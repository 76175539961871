import React from "react";
import { Page, StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import rertr from "./sidecorner.png";  
import Sidebarright from "./siderightchapter.png";  

const styles = StyleSheet.create({
  page: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  fullImage: {
    width: "50%",
    height: "100%",
    objectFit: "cover",
    position: "relative",
    left: "15px",
    top: "-10px",
  },
  userDetailsContainer: {
    position: "absolute",
    top: "7%",
    right: "3%",
    textAlign: "right",
  },
  nameText: {
    fontSize: 19,
    fontWeight: "bold",
    position:'relative',
    left:'40px',
    marginBottom: 5,
  },
  subText: {
    fontSize: 13,
    color: "#525659",
    marginBottom: 10,
    fontWeight: "300",  
  },
  sideBarImage: {
    width: "150%", 
    height: "70%",  
    marginTop: "10px", 
    alignSelf: "flex-end",  
    marginBottom: 30,

  },
});

const Pageone = ({ name = "Bhavisha Sheth", age = 26, gender = "Female", occupation = "Businessperson" }) => (
  <Page size="A4" style={styles.page}>
    <View>
      {/* Full-page background image */}
      <Image src={rertr} style={styles.fullImage} />

      {/* User details */}
      <View style={styles.userDetailsContainer}>
        <Text style={styles.nameText}>{name}</Text>
        <Text style={styles.subText}>
          {age} | {gender} | {occupation}
        </Text>

        {/* Sidebar image below user details */}
        <Image src={Sidebarright} style={styles.sideBarImage} />

        <Text>Date: 15.04.24</Text>
      </View>
    </View>
  </Page>
);

export default Pageone;
