// src/components/AnimatedCounter.js
import React, { useState } from 'react';
import CountUp from 'react-countup';
import { Card } from 'react-bootstrap';
import ScrollTrigger from 'react-scroll-trigger';

const AnimatedCounter = ({ endValue, duration,cardText ,icon}) => {
    const [counterON, setCounterON] = useState(false)
  return (
   <ScrollTrigger onEnter={()=>setCounterON(true) } onExit={()=>setCounterON(false)}>
        <Card className="text-center counter-card">
            <Card.Header>
             {icon}
            </Card.Header>
      <Card.Body>

        <Card.Title className="display-4">
        {counterON && <CountUp end={endValue} duration={duration} />}+
        </Card.Title>
        <Card.Text>
          <strong>{cardText}</strong>
        </Card.Text>
      </Card.Body>
    </Card>
   </ScrollTrigger>

  );
};

export default AnimatedCounter;
