import './App.css';
import Home from './Files/Home';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import Payment from './Files/Payment';
import Result from './Files/Result';

import Exam from './Files/Subjects/Exam';
import Componentmain from './Files/Subjects/Componentmain';
import Totorialstartpage from './Files/Totorialstartpage';
import Wealth from './Files/Subjects/Wealth';
import Workeducation from './Files/Subjects/Workeducation';
import Health from './Files/Subjects/Health';
import SelfAww from './Files/Subjects/SelfAww';
import LOgionnew from './LOgionnew';
import Thankyou from './Files/Thankyou';
import Pdf from './Files/Subjects/Pdf';
import Pdftest from './Files/Subjects/Pdftest';
import Pdfone from './Files/Subjects/Pdfone';
import Pagetestwr from './Pagesmaker/Pagetestwr';
import Dashboard from "./pages/Dashboard";
import { useEffect, useState } from 'react';
import Login from "./pages/Login";
import Coustomertable from "./pages/Coustomer/Coustomertable";
import './App_2.css';
import './Table.css';
import Staffcreate from "./pages/Staff/Staffcreate";
import Stafftable from "./pages/Staff/Stafftable";
import Staffedit from "./pages/Staff/Staffedit";
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Examdata from './pages/Examdata';
import Sortdateresult from './pages/Resultdata/Sortdateresult'
import Examresult from './pages/Examresult';
import AddCoupon from './pages/Copuon/AddCoupon'
import Copuontable from "./pages/Copuon/Copuontable";
import EditCoupon from "./pages/Copuon/Editcp";
import Pdftest1 from './Pagesmaker/Pdftest1';
import Razorpay from './Pagesmaker/Razorpay';
import { baseurlvmkp } from './Files/Subjects/Baseurl';
import axios from 'axios';
import Redirect from './Files/Redirect';
import Loaders from './Files/Loaders';
// import Sortdateresult 
 function App() {

  const [staffToken, setStaffToken] = useState(null);
const [paymentpage, setPaymentpage] = useState([])
  // Effect to check localStorage for staffToken
  useEffect(() => {

    // axios.get(`${baseurlvmkp}/myorder/get/${JSON.parse(localStorage.getItem('user')).details.id}`) 
    // .then((response) => {
    //   // setOrderId(response.data[0].id)
    //   console.log(response.data.filter((item)=>item.examStatus=='false'));  
    //   setPaymentpage(response.data.filter((item)=>item.examStatus=='false'?false:true))
    // })
    // .catch((err) => {
    //   console.log(err.message); 
    // })
    // .finally(() => {
    //   console.log('finally');
    // });




    const token = localStorage.getItem("staffToken");
    if (token) {
      setStaffToken(token);
    }
  }, []);

  return (

    <BrowserRouter>
      <Routes>

  
      <Route path="/stafftable" element={<Staffcreate />} />
      <Route path="/s2/:pointindex" element={<Stafftable />} />
        <Route path="/Staffedit/:id/:pointindex" element={<Staffedit />} />
 


        <Route path="/Exam/:pointindex" element={<Examdata />} />
        <Route path="/Examresult/:id/:pointindex" element={<Examresult />} />

        {/* <Route path="*" element={<Home />} /> */}
        <Route path="/" element={<Home />} />
        <Route path="/Exam" element={<Exam />} />
        <Route path="/wealth" element={<Wealth />} />
        <Route path="/we" element={<Workeducation />} />
        <Route path="/health" element={<Health />} />
        <Route path="/selfAww" element={<SelfAww />} />
    {/* {paymentpage Redirect ==true?  */}
       <Route path="/Payment" element={ <Payment /> } />
       <Route path="/Loaders" element={ <Loaders /> } />
       {/* Loaders */}
        <Route path="/Result" element={<Result />} />
        <Route path="/Totorialstartpage" element={<Totorialstartpage />} />
        <Route path="/LOgionnew" element={<LOgionnew />} />
        <Route path="/Thankyou" element={<Thankyou />} />
        <Route path="/redirectpage" element={<Redirect/>} />

        <Route path="/Pdf/:id" element={<Pdf />} />
        <Route path="/Pagetestwr/:id" element={<Pagetestwr />} />
        <Route path="/Pdfone/:id" element={<Pdfone />} />
        <Route path="/Coustomertable/:pointindex" element={<Coustomertable />} />

        <Route path="/Sortdateresult" element={<Sortdateresult />} />
        <Route path="/Pdftest1/:id" element={<Pdftest1 />} />

        <Route path="/Login" element={<Login setStaffToken={setStaffToken} />} />
        <Route path="/Razorpay" element={<Razorpay/>} />


        {/* Razorpay */}
        <Route path="/AddCoupon" element={<AddCoupon/>} />
                    <Route path="/Copuontable/:pointindex" element={<Copuontable/>} />
                    {/* Pdftest1 */}
                    {/* http://13.201.40.47:8080/vmkp */}
               
                    <Route path="/EditCoupon/:id/:pointindex" element={<EditCoupon/>} />
        {/* Pdfone */}
        {/* modallogin */}
        {/* Result */}


      </Routes>
    </BrowserRouter>



  );
}

export default App;
