import React, { useState } from "react";
import axios from "axios";
import { baseurlvmkp } from "../Files/Subjects/Baseurl";

const Razorpay = () => {
  const [amount, setAmount] = useState(""); // Input field for payment amount

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handlePayment = async () => {
    if (!amount || isNaN(amount)) {
      alert("Please enter a valid amount.");
      return;
    }

    const isScriptLoaded = await loadRazorpayScript();
    if (!isScriptLoaded) {
      alert("Failed to load Razorpay SDK. Please check your connection.");
      return;
    }

    try {
        // Create order on the backend
        const { data: order } = await axios.post(`${baseurlvmkp}/vmkp/createorder`, {
          amount: amount , // Convert to smallest currency unit (paise)
        });
      
        // Calculate price breakdown
        const subtotal = amount * 100; // Subtotal in paise
        const tax = subtotal * 0.18;  // 18% tax
        const discount = subtotal * 0.1; // 10% discount
        const totalAmount = subtotal + tax - discount;
      
        const options = {
          key: "rzp_test_tZrAScctm2CFkZ", // Replace with your Razorpay Key ID
          amount: totalAmount, // Total amount in smallest currency unit
          currency: "INR",
          name: "Your Company",
          description: "Payment Transaction",
          image: "https://your-logo-url.com/logo.png", // Optional logo
          order_id: order.id, // Pass the order ID from the backend
          handler: async function (response) {
            // Log both the payment response and the order details
            console.log("Payment Success Response:", response);
        
      
            try {
              // Send the payment success response to the backend for verification
              await axios.post(`${baseurlvmkp}/vmkp/createpayment`, {
                // ...response,
                orderId: order.id,
                status:'paid',
                payment_id:response.razorpay_payment_id

              });
              alert("Payment Successful!");
            } catch (error) {
              console.error("Payment Success Callback Error:", error);
              alert("Payment verification failed.");
            }
          },
          prefill: {
            name: "Customer Name",
            email: "customer@example.com",
            contact: "9999999999",
          },
          notes: {
            subtotal: `₹${(subtotal / 100).toFixed(2)}`,
            tax: `₹${(tax / 100).toFixed(2)}`,
            discount: `-₹${(discount / 100).toFixed(2)}`,
            total: `₹${(totalAmount / 100).toFixed(2)}`,
          },
          theme: {
            color: "#3399cc",
          },
        };
      
        const rzp = new window.Razorpay(options);
        rzp.open();
      } catch (error) {
        console.error("Error creating Razorpay order:", error);
        alert("Payment initiation failed. Please try again.");
      }
      
  };

  return (
    <div style={styles.container}>
      <h1>Payment Gateway</h1>
      <input
        type="number"
        placeholder="Enter Amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        style={styles.input}
      />
      <button onClick={handlePayment} style={styles.button}>
        Pay Now
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5",
  },
  input: {
    padding: "10px",
    marginBottom: "20px",
    width: "300px",
    fontSize: "16px",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#3399cc",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};



export default Razorpay;
