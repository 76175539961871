export const male_16_24 = [
    {
      app: [
        {
          // Applicability category content
          one_1: [
            {
              sec: 'You have limited your views of the world and often stop yourself from exploring.',
              para: 'In securing yourself within a certain limited world view, you have stopped exploring and living a routing or following pattern. You want to do a lot of things deep down, but feel stopped and at times want to just give up. You do not like the dominant behaviour of others. You are empathetic to the needs of others and try to help them.'
            }
          ],
          two_2: [
            {
              sec: 'You would like to explore the world but are scared to open ventures.',
              para: 'You have a streak of adventure in you which wishes to take risks but your rationale pulls you back often and restricts your expression. Your freedom for adventure is your biggest asset which allows for your exploration and expands your learning. A balance of rationale and adventure will give richer rewards rather than being hooked to rationale.'
            }
          ],
          three_3: [
            {
              sec: 'In your explorations in life, you often go for caution over valor.',
              para: 'You love to take a stand for others and empower them to achieve their goals. You are someone who safeguards others interests and doesn\'t let them down even if it\'s at your own cost. You need to however learn to take a stand for yourself at times as it will make you more grounded and respectable.'
            }
          ],
          four_4: [
            {
              sec: 'You love exploring the world and are ready to bear the consequences of doing so.',
              para: 'Leadership is natural to you. You have a certain charm and natural responsibility which you demonstrate in your leadership. Your sense of leadership comes from the sense of service that you have for humanity. You run the risk of being hurt and misunderstood but in the long run you will end up being respected and acknowledged.'
            }
          ],
          five_5: [
            {
              sec: 'While engaging in your experiences, you have freedom to accept your own failures.',
              para: 'You love taking risks and living on the edge because failures are something you have freedom in acknowledging. You are the best friend to have for anybody. You always want more out of life and are ready to take the effort. If you are able to measure and calculate your risks with your rewards, you will turn out to be an excellent leader.'
            }
          ],
          six_6: [
            {
              sec: 'You attempt too many things in life in the fear of missing out. You are friendly but insecure.',
              para: 'Popularity, fame and wealth are the prime motivators of your life. You would go the extra mile and double the effort for popularity, fame and wealth. You work hard towards your goals but make it look as if it\'s natural. You value friendships and prefer a large group though you have an intimate relationship with each. You want to live life to the fullest.'
            }
          ],
          seven_7: [
            {
              sec: 'You want to achieve too much in too little time - a dreamer sort of.',
              para: 'Your zest for life is extraordinary but often it is inconsistent with regards to actions. You think big and larger than life. Sometimes it works and sometimes it fails. However, while successes are large, the failures are too brutal and can make you seem foolish. Your courage to face such success and failure with equanimity can make you a tough leader in the community.'
            }
          ]
        }
      ],
      work: [
        {
          one_1: [
            {
              sec: 'While being very attentive to yourself you could end up being pretty hard on yourself.',
              para: 'You are almost a perfectionist and excellence may be your pursuit but it causes setbacks with colleagues, friends and relationships. You could easily give up things you are passionate about if it does not go as per your plans. Your idealism is great and you need to back it up with consistency in actions. You are a possibility of creating something extraordinary.'
            }
          ],
          two_2: [
            {
              sec: 'Being with people around you is what you enjoy irrespective of it being productive or not.',
              para: 'You are such a people’s person that you could even go out of your way for them. You can feel misunderstood by others even for the good you do to them. You value sacrifices more than rewards and allow yourself to feel like a martyr. You may want to consider yourself more of a change maker rather than a revolutionary.'
            }
          ],
          three_3: [
              {
                sec: 'Yes, service towards people though well intended often doesn\'t yield great results.',
                para: 'In your being authentic, people feel you are brutal, and when polite, they feel you are manipulative. However your greatest trait is the joy you get in serving people and enjoying others happiness. You often inquire into yourself and are honest with yourself. Sometimes it\'s better to let things be and not have to work out everything yourself.'
              }
            ],
            four_4: [
              {
                sec: 'Whatever you focus on you want to make sure you obtain results.',
                para: 'You are committed, determined and loyal to people, work and communities. You may enjoy many things in life and have fun, but do not take life casually. You value most things and in most spheres of life. Everything that you do needs to have a purpose which is wonderful; however just take care you don’t make those who take things casually wrong.'
              }
            ],
            five_5: [
              {
                sec: 'When you focus on something you are determined to make things happen and produce results.',
                para: 'You do not appreciate loose talk and casual behaviour. You prefer intuition to logic though you have quite an intelligent mind. Your continuous evolution is your greatest strength. You think deeply and like your thinking to be challenged but not everybody likes it. Your gentle and spontaneous side can be the right balance.'
              }
            ],
            six_6: [
              {
                sec: 'While facing difficulties in acknowledging your failures, you often end up overcompensating.',
                para: 'You prefer to take things lightly and avoid discussing your failures. You come across as one with a positive attitude while traits of self denial may be lurking within. You work hard in most spheres of life but behave as if their results do not matter. You are very closed about your dreams. You have many friends in social and professional life but share truly with few.'
              }
            ],
            seven_7: [
              {
                sec: 'You maintain a highly positive outlook to life, but could be living in self denial.',
                para: 'You want to make everything look and feel good. You are highly motivated and love to inspire people. You could be a lot more productive if you looked deeper at things than address them at the surface. Your sense of self believe may at times create an illusion or distort reality for you. You need caution in some of your bold decisions.'
              }
            ],
  }
      ],
  
      criti: [
          {
            one_1: [
              {
                sec: 'You find it difficult to take life seriously and prefer to have a casual attitude.',
                para: 'It is important for you to paint a happy picture of everything though internally you are pretty resigned and cynical. You often live in two worlds, one with yourself and the other world with the rest. It is also possible that these two worlds are quite opposite to each other. You could be someone who is a good observer and a friend who emphasizes others.'
              }
            ],
            two_2: [
              {
                sec: 'You are very meticulous in your work but sometimes may miss the larger context.',
                para: 'You wanted life balanced and took responsibility but have changed to someone who takes limited responsibility but has great commitment to things and life. You can be optimistic and cynical at the same which sometimes works in favour and sometimes may not work in your favour. You could look at expanding your responsibilities that you take one.'
              }
            ],
            three_3: [
                {
                  sec: 'Your meticulous approach to many things often limits you in taking quick decisions when needed.',
                  para: 'Your intensity and cynicism often coexist in many ways which can be difficult for people to comprehend. You are the best team member a leader could have as you do not crave much attention and find happiness in doing what you do and what is needed. Your purpose and fulfilling it somehow defines your self esteem.'
                }
              ],
              four_4: [
                {
                  sec: 'You have a balanced mix of being idealistic and practical at the same time.',
                  para: 'Your ambitious nature is balanced with purpose rather than greed. Your life approach is one of a leader who walks the path of self-belief. Your power to give is abundant and generous. However you need to be wise in your giving else even the undeserving will be rewarded which is unfair.'
                }
              ],
              five_5: [
                {
                  sec: 'Your idealism is a value that you live by and yet know how to be practical in your actions.',
                  para: 'You are a good team player but do not like being a follower. You have a mind of your own though you don’t impose it on others and don’t like others imposing their mind on you. Your pursuit for excellence may empower and enable you to invent or create products, systems or processes. You have it in you to invent or create something.'
                }
              ],
              six_6: [
                {
                  sec: 'You want to live life king size but the petty breakdowns are just too much to handle.',
                  para: 'Your approach to an idealistic life has made you a seeker who pursues his knowledge relentlessly. You have a thirst to know more and are not easily satisfied. Your adventurous nature has given you many life experiences which you want to integrate and have some focus on life, but often find yourself confused. You are a thinker and genuinely want to lead a life you love.'
                }
              ],
              seven_7: [
                {
                  sec: 'You’re highly energetic and can be perceived as restless and leave people agitated.',
                  para: 'You take life seriously and would like to be responsible for almost everything around you. Your value to life and things in life are very high. You did not accept failures easily and push yourself beyond failures. You could end up putting a lot of pressure on yourself. Your nature to think a lot may lead to worry and may cause harm to the world around you.'
                }
              ],
    }
        ],
  
        futu: [
          {
            one_1: [
              {
                sec: 'You prefer the idealistic world, though more imaginative than the real world you live in.',
                para: 'You have a large canvas to everything in life but often unreal and impractical. You are quite a dreamer but often lack responsibility for your thoughts and actions. You feel misunderstood by people but would receive a complaint that you do not listen to others. If you bring a sense of discipline you are a potential genius.'
              }
            ],
            two_2: [
              {
                sec: 'You have genuine dreams which if pursued have a potential to create a large impact.',
                para: 'You are ambitious and adventurous but need to caution yourself with respect to greed and gamble. You are not careful with your money and yet you want to be rich. In being responsible with your money you could be rich and powerful. You have the will to stand up to circumstances, rather than bow down to them.'
              }
            ],
            three_3: [
                {
                  sec: 'Your dreams for life aren\'t regular yet if you pursue them you would make a difference in the world.',
                  para: 'Your passion for life may at times cloud your view on what is possible and what is impossible. However your belief in people and their support will often have you cross the trouble you face. You prefer to value people and relationships more than success and rewards which keeps you ahead in life most often.'
                }
              ],
              four_4: [
                {
                  sec: 'You are both a dreamer and a realistic rolled in one which can be confusing for you most times.',
                  para: 'You have a strong sense of self-belief and pursue dreams with grit  and determination. Taking a punch in your face for your dreams is fine. Your grit and determination may be misunderstood as being stubborn which may need you to take a stand for yourself without hurting others or wanting to prove them wrong.'
                }
              ],
              five_5: [
                {
                  sec: 'Your dreams and reality have a unique blend to cover a large spectrum.',
                  para: 'You are quite selective in sharing your dreams and in choosing people to be a part of your dreams. In being selective you may take longer than usual to achieve them. Building patience as a virtue may turn out to be your biggest asset in fulfilling your dreams, especially as your dreams are big.'
                }
              ],
              six_6: [
                {
                  sec: 'You need to acknowledge yourself for the efforts you have taken to fulfill even the smallest of your needs.',
                  para: 'You have a plan for your future which is the practical plan and the dream plan. You would like to fulfill the practical plan in the short term and play out your dream plan in the future. Your ability to balance the real and the ideal is quite commendable, but it could lead you to keeping your life at razor’s edge.'
                }
              ],
              seven_7: [
                {
                  sec: 'You put too much pressure on fulfilling others expectations and your own.',
                  para: 'You want too much out of life and want to make every minute count. In doing so, you often find it difficult to play the long haul and are constantly working in the short term in many things. You could end up spreading yourself too wide and find yourself lacking depth in areas that matter to you. Allowing a little moderation to somethings will help you focus. '
                }
              ],
    }
        ]
  
  
    }
  ];
  
  
  
  export const female_16_24 = [
      {
        app: [
          {
            // Applicability category content
            one_1: [
              {
                sec: 'You have limited your views of the world and often stop yourself from exploring.',
                para: 'In securing yourself within a certain limited world view, you have stopped exploring and living a routing or following pattern. You want to do a lot of things deep down, but feel stopped and at times want to just give up. You often hope people would be more kind and compassionate to others feelings.You do not like the dominant behaviour of others. You are empathetic to the needs of others and try to help them.'
              }
            ],
            two_2: [
              {
                sec: 'You would like to explore the world but are scared to open ventures.',
                para: 'You have a streak of adventure in you which wishes to take risks but your rationale pulls you back often and restricts your expression. Your freedom for adventure is your biggest asset which allows for your exploration and expands your learning. A balance of rationale and adventure will give richer rewards rather than being hooked to rationale.'
              }
            ],
            three_3: [
              {
                sec: 'In your explorations in life you often go for caution to valour.',
                para: 'You love to take a stand for others and empower them to achieve their goals. You are someone who safeguards others interests and doesn\'t let them down even if it\'s at your own cost. You need to however learn to take a stand for yourself at times as it will make you more grounded and respectable.'
              }
            ],
            four_4: [
              {
                sec: 'You love exploring the world and are ready to bear the consequences of doing so.',
                para: 'Leadership is natural to you. You have a certain charm and natural responsibility which you demonstrate in your leadership. Your sense of leadership comes from the sense of service that you have for humanity. You run the risk of being hurt and misunderstood but in the long run you will end up being respected and acknowledged.'
              }
            ],
            five_5: [
              {
                sec: 'While engaging in your experiences, you have freedom to accept your own failures.',
                para: 'You love taking risks and living on the edge because failures are something you have freedom in acknowledging. You are the best friend to have for anybody. You always want more out of life and are ready to take the effort. If you are able to measure and calculate your risks with your rewards, you will turn out to be an excellent leader.'
              }
            ],
            six_6: [
              {
                sec: 'You attempt too many things in life in the fear of missing out. You are friendly but insecure.',
                para: 'Popularity, fame and wealth are the prime motivators of your life. You would go the extra mile and double the effort for popularity, fame and wealth. You work hard towards your goals but make it look as if it\'s natural. You value friendships and prefer a large group though you have an intimate relationship with each. You wish that in living to the fullest, you are not misunderstood as irresponsible and taken lightly.'
              }
            ],
            seven_7: [
              {
                sec: 'You are a dreamer and want to live like a butterfly, free and uninhibited.',
                para: 'Your thoughts are quite radical about life itself. It may help if you learn to write it down and articulate it well rather than shoot from the hip. You think big and larger than life. Sometimes it works and sometimes it fails. However, while successes are large, the failures are too brutal and can make you seem foolish. Your courage to face such success and failure with equanimity can make you a tough leader in the community.'
              }
            ]
          }
        ],
        work: [
          {
            one_1: [
              {
                sec: 'You are pretty straight and upfront with yourself and may end up being too demanding on yourself.',
                para: 'You are almost a perfectionist and excellence may be your pursuit but you could learn to be more kind and compassionate to yourself in your quest for perfection. You could easily give up things you are passionate about if it does not go as per your plans. Your idealism is great and you need to back it up with consistency in actions. You are a possibility of creating something extraordinary.'
              }
            ],
            two_2: [
              {
                sec: 'Being with people around you is what you enjoy irrespective of it being productive or not.',
                para: 'Whatever people say, your straightforwardness is your greatest asset which you could build by drawing the line between blunt and rude. Your carefree and devil-may-care attitude may find you endearing and loved by some but disliked and criticized by some. You are either loved or disliked, there is no in between with you.'
              }
            ],
            three_3: [
                {
                  sec: 'Yes, service towards people though well intended often doesn\'t yield great results.',
                  para: 'Have a little compassion for others and you may turn out to be a great leader most people want. Responsibility may then fall easy on your shoulders. Your ability to handle success with being greedy for it distinguishes you as a leader. You don\'t mind taking blame upon yourself even if the mistake is made by others, especially when those others are relationships you value.'
                }
              ],
              four_4: [
                {
                  sec: 'Whatever you focus on you want to make sure you obtain results.',
                  para: 'Your intuitive mind enables you to see things differently and draws very unique inferences, which can often lead to "wow" moments. However, these moments may also create self-doubt at times. By learning to believe more in yourself, you will become an invaluable asset to any team. If teaching is something that interests you, you have the potential to be an excellent teacher.'
                }
              ],
              five_5: [
                {
                  sec: 'When you focus on something, you are determined to make things happen and produce results.',
                  para: 'Your intuitive mind may even give you, at times, unreal inferences. You need to blend your logic and intuition to derive your inferences. Your ability to blend your logic and intuition will turn out to be such a trait that it will reward you richly. However, pressuring any one way will have detrimental results.'
                }
              ],
              six_6: [
                {
                  sec: 'While facing difficulties in acknowledging your failures, you often end up overcompensating.',
                  para: 'You work very hard but yet maintain a very casual look rather than a serious look. You prefer to have deep and intense relationships and conversations but find most people frivolous and farcical. With a true and authentic person you are more committed than anyone would know. Your anger in some areas is deep while your smile may be of the cynical.'
                }
              ],
              seven_7: [
                {
                  sec: 'Your positive outlook is quite contagious but you need someone to take care of your insecurities.',
                  para: 'Your world is quite unreal and it looks like you are attempting to escape the real world when in truth, you are trying to create a better world. Walking these two worlds sometimes makes you look like a hypocrite which is quite funny. Your ability to align yourself with the real and unreal may take you places in terms of intelligence and success.'
                }
              ],
    }
        ],
    
        criti: [
            {
              one_1: [
                {
                  sec: 'You find it difficult to take life seriously and prefer to have a casual attitude.',
                  para: 'Your sense of empathy for others is very high and you are also very sensitive but you prefer to demonstrate it silently in actions rather than talk about it in words. Even though you may not agree with many things around you, you prefer to not speak your mind and keep counsel to yourself. You could be someone who is a good observer and a friend who emphasizes others.'
                }
              ],
              two_2: [
                {
                  sec: 'You are very meticulous in your work but sometimes may miss the larger context.',
                  para: 'You are an excellent team player but wary of being a leader as you have experienced hurt and betrayal in the past. You have great ideas with many things but would not push them in a team to avoid conflict and confrontation. Your passive nature may at times work counterproductive to your interest.'
                }
              ],
              three_3: [
                  {
                    sec: 'Your meticulous approach to many things often limits you in taking quick decisions when needed.',
                    para: 'You have freedom in dealing with good and bad with ease which you may often perplex people into thinking you don\'t have a stand. However people can count on you more than themselves. You are left in handling conflicts and take pride in resolving them when others fail in resolving such conflicts.'
                  }
                ],
                four_4: [
                  {
                    sec: 'You have a balanced mix of being idealistic and practical at the same time.',
                    para: 'You are a giver and love living life that way. Sometimes you may sacrifice your needs just to care for others. You value relationships more than anything which could give the impression that you are insecure and alone. You need to be wise and sensible in charming your relationship.'
                  }
                ],
                five_5: [
                  {
                    sec: 'Your idealism is a value that you live by and yet know how to be practical in your actions.',
                    para: 'While you feel strongly for others and may even cry for or with others, you have a strong mind and are an independent thinker. Others may feel you are weak but you know you are not. You need to hold your ground and belief in yourself even when others may assume you are a pushover. Defending yourself is counterproductive to your goals. Listening to others and then firmly sharing yourself is your best bet.'
                  }
                ],
                six_6: [
                  {
                    sec: 'You want to live life king size but the petty breakdowns are just too much to handle.',
                    para: 'You have characteristics of being a leader or a mentor and are even spiritually inclined. Your inquiries have others baffled and perplexed. You are a thinker and genuinely want to lead a life you love. In being a seeker you are also searching for a guru or mentor yourself. Your best support is the inquiries you have, the answers will be discovered in due course.'
                  }
                ],
                seven_7: [
                  {
                    sec: 'You’re highly energetic and can be perceived as restless and leave people agitated.',
                    para: 'Life is precious and you want to nurture it well. You don\'t want to waste your life in any way. You do not accept failures easily and push yourself beyond failures. However allowing yourself to make mistakes is also a learning in many ways. You can\'t be perfect. Imperfections are often the embellishment to life itself. Enjoying life is also a way to nurture it.'
                  }
                ],
      }
          ],
    
          futu: [
            {
              one_1: [
                {
                  sec: 'You often work hard to make your fantasies real and feel heartbroken when they don’t turn out.',
                  para: 'You are the master of your own world and like to create your life your own way. You dream beyond what others dream and everything or anything that seems impossible you want to make possible. Many times it looks like nothing in your world is real. You are unstopped by criticism. It may help if you created a balance.'
                }
              ],
              two_2: [
                {
                  sec: 'You have genuine dreams which if pursued have a potential to create a large impact.',
                  para: 'You play to win, you are aggressive, confident, a go-getter. You want many things in life and are ready to work for it. Your hard work and determination distinguishes you from the rest. You need to caution yourself from making your pursuits your obsession. Your obsessions have often set you up for failures.'
                }
              ],
              three_3: [
                  {
                    sec: 'Your dreams for life aren\'t regular yet if you pursue them you would make a difference in the world.',
                    para: 'You may be more productive and effective in life if you learn to prioritise rather than pursue. Patience is also a virtue you could learn. As a leader your team may often achieve the impossible, but never take it for granted. Taking on challenges and winning them keeps you going. Choose the challenges you want to take on rather wisely.'
                  }
                ],
                four_4: [
                  {
                    sec: 'You are both a dreamer and a realistic rolled in one which can be confusing for you most times.',
                    para: 'Your belief in yourself and your ability to absorb others\' ideas compels people to want to be in your team. You are a natural leader and leadership rests on your head as a deserved crown. Your zest to challenge yourself and push your limits make you special and endearing. You need to choose your team associates wisely.'
                  }
                ],
                five_5: [
                  {
                    sec: 'Your dreams and reality have a unique blend to cover a large spectrum.',
                    para: 'The more you learn to value your power, greater responsibility will come your way. Humility and compassion will serve you greatly. You need to be selective and choose wisely the areas and places you want to lead. Spreading yourself thin in need to see your own power may work against you. You are in all ways a child of the future.'
                  }
                ],
                six_6: [
                  {
                    sec: 'You need to acknowledge yourself for the efforts you have taken to fulfill even the smallest of your needs.',
                    para: 'Your future plans are not extravagant and at the same time not too practical. You are attempting to strike a balance between the two. You want a lot out of life, but are ready to play it slow. You are ready for the long haul. You need to engage with different kinds of people beyond your limited circle you exist in now to realise your future.'
                  }
                ],
                seven_7: [
                  {
                    sec: 'In wanting to fulfill expectations of others you may have forgotten what you really want for yourself.',
                    para: 'You are in a hurry to fulfill many things in life. You think you are intelligent, responsible and creative but do not acknowledge how insecure you are. You think of achieving everything quickly to compensate for past failures. Your potential to dream is limited by wanting to be successful.'
                  }
                ],
      }
          ]
    
    
      }
    ];
  
  
  export const stamptest=[
  {
    "coreAptitude": "Coding",
    "stampAtFront": "Executor"
  },
  {
    "coreAptitude": "Data",
    "stampAtFront": "Executor"
  },
  {
    "coreAptitude": "Technology",
    "stampAtFront": "Executor"
  },
  {
    "coreAptitude": "Business",
    "stampAtFront": "Facilitator"
  },
  {
    "coreAptitude": "Marketing",
    "stampAtFront": "Facilitator"
  },
  {
    "coreAptitude": "Finance",
    "stampAtFront": "Instructor"
  },
  {
    "coreAptitude": "Personal Growth",
    "stampAtFront": "Instructor"
  },
  {
    "coreAptitude": "Humanities",
    "stampAtFront": "Instructor"
  },
  {
    "coreAptitude": "Content",
    "stampAtFront": "Creator"
  },
  {
    "coreAptitude": "Design",
    "stampAtFront": "Creator"
  }
]

  
    export   const male_25_40 = [
      {
        app: [
          {
            // Applicability category content
            one_1: [
              {
                sec: 'Setbacks of circumstances in life have restricted your view of life and you call yourself introverted.',
                para: 'It\'s okay to have failed in trying to do many things. Just acknowledge yourself for trying. Make new choices and create a new future. Do not suppress yourself. You are a deep thinker but often get into the trap of overthinking. Staying with a few things and going deep into it can be more rewarding. Discretion and valour.'
              }
            ],
            two_2: [
              {
                sec: 'You like being extroverted but most often hold yourself back.',
                para: 'You look upto many people and choose to be inspired by them. You have the trait of a self-made individual and want to make a mark for yourself in the world. While looking up to people is good, making it a habit to be inspired by others could be limiting. Your own learnings are inspiring, learn to share it with others.'
              }
            ],
            three_3: [
              {
                sec: 'You want to expand your world but for some reason it is being limited.',
                para: 'You need to choose the areas you want to excel and shut out the other disturbances. Your ability to focus is what will transform you into a leader whose team will always be empowered and on the go. Exploration is your forte in your growth, retreat is not.'
              }
            ],
            four_4: [
              {
                sec: 'You have learnt to strike the right balance with life and areas of life.',
                para: 'You are lucky and blessed. You have had good mentors and have done well by listening to them and abiding by what they have taught you. Your learnings from the various sources enable you to mentor people in the future when they come to you. For all that you get, giving back will multiply what you have.'
              }
            ],
            five_5: [
              {
                sec: 'You enjoy being balanced but there are streaks of adventure in you.',
                para: 'You need to take a stand, believe in yourself and be a leader for those who look up to you. Giving up your competitive trait for a more inclusive approach will prove more beneficial. You are a rare mix of adventure and caution. You are the go-to person in decisions of crisis and chaos. You have a streak of entrepreneurship in you.'
              }
            ],
            six_6: [
              {
                sec: 'You are grappling with many things in life at this stage and need to slow down.',
                para: 'Your life is like an exciting roller coaster ride with many bumps, but it does not dampen your spirit, rather propels it further and higher. In just preparing a chart of urgent, immediate and important criteria for different areas and aspirations of life you may turn out to be exponentially productive. Instead of wanting to be more successful, accept you have succeeded quite a bit and have a long journey ahead.'
              }
            ],
            seven_7: [
              {
                sec: 'You put a certain pressure on your life that is taking its toll.',
                para: 'Chill! Things will workout. You have a strong sense of responsibility and don\'t like to let people down. You are quite a leader but it\'s not necessary therefore to take everything upon yourself. While you have great commitment towards people and aspects of life in general, it\'s okay to spare some time for yourself. Instead of often sacrificing for the greater good, it\'s okay to do things to make only yourself happy.'
              }
            ]
          }
        ],
        work: [
          {
            one_1: [
              {
                sec: 'Life is tough, hard and unfair but don\'t give up. In the end it all works out.',
                para: 'You have put in a lot of hard work into living life. It is fine if often you have met with failures. Just taking a step back and assessing your achievements and failures may help you bounce back significantly. You have tenacity which is your greatest strength. You love challenges, so take a few of them and not all of them. Life is not a challenge but a journey.'
              }
            ],
            two_2: [
              {
                sec: 'You have dedicated a large part of your life to family responsibilities.',
                para: 'You are the ideal family man, social citizen and community leader. Your energy is often infectious and charming. You need to choose wisely where to dissipate your energy and where not to for that will define you as a leader or a popular person. Choice is yours.'
              }
            ],
            three_3: [
                {
                  sec: 'You need to give time for yourself and the development of your mind skills.',
                  para: 'It is possible that you see yourself in the expectations of others. Just observing yourself from time to time may give you choices on what to lead and how much to lead. Since you are an inherent leader this is not difficult for you. Your observation skills and experiential memories are the best tools you have for engaging with life.'
                }
              ],
              four_4: [
                {
                  sec: 'Your intent to make life work at all costs is what makes you a leader.',
                  para: 'You want to dedicate your life to this to the service of others. Society needs more people like you. You have good listening abilities and empathy. You need to ensure that you can absorb the hurt, betrayal and resentment and yet be forgiving and accepting. You have a teacher in you waiting to come out.'
                }
              ],
              five_5: [
                {
                  sec: 'Your intent for expanding yourself needs a plan more than courage and bravado.',
                  para: 'You place others\' successes and others dreams above your own. However, acknowledging your own dreams and defining your own success is necessary as it will give you a balanced sense of reality. You could be the one empowering others to fulfill their dreams by just sharing and living your dream with and for others.'
                }
              ],
              six_6: [
                {
                  sec: 'Your view of the world has expanded but you need to expand yourself too.',
                  para: 'What a journey of life you have had. It seems like you are the chosen one by the universe. Just when you think you have achieved it, the world around suddenly expands and you are found wanting more. You are not alone. Leaders like you would often wonder how much to grow and expand. The answer is ‘limitless’. Have fun.'
                }
              ],
              seven_7: [
                {
                  sec: 'You want to achieve many things in life but struggle in determining a path.',
                  para: 'Your sense of aspiration and importance is skewed. You want the world your way and have often achieved it with your intelligence and determination. Consider you may have the experience of being alone and not understood in spite of most things working for you. Doing a reality check may help.'
                }
              ],
    }
        ],
    
        criti: [
            {
              one_1: [
                {
                  sec: 'Being a carefree individual is great, but maintaining a sense of responsibility is essential.',
                  para: 'Having fun, making others happy, taking life as it comes, don\'t be too serious with life, are mantras of your life. It works when you deal with life at the surface. If you want to deal with life a little deeper then you could reveal your responsible self too. Responsibility can also be fun if you change your perspective. Your jovial attitude with the expression of responsibility makes you a star.'
                }
              ],
              two_2: [
                {
                  sec: 'You are a self critic and could be at times harsh to yourself.',
                  para: 'Being positive in all circumstances is a mantra of your life but not taking cognizance of the impact of the breakdowns may make you delusional. The ability to observe the positivity and the negativity gives the more powerful balanced view rather than polarized view. Your decision making will prove far more effective in a balanced, than polarized view.'
                }
              ],
              three_3: [
                  {
                    sec: 'Being positive is a good trait you have but check yourself for denial.',
                    para: 'You may often wonder why others look at life so negatively. Maybe they are doing so for your sake, so that you can absorb your positivity and their negativity with greater ease. Your need to remain positive at all times maybe is sourced in fear rather than joy. In acknowledging your fears you may move from positivity to possibility.'
                  }
                ],
                four_4: [
                  {
                    sec: 'Measuring your limitations and working on them is a trait that will take you ahead.',
                    para: 'Your ability to dream as well as be aware and in tune with reality is quite rare. This allows you to have empathy for others and nurture their inner qualities. Your abilities are more intune to being a teacher, trainer or facilitator rather than a leader. When you are empathetic, people may revere you. While you are manipulating people may abhor you.'
                  }
                ],
                five_5: [
                  {
                    sec: 'Knowing one\'s limitations is good but using it as an excuse for failure is not wise.',
                    para: 'You have the balance of an analytical and creative brain. This enables you to make logical as well as intuitive decisions wherever required. You need to take care that this ability does not let you be manipulative. While you can understand others so much you wish you would also be understood as much by others. Choosing those who you wish understood you is an art.'
                  }
                ],
                six_6: [
                  {
                    sec: 'You are maintaining a thin line between risk and gamble, balance yourself.',
                    para: 'You are always gunning for the big game. You are creative and love to think out of the box and live life on the edge. Exploring, adventuring and discovering are natural traits in you. However, you need to caution yourself from going overboard as you often do not maintain a structure which tells you when to stop. You accept failures but are never stopped by your failures.'
                  }
                ],
                seven_7: [
                  {
                    sec: 'Being a perfectionist is great but being obsessed with it could be unfavourable.',
                    para: 'Life is unfair, unjust and cruel. That\'s how life is. The harder you work for it to be otherwise the more it becomes the way it is. The best way sometimes is to let things be. Just being with things empowers you to have them change. It requires intent, observations, deep listening and patience. Virtues which you are capable of building.'
                  }
                ],
      }
          ],
    
          futu: [
            {
              one_1: [
                {
                  sec: 'Being ambitious in the future is good, but also balance it with reality in action.',
                  para: 'You don\'t care about your future much now. You behave as if you don\'t care not because you are free but you have given up caring. Your jovial nature on the surface is to hide the resignation and pain within. A satire through humour is laced with layers of meaning. Your future is very promising if you allow transparency and vulnerability for yourself.'
                }
              ],
              two_2: [
                {
                  sec: 'You have the ability to think out of the box and constantly challenge yourself.',
                  para: 'Your openness to challenges is amazing. Nothing tires you and you have the power to devote yourself endlessly to something you love. You enjoy life itself. You want even others to enjoy but need to accept others have their own way to enjoy life. You need to include others in your way of life rather than coax or force them into your way of life.'
                }
              ],
              three_3: [
                  {
                    sec: 'You have the possibility of a great future in a creative pursuit.',
                    para: 'You often feel bogged down by discussions of logic and reality. You often spend time trying to create your own reality. You need to believe in yourself and know you are special. Yet have compassion for all around you. Each one has their own reality accepting others reality expands your own. This life will not happen again, make the most of it.'
                  }
                ],
                four_4: [
                  {
                    sec: 'You choose to be the master of your own future and craft it yourself.',
                    para: 'Nothing can shake the spirit within you except your loved ones. Your weakest link is love and romance, while it is also your strongest and most intense emotion. You pride yourself on your intensity and depth of emotions. While it is a powerful way to express yourself, it could be suffocating and intimidating to others who are in a relationship with you.'
                  }
                ],
                five_5: [
                  {
                    sec: 'You have a strong sense of belief in yourself which will keep you in a good place.',
                    para: 'In matters of romance you want the girl  you love to be the only one and risk everything for her. Your madness, passion, commitment and intensity are all extreme emotions rolled into one being which is you. Keep believing in yourself. Be aware of your intensity and you have the potential to rock the world.'
                  }
                ],
                six_6: [
                  {
                    sec: 'You know you are quite capable of anything, though it\'s better to focus on a few things to begin with.',
                    para: 'You live as if you are the most powerful person in your world. This allows you to expand your sense of responsibility but makes everyone around you feel they are inferior and smaller than you. You need to have powerful people in your circle which will make you accountable to yourself. Do not fool yourself to be the best as there is always something better than the best.'
                  }
                ],
                seven_7: [
                  {
                    sec: 'You are in a hurry to make up for lost time. Relax and it\'s okay to take it slow.',
                    para: 'You have done your bit to make life happen. Now is the time to look back and check the few things you want to pursue for a lifetime. Money and success will come provided you give up the chase. Time is not a function of what is lost but a function of what you have ahead and what you make of it. You have a generous heart but you need to give up stingy behaviour.'
                  }
                ],
      }
          ]
    
    
      }
    ];
    
    
    
    export   const female_25_40 = [
        {
          app: [
            {
              // Applicability category content
              one_1: [
                {
                  sec: 'Setbacks of circumstances in life have restricted your view of life and you call yourself introverted.',
                  para: 'Broken relationships and broken promises have left you feeling hurt and angry with the world but it has cost you your belief in yourself. Forgive them and move ahead. You are too precious to carry such resentment. It\'s okay to have failed in trying to do many things. Just acknowledge yourself for trying. Make new choices and create a new future. Do not suppress yourself. You are a deep thinker but often get into the trap of overthinking.'
                }
              ],
              two_2: [
                {
                  sec: 'You like being extroverted but most often hold yourself back.',
                  para: 'You look upto many people and choose to be inspired by them. You have the trait of a self-made individual and want to make a mark for yourself in the world. While looking up to people is good, making it a habit to be inspired by others could be limiting. Your own learnings are inspiring, learn to share it with others.'
                }
              ],
              three_3: [
                {
                  sec: 'You want to expand your world but for some reason it is being limited.',
                  para: 'You need to choose the areas you want to excel and shut out the other disturbances. Your ability to focus is what will transform you into a leader whose team will always be empowered and on the go. Exploration is your forte in your growth, retreat is not.'
                }
              ],
              four_4: [
                {
                  sec: 'You have learnt to strike the right balance with life and areas of life.',
                  para: 'You are lucky and blessed. You have had good mentors and have done well by listening to them and abiding by what they have taught you. Your learnings from the various sources enable you to mentor people in the future when they come to you. For all that you get, giving back will multiply what you have.'
                }
              ],
              five_5: [
                {
                  sec: 'You enjoy being balanced but there are streaks of adventure in you.',
                  para: 'You need to take a stand, believe in yourself and be a leader for those who look up to you. Giving up your competitive trait for a more inclusive approach will prove more beneficial. You are a rare mix of adventure and caution. You are the go-to person in decisions of crisis and chaos. You have a streak of entrepreneurship in you.'
                }
              ],
              six_6: [
                {
                  sec: 'You are grappling with many things in life at this stage and need to slow down.',
                  para: 'Your journey of life is a unique and difficult one. Learn to accept it and do not copy others. Your life is like an exciting roller coaster ride with many bumps, but it does not dampen your spirit, rather propels it further and higher. In just preparing a chart of urgent, immediate and important criteria for different areas and aspirations of life you may turn out to be exponentially productive. Instead of wanting to be more successful, accept you have succeeded quite a bit and have a long journey ahead.'
                }
              ],
              seven_7: [
                {
                  sec: 'There is nothing wrong with you. Stop correcting yourself and start accepting yourself.',
                  para: 'Being interested in self is not selfish or self-centred. You do not have to sacrifice all the time. Chill! Things will workout. You have a strong sense of responsibility and don\'t like to let people down. You are quite a leader but it\'s not necessary therefore to take everything upon yourself. While you have great commitment towards people and aspects of life in general, it\'s okay to spare some time for yourself.'
                }
              ]
            }
          ],
          work: [
            {
              one_1: [
                {
                  sec: 'Life is tough, hard and unfair but don\'t give up. In the end it all works out.',
                  para: 'You haven’t given much importance to love and romance and have stayed in the world of responsibility and duty. You need to first have some love for yourself. You have put in a lot of hard work into living life. It is fine if often you have met with failures. Just taking a step back and assessing your achievements and failures may help you bounce back significantly. You have tenacity which is your greatest strength. You love challenges, so take a few of them and not all of them. Life is not a challenge but a journey.'
                }
              ],
              two_2: [
                {
                  sec: 'You have dedicated a large part of your life to family responsibilities.',
                  para: 'You are the ideal family woman, social citizen and community leader. Your energy is often infectious and charming. You need to choose wisely where to dissipate your energy and where not to for that will define you as a leader or a popular person. Choice is yours.'
                }
              ],
              three_3: [
                  {
                    sec: 'You need to give time for yourself and the development of your mind skills.',
                    para: 'It is possible that you see yourself in the expectations of others. Just observing yourself from time to time may give you choices on what to lead and how much to lead. Since you are an inherent leader this is not difficult for you. Your observation skills and experiential memories are the best tools you have for engaging with life.'
                  }
                ],
                four_4: [
                  {
                    sec: 'Your intent to make life work at all costs is what makes you a leader.',
                    para: 'Your intuitive mind enables you to see things differently and draws very unique inferences, which can often lead to "wow" moments. However, these moments may also create self-doubt at times. By learning to believe more in yourself, you will become an invaluable asset to any team. If teaching is something that interests you, you have the potential to be an excellent teacher.'
                  }
                ],
                five_5: [
                  {
                    sec: 'Your intent for expanding yourself needs a plan more than courage and bravado.',
                    para: 'You place others\' successes and others\' dreams above your own. However, acknowledging your own dreams and defining your own success is necessary as it will give you a balanced sense of reality. You could be the one empowering others to fulfill their dreams by just sharing and living your dream with and for others.'
                  }
                ],
                six_6: [
                  {
                    sec: 'Your view of the world has expanded but you need to expand yourself too.',
                    para: 'Your creativity is your biggest asset but your rebellious nature destroys the same. Value your creativity, it will reward you amply. It seems like you are chosen one by the universe. Just when you think you have achieved it, the world around suddenly expands and you are found wanting more. You are not alone. Leaders like you would often wonder how much to grow and expand. The answer is ‘limitless’. Have fun.'
                  }
                ],
                seven_7: [
                  {
                    sec: 'You are a whole package of possibilities waiting to explode. Choose the right path.',
                    para: 'You are a person who needs a lot of appreciation and approach. You look at your life from the eyes of others rather than your own. Your sense of aspiration and importance is skewed. You want the world your way and have often achieved it with your intelligence and determination. Consider you may have the experience of being alone and not understood in spite of most things working for you. Doing a reality check may help.'
                  }
                ],
      }
          ],
      
          criti: [
              {
                one_1: [
                  {
                    sec: 'Being a carefree individual is great, but maintaining a sense of responsibility is essential.',
                    para: 'While you enjoy life, make sure your confidence does not intimidate others. Having fun, making others happy, taking life as it comes, don\'t be too serious with life, are mantras of your life. It works when you deal with life at the surface. If you want to deal with life a little deeper then you could reveal your responsible self too. Responsibility can also be fun if you change your perspective. Your jovial attitude with the expression of responsibility makes you a star.'
                  }
                ],
                two_2: [
                  {
                    sec: 'You are a self critic and could be at times harsh to yourself.',
                    para: 'Being positive in all circumstances is a mantra of your life but not taking cognizance of the impact of the breakdowns may make you delusional. The ability to observe the positivity and the negativity gives the more powerful balanced view rather than polarized view. Your decision making will prove far more effective in a balanced, than polarized view.'
                  }
                ],
                three_3: [
                    {
                      sec: 'Being positive is a good trait you have but check yourself for denial.',
                      para: 'You may often wonder why others look at life so negatively. Maybe they are doing so for your sake, so that you can absorb your positivity and their negativity with greater ease. Your need to remain positive at all times maybe is sourced in fear rather than joy. In acknowledging your fears you may move from positivity to possibility.'
                    }
                  ],
                  four_4: [
                    {
                      sec: 'Measuring your limitations and working on them is a trait that will take you ahead.',
                      para: 'Your ability to dream as well as be aware and in tune with reality is quite rare. This allows you to have empathy for others and nurture their inner qualities. Your abilities are more in-tune to being a teacher, trainer or facilitator rather than a leader. When you are empathetic, people may revere you. While you are manipulating, people may abhor you.'
                    }
                  ],
                  five_5: [
                    {
                      sec: 'Knowing one\'s limitations is good but using it as an excuse for failure is not wise.',
                      para: 'You have the balance of an analytical and creative brain. This enables you to make logical as well as intuitive decisions wherever required. You need to take care that this ability does not let you be manipulative. While you can understand others so much, you wish you would also be understood as much by others. Choosing those who you wish understood you is an art.'
                    }
                  ],
                  six_6: [
                    {
                      sec: 'You are maintaining a thin line between risk and gamble, balance yourself.',
                      para: 'The canvas of life is painted large by you. Take things one at a time, step by step. The canvas will fill up in due time. You are creative and love to think out of the box and live life on the edge. Exploring, adventuring and discovering are natural traits in you. However, you need to caution yourself from going overboard as you often do not maintain a structure which tells you when to stop. You accept failures but are never stopped by your failures.'
                    }
                  ],
                  seven_7: [
                    {
                      sec: 'Relationships matter to you more than anything. Choose your relationships carefully.',
                      para: 'Virtues of authenticity, intuition and observation within you are your best friends in times of crisis, confusion and chaos. Life is unfair, unjust and cruel. That\'s how life is. The harder you work for it to be otherwise the more it becomes the way it is. The best way sometimes is to let things be. Just being with things empowers you to have them change. It requires intent, observations, deep listening and patience. Virtues which you are capable of building.'
                    }
                  ],
        }
            ],
      
            futu: [
              {
                one_1: [
                  {
                    sec: 'Being ambitious in the future is good, but also balance it with reality in action.',
                    para: 'While you appreciate others you tend to criticise yourself too much. At times you are the exact opposite. Integrate yourself. You don\'t care about your future much now. You behave as if you don\'t care not because you are free but you have given up caring. Your jovial nature on the surface is to hide the resignation and pain within. A satire through humour is laced with layers of meaning. Your future is very promising if you allow transparency and vulnerability for yourself.'
                  }
                ],
                two_2: [
                  {
                    sec: 'You have the ability to think out of the box and constantly challenge yourself.',
                    para: 'Your openness to challenges is amazing. Nothing tires you and you have the power to devote yourself endlessly to something you love. You enjoy life itself. You want even others to enjoy but need to accept others have their own way to enjoy life. You need to include others in your way of life rather than coax or force them into your way of life.'
                  }
                ],
                three_3: [
                    {
                      sec: 'You have the possibility of a great future in a creative pursuit.',
                      para: 'You often feel bogged down by discussions of logic and reality. You often spend time trying to create your own reality. You need to believe in yourself and know you are special. Yet have compassion for all around you. Each one has their own reality accepting others reality expands your own. This life will not happen again, make the most of it.'
                    }
                  ],
                  four_4: [
                    {
                      sec: 'You choose to be the master of your own future and craft it yourself.',
                      para: 'Nothing can shake the spirit within you except your loved ones. Your weakest link is love and romance, while it is also your strongest and most intense emotion. You pride yourself on your intensity and depth of emotions. While it is a powerful way to express yourself, it could be suffocating and intimidating to others who are in a relationship with you.'
                    }
                  ],
                  five_5: [
                    {
                      sec: 'You have a strong sense of belief in yourself which will keep you in a good place.',
                      para: 'In matters of romance, you want the one you love to be the only one and risk everything for him. Your madness, passion, commitment and intensity are all extreme emotions rolled into one being which is you. Keep believing in yourself. Be aware of your intensity and you have the potential to rock the world.'
                    }
                  ],
                  six_6: [
                    {
                      sec: 'You know you are quite capable of anything, though it\'s better to focus on a few things to begin with.',
                      para: 'People may call you self-centred or self-indulgent but there may be layers of insecurity within which gives others this perception about you. You live as if you are the most powerful person in your world. This allows you to expand your sense of responsibility but makes everyone around you feel they are inferior and smaller than you. You need to have powerful people in your circle which will make you accountable to yourself.'
                    }
                  ],
                  seven_7: [
                    {
                      sec: 'Life is a bumpy ride, the bumps sometimes are the energy required. Go slow.',
                      para: 'You have done your bit to make life happen. Bother less on your needs and focus more on your expression. Once you find your expression, the needs will take care of themselves. Money and success will come provided you give up the chase. Time is not a function of what is lost but a function of what you have ahead and what you make of it. You have a generous heart but you need to give up stingy behaviour.'
                    }
                  ],
        }
            ]
      
      
        }
      ];
  
  
  
      export     const male_41_60 = [
        {
          app: [
            {
              // Applicability category content
              one_1: [
                {
                  sec: 'You have limited your scope of life and confined it to a few important things.',
                  para: 'You have tried many things in life and have limited yourself now here to your failures. You have become a little resigned and cynical with life and have a sense of injustice and unfairness. Forgiving yourself and others will restore your potential to fulfill what you always wanted to do for yourself.'
                }
              ],
              two_2: [
                {
                  sec: 'You feel you have tried too many things in life, it\'s time to focus.',
                  para: 'Experimenting and exploring is a good thing you have done. Now is the time to rethink and focus on what is important to you. You need to look at a few things which you want to dedicate your life to. Some dreams are best left unfulfilled. Be complete about them. Your focus on the few things has the power to redefine your future.'
                }
              ],
              three_3: [
                {
                  sec: 'You feel life is a little unfair and even trying so hard does not seem enough.',
                  para: 'It’s okay to fail. Failures are not meant to put you down but to teach you how to lift yourself up. Life is unfair, it always will be. Instead of asking for justice it’s better to accept the way it is and see what is possible now. You have good relationships, value them. Your tender heart and sensitivity are your greatest assets. Acknowledge them.'
                }
              ],
              four_4: [
                {
                  sec: 'You have tried many things in life and now choose to excel in what you have.',
                  para: 'It\'s amazing that you have such a balance with so many things in life and have maturity and wisdom in managing things. While being practical be careful not to lose those ideals which have made you what you are. Opinions of others are useful but do not judge yourself or others by those opinions.'
                }
              ],
              five_5: [
                {
                  sec: 'You are wanting to make it big in life and do something special.',
                  para: 'You have always been unique and different. Instead of seeking approval or proving yourself just accept that you are special. While it may work in your favour, accept that you could be misunderstood. You could turn out to be a leader if you focus on understanding others rather than making yourself understood.'
                }
              ],
              six_6: [
                {
                  sec: 'You may want many things in life, but acknowledging what you have is also fulfilling.',
                  para: 'You will not acknowledge your own successes enough and are so critical of your own self that it\'s almost like punishing oneself. You take criticism too harshly and doubt the acknowledgement you receive. Being kind to yourself in some places is a virtue not a compromise. Your power is unique when you receive criticism as a feedback. Show a correction.'
                }
              ],
              seven_7: [
                {
                  sec: 'You are trying too many things and feel so lost about the future.',
                  para: 'Life has been a rollercoaster ride where so much has happened that, man, it seems to have gotten out of control. It\'s best to take a step back and consolidate. Give up a few things and then ask what is the one thing you most want in life. Pursue that one thing and align everything around it.'
                }
              ]
            }
          ],
          work: [
            {
              one_1: [
                {
                  sec: 'The sense of failure that grips you is almost like a constant fear.',
                  para: 'Fear as a constant often limits you in exploring and inadvertently causes seeming failures. Areas of your life don’t work because you have become quite cautious in making things work. There was a time you had no fear and courage was your way of being. Let not fears and failures limit you.'
                }
              ],
              two_2: [
                {
                  sec: 'You have often tried to stay positive but you are not sure if it can bring success.',
                  para: 'Your attitude of being positive has kept you in good stead, though as a habit it can lead to moments of self denial and take you away from reality. Hence success may elude you in spite of all the hard work that you put in. You however have an uncanny ability to survive situations and overcome them.'
                }
              ],
              three_3: [
                  {
                    sec: 'You have worked to bring a certain stability and now seek to grow.',
                    para: 'Stability has been such a significant character/attitude to your decision making that you have averted unnecessary risks to your endeavours. Now is the time to grow but it\'s necessary that you take risks. You being a survivor could absorb these shocks. When these risks pay off, you will be on a roll.'
                  }
                ],
                four_4: [
                  {
                    sec: 'Making life work at all costs is your motto. Keep going the highway is yours.',
                    para: 'You live life passionately and are a great shock absorber. With passion and shock absorbing being the pillars of your life, you can grab success even when cornered by failure. Life for you is a huge stage and you are one of its charmed players. You would have lived your life when time comes calling.'
                  }
                ],
                five_5: [
                  {
                    sec: 'You put a lot of demand on yourself and can be stressed.',
                    para: 'You have a certain benchmark for most things in your life and keep working on those gaps but it could be often stressful as it leaves you in a continuous race of improvement. At times it\'s okay if some areas don’t work. You don’t have to make them all work. Be demanding of yourself, but don’t hurt yourself in the process is the word of caution.'
                  }
                ],
                six_6: [
                  {
                    sec: 'Your zest for life is unending. Living life to the fullest is what you want.',
                    para: 'You want to experience everything in life and leave nothing out. A lot of times your dreams are driven by others which makes you a work horse. Life is to be lived not worked. Instead of making everything work by yourself, take support or at times it\'s better to share responsibility. Stop, halt, acknowledge yourself, celebrate and then start again.'
                  }
                ],
                seven_7: [
                  {
                    sec: 'In the quest of being positive and making things work, you are often insensitive to others feelings.',
                    para: 'In wanting to make everything look positive you seem to have lost on the subtle and unsaid communications that life has been giving you. It might surprise you that you have been living a life of self denial in wanting to look happy and satisfied. Sharing yourself and being vulnerable may help you in being your true self.'
                  }
                ],
      }
          ],
      
          criti: [
              {
                one_1: [
                  {
                    sec: 'A sedentary, resigned and cynical life is not contentment. Confront life.',
                    para: 'You have chosen a sedentary lifestyle to avoid conflict and confrontation with life in order to keep your peace of mind. The external peace may leave you internally restless and may want your voice heard. Forgiving and beginning anew may help open up a new glorious chapter in your life.'
                  }
                ],
                two_2: [
                  {
                    sec: 'Life is the way it is. Withdrawal is not the solution, participating is.',
                    para: 'You have been through a hard life. Betrayal, cheating, losses, failures and such have often knocked you down but you haven\'t bowed down to those knocks. However, now life seems exhausting. You need a break and open up that aspect of your life which is so dear and you have kept it under wraps. Unravelling that is a source of joy.'
                  }
                ],
                three_3: [
                    {
                      sec: 'You have limited your options and are trying to find a safe place.',
                      para: 'Consolidation in life is often a great thing to do but that does not mean to you limit your options. That could be counter productive and invalidate everything you have done until now. You prefer risks but are scared to take them. You have many friends but are close to very few. You must just break free.'
                    }
                  ],
                  four_4: [
                    {
                      sec: 'Balancing out every area of life is what you like. Also discover your passionate work to express yourself.',
                      para: 'You have often assessed your areas of life and probably you are the best critic of yourself. You have a pathway to excellence which you do not give up in all this. Finding ways to keep yourself expressed rather than structured is essential. You want the world in your hand but you don\'t show it. But maybe you may even have it.'
                    }
                  ],
                  five_5: [
                    {
                      sec: 'Having worked hard for your achievements it\'s okay to be kind to yourself.',
                      para: 'Living a disciplined life and a demanding one, you have a serious approach to life and relationships. You have value and regard for most things and disregard very few things in life. Accepting your failures with grace and not being hard on yourself will reap richer rewards.'
                    }
                  ],
                  six_6: [
                    {
                      sec: 'You have taken risks and expanded life. Don’t blame yourself. Just consolidate.',
                      para: 'It’s possible to feel overwhelmed with what has happened with you in life. You want to cry at times “Enough is enough”. Acknowledge that you tried something bigger than yourself. Don\'t blame yourself for the things that didn\'t work out. Go slow in doing and start observing life. Things will take a dramatic turn for greater heights.'
                    }
                  ],
                  seven_7: [
                    {
                      sec: 'Living life on a razor edge is either a journey towards excellence or a nervous breakdown. Choose yours.',
                      para: 'You are almost a paranoid who wants to excel in everything you do. This could lead you to being a genius or you could encounter a nervous breakdown. You need to choose what you want to excel in and stay with that, the rest will align. This requires belief and confidence in yourself. Don\'t doubt yourself. Believe in yourself!!!'
                    }
                  ],
        }
            ],
      
            futu: [
              {
                one_1: [
                  {
                    sec: 'What’s the hurry? Even in youth you hurried and are still hurrying. Relax!',
                    para: 'The future is always created and fulfilled, not achieved and settled. You like to be busy and want to be successful. However, it is a reflection of how you feel like failure within and want to prove that wrong. Accepting failures is an act of courage not an exercise of a loser. Your vision needs to have a different point of view than the existing one.'
                  }
                ],
                two_2: [
                  {
                    sec: 'You may want many things in life, but acknowledging what you have is also fulfilling.',
                    para: 'You will not acknowledge your own successes enough and are so critical of your own self that it\'s almost like punishing oneself. You take criticism too harshly and doubt the acknowledgement you receive. Being kind to yourself in some places is a virtue not a compromise. Your power is unique when you receive criticism as a feedback. Show a correction.'
                  }
                ],
                three_3: [
                    {
                      sec: 'Your desire to be practical as well as ideal is not as conflicting as you think it is. Drawing balance is necessary.',
                      para: 'You are a combination of the logical and the creative. Functions require the logical mind and innovation requires a creative mind. Confusing one for the other can cause havoc and be detrimental to your mind. Choosing the right application of logic and creativity comes with practice where you learn rather than doubt. The right balance is your core.'
                    }
                  ],
                  four_4: [
                    {
                      sec: 'Planning a future and creating a structure to fulfil it is your forte. Keep it up.',
                      para: 'You are the master of your own future, is not a phrase for you. You choose to live and grow. You are a natural leader who has compassion and acceptance for his/her teammates and empowers them. Trust that ability. You think a lot for others which is a gift. However, care for yourself is also a responsibility.'
                    }
                  ],
                  five_5: [
                    {
                      sec: 'Your acknowledgement of your dreams fulfilled and experiencing gratitude propels you to bigger dreams.',
                      para: 'You have a unique ability of experiencing gratitude and acknowledging people and circumstances for your achievements and successes. Sharing these with people is an exponential multiplier. You need to learn to also give credit to yourself where it is due. Ignoring yourself in giving credit may be counterproductive and can be seen as lack of belief rather than humility which you possess.'
                    }
                  ],
                  six_6: [
                    {
                      sec: 'Having big dreams is great, building a heart of abundance is necessary.',
                      para: 'Your dreams are painted on a large canvas and you want to take on greater responsibility so that you can be powerful in contributing to people. Having a heart of abundance is essential for this which you are scared to open because of the fear of being hurt and betrayed. Doing something big needs you to be vulnerable. Vulnerability is a real power.'
                    }
                  ],
                  seven_7: [
                    {
                      sec: 'You prefer to live in an ideal world but it is possible that it\'s a fantasy.',
                      para: 'You often talk of high ideals, excellence and such grandiose things. Your actions don\'t match your thoughts and talk. You often feel nobody takes you seriously but you haven’t taken yourself seriously. You think one day something will happen and you will then choose to be serious and responsible. However, once you choose to be responsible, something will happen one day.'
                    }
                  ],
        }
            ]
      
      
        }
      ];
  
  
  
      export     const female_41_60 = [
        {
          app: [
            {
              // Applicability category content
              one_1: [
                {
                  sec: 'You have chosen to suppress so much in the name of love and sacrifice that you experience being taken for granted.',
                  para: 'It is possible that in wanting to show your love, you have begun to feel taken for granted. You often trust people easily and then are left feeling betrayed. You have often even wanted somebody to take a stand for you but each was found wanting. However your experience of being a victim is something you need to take responsibility for.'
                }
              ],
              two_2: [
                {
                  sec: 'You seem so resigned and cynical with life.',
                  para: 'You have a high sense of what is fair and unfair. In the process without being conscious you have become judgemental of self and others. It is possible that self-doubt has taken over your bold and courageous behaviour. You have been such a people’s person who has of late begun to be withdrawn. Being forgiving and accepting is best for you.'
                }
              ],
              three_3: [
                {
                  sec: 'You feel life is not just and a little unfair.',
                  para: 'You have always been someone who has challenged life’s norms and questioned realities. Your failures have pulled you back. You have the energy to pull yourself up. You need to believe in yourself rather than invest in seeking approvals and appreciation. Receiving criticism and praise with equanimity is your best bet in life.'
                }
              ],
              four_4: [
                {
                  sec: 'You want to challenge things and norms but feel it\'s too late now though you are not the one who gives up.',
                  para: 'You have always wanted to live life large and have often succeeded in doing so. Your confidence and belief in yourself even when faced with failures is your core power. It pays to be authentic and genuine rather than defensive and aggressive. Not everybody can be like you is something you need to understand.'
                }
              ],
              five_5: [
                {
                  sec: 'Expanding your life and living it large is someone like you.',
                  para: 'You have worked hard for everything in your life. Though life has not been fair with you, that has not stopped you from being expressive. Your ability to forgive others and include them in your space is worth acknowledging. It is very difficult to put you down anywhere.'
                }
              ],
              six_6: [
                {
                  sec: 'You have built your life step by step and now want to enjoy it, you deserve it.',
                  para: 'You need to enjoy what you created and acknowledge your own achievements. You are at times paranoid to protect yourself and those you love. Your ability to remain calm rather than paranoid is what will make the difference. You deserve everything you have and nothing is going to take it away from you.'
                }
              ],
              seven_7: [
                {
                  sec: 'You have too much on your plate right now.',
                  para: 'You have spread yourself too thin as the years have gone by. You are concerned about many things but taking too much on yourself will cost your peace and health. You need to share your responsibilities with others and include others in your duties and responsibilities of life. If required stop and go slow.'
                }
              ]
            }
          ],
          work: [
            {
              one_1: [
                {
                  sec: 'Limiting your world and relationships is not the answer to your problems.',
                  para: 'From someone who was curious and was free to explore things, you have begun to withdraw from life and relationships. Failures in life don\'t make you wrong. You need to wake up to how wrong you make yourself. People love you and respect you more than you know.'
                }
              ],
              two_2: [
                {
                  sec: 'You want to do so many things but are holding yourself back as you are a little scared.',
                  para: 'You have so many things on your mind and would like to live them out, but are often left questioning yourself and living in doubt. Over time you seem to be losing your voice. You have no idea that when you express yourself you end up touching people\'s hearts.'
                }
              ],
              three_3: [
                  {
                    sec: 'You have made some bold decisions, now is the time to stand by your decisions.',
                    para: 'You have often taken bold and courageous decisions in life. The  results of some of your decisions have not been fruitful. That’s okay. Standing by your decisions with faith rather than fear or stubbornness will yield fruits in the long run. You often feel time is running out but things can change in a day.'
                  }
                ],
                four_4: [
                  {
                    sec: 'You are someone who has a balanced life and is making things work by believing in yourself.',
                    para: 'Your ability to question others and demand from others is a function of how much one questions oneself and demands from oneself. You are a true expression of being balanced. The balance that you have achieved in life is not by luck or an accident. It\'s through hard meticulous work.'
                  }
                ],
                five_5: [
                  {
                    sec: 'You are often stressed in keeping others happy and care less about yourself.',
                    para: 'Your health is now your biggest concern. You seem to neglect yourself and your health while paying great attention to others. Your need to make others happy at all times is the burden you have limited on yourself. Focus on your health and you will get much done in less time.'
                  }
                ],
                six_6: [
                  {
                    sec: 'You think time is running out and you need to complete many things before the final exit.',
                    para: 'To make everything work in life is not a necessity. It\'s okay if somethings don\'t work, accept that you are a human and things can go wrong. Even if you have a few years to live, yet it\'s not necessary to make everything work. When you have so many years left, don\'t waste it wanting to make everything work. Some things work best when they take their own course.'
                  }
                ],
                seven_7: [
                  {
                    sec: 'You are unclear about what you want and are at times insensitive to others.',
                    para: 'You are working hard everywhere and questioning everything and everybody to almost a fault. You could be appreciative of the efforts of others instead of claiming to be a perfectionist. Your fear of failing to live a perfect life can actually cause a sudden breakdown in life. You could take time out with your friends for a change.'
                  }
                ],
      }
          ],
      
          criti: [
              {
                one_1: [
                  {
                    sec: 'Being casual with life is great if it\'s not a tool to hide your true feelings.',
                    para: 'You keep a happy-go-lucky exterior and a troubled and deeply private interior. You take efforts to bring joy to others and find it difficult to face your own grief and sorrow. You could explore so many of your talents but are scared to share or reveal your true self.'
                  }
                ],
                two_2: [
                  {
                    sec: 'You are cautious and defensive when truly you want to be creative and take some risks.',
                    para: 'You have coped well with your cautious and defensive personality but it has cost you your freedom of creativity and the ability to take risks. You often take the middle path to avoid conflicts when in truth you want to face it like a bull or a lion. It\'s time you took a stand for your true self.'
                  }
                ],
                three_3: [
                    {
                      sec: 'You have worked hard in life and want to see results before you take on anything else.',
                      para: 'You have put in all the effort in life and are waiting for the results. The learning from the efforts is more important than the results. You need to tell yourself you have done your best. Life does not end tomorrow. You are a creator of possibilities and opportunities rather than a seeker of awards and accolades.'
                    }
                  ],
                  four_4: [
                    {
                      sec: 'You are lucky and blessed that things often work in your favour without trying too hard.',
                      para: 'You often have things going your way even in the strangest of circumstances. You could consider it being lucky or blessed or maybe a genetic trait to live in possibilities and belief rather than denial and fear. You are different than most. Enjoy the difference and accept people without judging.'
                    }
                  ],
                  five_5: [
                    {
                      sec: 'You are too sensitive and have a harder judgement about yourself.',
                      para: 'Being self critical is a good way of inquiring, but a permanent state of self-criticism can only cause doubt and reduce self belief. Harsh judgements on yourself leaves you resentful and suppressed. You could convert this self critical nature to being observant, aware and intuitive if you stop being harsh, resentful and suppressed.'
                    }
                  ],
                  six_6: [
                    {
                      sec: 'You have restricted yourself and want to now explore and explode into life.',
                      para: 'Your sudden spurt to explore and explode yourself is a reflection of years of suppression, adjustments and compromises. Explore, you must. But exploding yourself could cause harm to you and to others, most importantly those you love. Spend time inquiring your reasons for such suppression, adjustments and compromises.'
                    }
                  ],
                  seven_7: [
                    {
                      sec: 'You are overwhelmed and overstressed with life in general. You want to make it perfect.',
                      para: 'You can do what you want with life, it\'s never going to be perfect. The imperfections of life embellish life itself. Cherish them rather than correcting them. Cherishing the imperfections brings down the stress and increases the enjoyment of life. Your intelligence and heart, when aligned, makes you a potent force that can bring the desired change in your environment.'
                    }
                  ],
        }
            ],
      
            futu: [
              {
                one_1: [
                  {
                    sec: 'You want to make up for everything you failed in the past to feel complete and fulfilled.',
                    para: 'You want to use the future to correct most things of your past when in truth you need to accept your past and create a new future. Your past has been a little troubled and unfair. Fighting the past will only leave you further bitter. You have a family that loves you beyond everything.'
                  }
                ],
                two_2: [
                  {
                    sec: 'You have the skill and talent for making the future happen. Your temperament is the only concern.',
                    para: 'You have been gifted with so many talents that it may, to the normal eye, seem like a miracle. Yet you struggle to be successful and fulfilled with life. The true reason is your temperament. Your volatile and unpredictable temperament is the source and cause of all your worries.'
                  }
                ],
                three_3: [
                    {
                      sec: 'Your need for others approval for your future is the only thing that limits you.',
                      para: 'You exactly know the future you want. You are gifted with the necessary talents and abilities to create that future. In creating that future freedom and power beckons you. However, your need for others approval and the fear of being ridiculed is stopping you from taking on the future you want.'
                    }
                  ],
                  four_4: [
                    {
                      sec: 'You have a great skill in managing your resources and producing effective results.',
                      para: 'Your ability to keep things simple and the attitude to find possibilities in all circumstances seems to be your magic mantra. You are definitely a charm but there is a silent mystery to how you manage everything so well. Your upbringing must have been the foundation of this charm.'
                    }
                  ],
                  five_5: [
                    {
                      sec: 'Your living-on-the-edge attitude all the time gives you more perspectives to life than others.',
                      para: 'You even today continue to at times carry your ‘don\'t care’ attitude even if others think you are foolish. Your belief in yourself is what keeps you so happy. Living on the edge is your magic power. Your alertness and sharpness is what keeps you abreast and ahead of most things and people.'
                    }
                  ],
                  six_6: [
                    {
                      sec: 'If you want something then you should go for it, not wait for it.',
                      para: 'You have grand dreams of the future for yourself and your family. You have great insight to life but poor confidence in sharing those. In your inability to share,  you are limited in what you can do for the future. This leaves much of your future paralyzed than possible. Wake up to your fears and begin expressing.'
                    }
                  ],
                  seven_7: [
                    {
                      sec: 'Being responsible for your future is essential else you will keep dreaming big and achieving nothing.',
                      para: 'Having and painting a large canvas to life is something you are often good at. However, making that canvas real, requires tremendous commitment which you lack and are scared to do so. It\'s better to have a simple future and fulfill it rather than a grand future for which you didn\'t take a single step.'
                    }
                  ],
        }
            ]
      
      
        }
      ];


      export   const blocks = [
        { title: "Technology", xRange: [0.0, 0.15], yRange: [0.7, 0.9] },
        { title: "Technology", xRange: [0.56, 0.81], yRange: [0.0, 0.36] },
        { title: "Data", xRange: [0.0, 0.15], yRange: [0.5, 0.7] },
        { title: "Data", xRange: [0.31, 0.56], yRange: [0.0, 0.36] },
        { title: "Coding", xRange: [0.0, 0.31], yRange: [0.0, 0.36] },
        { title: "Coding", xRange: [0.0, 0.15], yRange: [0.36, 0.50] },
        { title: "Finance", xRange: [0.15, 0.34], yRange: [0.75, 0.9] },
        { title: "Finance", xRange: [0.62, 0.8], yRange: [0.36, 0.51] },
        { title: "Marketing", xRange: [0.15, 0.34], yRange: [0.6, 0.75] },
        { title: "Marketing", xRange: [0.43, 0.62], yRange: [0.36, 0.51] },
        { title: "Business", xRange: [0.15, 0.43], yRange: [0.36, 0.51] },
        { title: "Business", xRange: [0.15, 0.34], yRange: [0.51, 0.6] },
        { title: "Humanities", xRange: [0.34, 0.5], yRange: [0.74, 0.9] },
        { title: "Humanities", xRange: [0.61, 0.81], yRange: [0.51, 0.68] },
        { title: "Personal Growth", xRange: [0.34, 0.61], yRange: [0.51, 0.69] },
        { title: "Personal Growth", xRange: [0.34, 0.5], yRange: [0.69, 0.74] },
        // { title: "Content", xRange: [0.5, 0.81], yRange: [0.69, 0.9] },
        { title: "Content", xRange: [0.5, 0.52], yRange: [0.87] },//right
        { title: "Content", xRange: [0.5, 0.52], yRange: [0.88] },//right
        { title: "Content", xRange: [0.5, 0.51], yRange: [0.89] },//right
        { title: "Content", xRange: [0.5, 0.53], yRange: [0.87] },//right
        { title: "Content", xRange: [0.5, 0.54], yRange: [0.87] },//right
        { title: "Content", xRange: [0.5, 0.55], yRange: [0.86] },//
        { title: "Content", xRange: [0.5, 0.56], yRange: [0.85] },//
        { title: "Content", xRange: [0.5, 0.57], yRange: [0.84] },//
        { title: "Content", xRange: [0.5, 0.58], yRange: [0.84] },//
        { title: "Content", xRange: [0.5, 0.59], yRange: [0.83] },//
        { title: "Content", xRange: [0.5, 0.6], yRange: [0.82] },//
        { title: "Content", xRange: [0.5, 0.61], yRange: [0.82] },//
        { title: "Content", xRange: [0.5, 0.62], yRange: [0.81] },//

        // { title: "Content", xRange: [0.54, 0.81], yRange: [0.87] },//
        // { title: "Content", xRange: [0.64, 0.81], yRange: [0.80] },//
        // { title: "Content", xRange: [0.67, 0.81], yRange: [0.78] },//
        // { title: "Content", xRange: [0.70, 0.81], yRange: [0.76] },//
        // { title: "Content", xRange: [0.71, 0.81], yRange: [0.75] },//
        // { title: "Content", xRange: [0.74, 0.81], yRange: [0.73] },
        // { title: "Content", xRange: [0.76, 0.81], yRange: [0.72] },
        // { title: "Content", xRange: [0.77, 0.81], yRange: [0.71] },//
        // { title: "Content", xRange: [0.79, 0.81], yRange: [0.70] },//
        { title: "Content", xRange: [0.5, 0.54], yRange: [0.87] },
        { title: "Content", xRange: [0.5, 0.64], yRange: [0.80] },
        { title: "Content", xRange: [0.5, 0.67], yRange: [0.78] },
        { title: "Content", xRange: [0.5, 0.70], yRange: [0.76] },
        { title: "Content", xRange: [0.5, 0.71], yRange: [0.75] },
        { title: "Content", xRange: [0.5, 0.74], yRange: [0.73] },
        { title: "Content", xRange: [0.5, 0.76], yRange: [0.72] },
        { title: "Content", xRange: [0.5, 0.77], yRange: [0.71] },
        { title: "Content", xRange: [0.5, 0.79], yRange: [0.70] },

        
        { title: "Content", xRange: [0.5, 0.63], yRange: [0.80] },//
        { title: "Content", xRange: [0.5, 0.64], yRange: [0.79] },//
        { title: "Content", xRange: [0.5, 0.65], yRange: [0.79] },//
        { title: "Content", xRange: [0.5, 0.66], yRange: [0.78] },//
        { title: "Content", xRange: [0.5, 0.67], yRange: [0.77] },//
        { title: "Content", xRange: [0.5, 0.68], yRange: [0.77] },//
        { title: "Content", xRange: [0.5, 0.69], yRange: [0.76] },//
        { title: "Content", xRange: [0.5, 0.70], yRange: [0.75] },//
        { title: "Content", xRange: [0.5, 0.71], yRange: [0.74] },//
        { title: "Content", xRange: [0.5, 0.72], yRange: [0.74] },//
        { title: "Content", xRange: [0.5, 0.73], yRange: [0.73] },//
        { title: "Content", xRange: [0.5, 0.74], yRange: [0.72] },//
        { title: "Content", xRange: [0.5, 0.75], yRange: [0.72] },//
        { title: "Content", xRange: [0.5, 0.76], yRange: [0.71] },//
        { title: "Content", xRange: [0.5, 0.77], yRange: [0.70] },//
        { title: "Content", xRange: [0.5, 0.78], yRange: [0.70] },//
        { title: "Content", xRange: [0.5, 0.78], yRange: [0.69] },//
        { title: "Content", xRange: [0.5, 0.79], yRange: [0.69] },//
        { title: "Content", xRange: [0.5, 0.80], yRange: [0.69] },//

        // { title: "Content", xRange: [0.5, 0.79], yRange: [0.69] },
        // { title: "Content", xRange: [0.5, 0.80], yRange: [0.69] },


        // { title: "Design", xRange: [0.50, 0.81], yRange: [0.9] }, //went to humanities
        { title: "Design", xRange: [0.51, 0.81], yRange: [0.9] },  //right
        { title: "Design", xRange: [0.52, 0.81], yRange: [0.89] },//right
        { title: "Design", xRange: [0.53, 0.81], yRange: [0.88] },//right
        // { title: "Design", xRange: [0.55, 0.81], yRange: [0.87] },//
        { title: "Design", xRange: [0.56, 0.81], yRange: [0.86] }, //right
        { title: "Design", xRange: [0.57, 0.81], yRange: [0.85] },///right
        { title: "Design", xRange: [0.58, 0.81], yRange: [0.85] },//right
        { title: "Design", xRange: [0.59, 0.81], yRange: [0.84] },//right
        { title: "Design", xRange: [0.60, 0.81], yRange: [0.83] },//right
        { title: "Design", xRange: [0.61, 0.81], yRange: [0.83] },//right
        { title: "Design", xRange: [0.62, 0.81], yRange: [0.82] },//riight
        { title: "Design", xRange: [0.63, 0.81], yRange: [0.81] },//right
        { title: "Design", xRange: [0.65, 0.81], yRange: [0.80] },//right
        { title: "Design", xRange: [0.66, 0.81], yRange: [0.79] },//right
        { title: "Design", xRange: [0.68, 0.81], yRange: [0.78] },//right
        { title: "Design", xRange: [0.69, 0.81], yRange: [0.77] },//right
        { title: "Design", xRange: [0.72, 0.81], yRange: [0.75] },//right
        // { title: "Design", xRange: [0.73, 0.81], yRange: [0.74] },
        { title: "Design", xRange: [0.75, 0.81], yRange: [0.73] },//right
        { title: "Design", xRange: [0.78, 0.81], yRange: [0.71] },//right
        { title: "Design", xRange: [0.80, 0.81], yRange: [0.70] },//right
        { title: "Design", xRange: [0.81, 0.81], yRange: [0.69] }
        // { title: "Design", xRange: [0.5, 0.81], yRange: [0.69, 0.9] }
      ];
      

      export const  Coding =[
    
        {Behaviour:'Routine & Regular', Careers:"Logistics, Operations, Managing & maintaining data security and security systems",Language_of_Being:'Execution'},
       
       ]
    
    export const  Data=[
        
        {Behaviour:'Logic & Rules', Careers:"Data Science, Operation Research, Auditing, Process Control, Systems Development & Management",Language_of_Being:'Execution'},
       
       ]
    
     export const Technology=[
        
        {Behaviour:'Logic & Possibilities', Careers:"Engineering, Medicine, Science, Research, Function Design, Software Management and Development",Language_of_Being:'Execution'},
       
       ]
    
     export const  Business=[
        
        {Behaviour:'Responsibility', Careers:"Entrepreneurship, Innovation, Commerce, Engineering",Language_of_Being:'Facilitation'},
       
       ]
    
       
       export const  Marketing=[
        
        {Behaviour:'Sharing', Careers:"Training & Development, People Management, MBA (Marketing), Sales, Customer Relationship Management, Insurance Advisor",Language_of_Being:'Facilitation'},
       
       ]
    
       export  const   Finance=[
        
        {Behaviour:'Accountability & Integrity', Careers:"MBA (Finance), Wealth Management, Financial Management, Process and System Management",Language_of_Being:'Instruction'},
       
       ]
    
        export const   Personal_Growth=[
        
        {Behaviour:'Internal Growth', Careers:"MBA (Human Resources), Sports, Sales, Training & Development, Teaching",Language_of_Being:'Instruction'},
       
       ]
    
       export const Humanities=[
        
        {Behaviour:'Social & Compassionate', Careers:"MBA (Human Resources), Training, Teaching, Music, Art, People Management and Development",Language_of_Being:'Instruction / Creation'},
       
       ]
    
      export const Content=[
        
        {Behaviour:'Thinker & Sensitive', Careers:"Writing, Reading, Traveller, Author, Marketing, Advertising",Language_of_Being:'Creation'},
       
       ]
    
       export const  Design=[
        
        {Behaviour:'Creativity & Discipline', Careers:"Innovation, Entrepreneurship, MBA (Business Design), Designing, Entrepreneurship & Change Management, Financial Leadership",Language_of_Being:'Creation'},
       
       ]


       export const powerranger =[
    
        {min:0,max:0.40,Pathway:'Read Handbook for Life(Order on Amazon)',Books:"Upward Spiral by Alex Korb,Alchemist by Paulo Coelho",MoviesE:"Blind Side,Good Will Hunting,Inside Out",MoviesH:"English Vinglish,Gully Boy,Dangal"},
        {min:0.41,max:0.50,Pathway:'Curriculum for Life(Self-Study)',Books:"Who Moved My Cheese by Spencer Johnson,Atomic Habits by James Clear",MoviesE:"Yes Man,Coach Carter,Creed,Forest Gump,Erin Brockovich",MoviesH:"Mary Kom,Bhaag Milkha Bhaag,Pan Man,Stanley Ka Dabba"},
          {min:0.51,max:0.60,Pathway:'Conversations for Life(One to One)+Program for Life(Classroom)',Books:"The Monk Who Sold His Ferrari by Robin Sharma,How to Stop Worrying and Start Living by Dale Carnegie",MoviesE:"Phenomena,Dead Man Walking,Pursuit of Happiness,Forest Gump",MoviesH:"Chak De India,3 Idiots,Zindagi Na Milegi Dobara,Gold,Rang De Basanti"},
         {min:0.61,max:0.70,Pathway:'Program for Life(Classroom)',Books:"The Power of Now by Eckhart Tolle,The Power of Positive Thinking by Norman Vincent Peale",MoviesE:"Invictus, Good Will Hunting, Bruce Almighty, The Shawshank Redemption",MoviesH:"Rocket Singh,Barfi,Panga,Border"},
          {min:0.71,max:0.80,Pathway:'Program for Life(Classroom)+Creating a Way of Life(Classroom & One to One)',Books:"How to Win Friends & Influence People by Dale Carnegie,Rich Dad and Poor Dad by Robert Kiyosaki and Sharon Lechter",MoviesE:"Coco, A Beautiful Day In The Neighbourhood,Inception,Gridiron Gang,The Intouchables",MoviesH:"Sky Is Pink,Manji,Guru"},
           {min:0.81,max:0.85,Pathway:'Program for Life (Classroom)+Fellowship(Classroom)',Books:"Outliers by Malcolm Gladwell,Man’s Search for Meaning by Viktor Frankl",MoviesE:"The End of All WarsMagnolia,Peaceful Warrior,Jobs,Dead Poets Society",MoviesH:"Dear Zindagi,Ferrari Ki Sawari,Swades,Chichore"},
        {min:0.86,max:1,Pathway:'Read recommended books & watch recommended movies',Books:"Outliers by Malcolm Gladwell,48 Laws of Power by Robert Greene,Meditations by Marcus Aurelius",MoviesE:"Atonement,Manchester by the Sea,Creed,Warrior",MoviesH:"Wake Up Sid"},
]

