import React, { useEffect, useRef, useState } from 'react';
 import img1 from './page1.png';   
import img2 from './page2.2.jpg';  
import img3 from './page3.png';  
import top from './tapping.png';  
import img5 from './pagefull5.jpg';  
import img7 from './page7.png';  
import img99 from './pagefull8.jpg';  
import img999 from './pagefull9.jpg';  
import img10 from './pagefull10.jpg';  
 
import img11 from './pagefull11.jpg';  
import img12 from './payfull12.jpg';  
import img13 from './pagefull13.jpg';  
import img14 from './pagefull14.jpg';  
import img15 from './pagefull15.jpg';  
import img16 from './payfull16.jpg';  

import pagenumber3 from './rework3.png';  
import pagenumber4 from './page4.png';  
import pagenumber8 from './page8.png';  
import { RadialChart } from './RadialChart';
import axios from 'axios';
import ScatterPlot from './ScatterPlot';
import Plot1 from './Plot1';
import { blocks, Business, Coding, Content, Data, Design, female_16_24, female_25_40, female_41_60, Finance, Humanities, male_16_24, male_25_40, male_41_60, Marketing, Personal_Growth, powerranger, Technology } from '../Para';
import { he, rs, sa, we, woe } from './Circle';
 import { useParams } from 'react-router-dom';
 import { baseurlvmkp } from './Baseurl';



const Pdf = () => {
  // Trigger PDF  download on page load
    // Empty array means it will run on mount
// console.log( divElement = document.querySelector(".box"));
let { id } = useParams();



const printButtonRef = useRef(null);
const [isLoading, setIsLoading] = useState(true);
useEffect(() => {
   if (printButtonRef.current) {
    printButtonRef.current.click();
  }

   const handleAfterPrint = () => {
    if (printButtonRef.current) {
      printButtonRef.current.click();
    }
  };

  window.addEventListener("afterprint", handleAfterPrint);

   return () => {
    window.removeEventListener("afterprint", handleAfterPrint);
  };
}, []);


const handlePrint = () => {
  window.print();
};


const [data, setData] = useState([0.67, 0.66, 0.77, 0.88, 0.68]);
const [data1, setData1] = useState([0.67, 0.66, 0.77, 0.88, 0.68]);

const[categories,setcategories]= useState([])
// const data = [0.67, 0.66, 0.77, 0.88, 0.68]; // Values for each category (scaled from 0 to 1)
// const categories = ['Balanced & Engaged', 'Health Relationships', 'Wealth Relationships', 'Generous & Wise', 'Committed & Discovering']; // Categories
const categories1 = ['Relationships', 'Health', 'Wealth', 'Work and Education', 'Self Awareness']; // Categories
const screenWidth = window.innerWidth;
const screenHeight = window.innerHeight;
const dynamicRadius = Math.min(screenWidth, screenHeight) / 2;
// Calculate the radius based on screen size
const [radius, setRadius] = useState(dynamicRadius);
const [dataPoints, setDataPoints] = useState([]);
const [paragenderage, setParagenderage] = useState([])

const [applicability,setapplicability]=useState([])
const [workability,setworkability]=useState([])
const [criticality,setcriticality]=useState([])
const [futurality,setfuturality]=useState([])

function datachecker(data,points) {
 console.log(data[0].app[0].one_1)
 console.log(points[0].applicability)
// applicability
if( getRange(points[0].applicability)==1){
  setapplicability(data[0].app[0].one_1)
}
else if( getRange(points[0].applicability)==2){
  setapplicability(data[0].app[0].two_2)
}else if( getRange(points[0].applicability)==3){
  setapplicability(data[0].app[0].three_3)
}else if( getRange(points[0].applicability)==4){
  setapplicability(data[0].app[0].four_4)
}else if( getRange(points[0].applicability)==5){
  setapplicability(data[0].app[0].five_5)
}else if( getRange(points[0].applicability)==6){
  setapplicability(data[0].app[0].six_6)
}else if( getRange(points[0].applicability)==7){
  setapplicability(data[0].app[0].seven_7)}

// workcability

if( getRange(points[0].workability)==1){
  console.log(data[0].work[0].one_1)
}
else if( getRange(points[0].workability)==2){
 setworkability(data[0].work[0].two_2)
}else if( getRange(points[0].workability)==3){
 setworkability(data[0].work[0].three_3)
}else if( getRange(points[0].workability)==4){
 setworkability(data[0].work[0].four_4)
}else if( getRange(points[0].workability)==5){
 setworkability(data[0].work[0].five_5)
}else if( getRange(points[0].workability)==6){
 setworkability(data[0].work[0].six_6)
}else if( getRange(points[0].workability)==7){
 setworkability(data[0].work[0].seven_7)
}
// criticality
if( getRange(points[0].criticality)==1){
  setcriticality(data[0].criti[0].one_1)
}
else if( getRange(points[0].criticality)==2){
 setcriticality(data[0].criti[0].two_2)
}else if( getRange(points[0].criticality)==3){
 setcriticality(data[0].criti[0].three_3)
}else if( getRange(points[0].criticality)==4){
 setcriticality(data[0].criti[0].four_4)
}
else if( getRange(points[0].criticality)==5){
 setcriticality(data[0].criti[0].five_5)
}else if( getRange(points[0].criticality)==6){
 setcriticality(data[0].criti[0].six_6)
}else if( getRange(points[0].criticality)==7){
 setcriticality(data[0].criti[0].seven_7)
}


if( getRange(points[0].futurality)==1){
 setfuturality(data[0].futu[0].one_1)
}
else if( getRange(points[0].futurality)==2){
 setfuturality(data[0].futu[0].two_2)
}else if( getRange(points[0].futurality)==3){
 setfuturality(data[0].futu[0].three_3)
}else if( getRange(points[0].futurality)==4){
 setfuturality(data[0].futu[0].four_4)
}
else if( getRange(points[0].futurality)==5){
 setfuturality(data[0].futu[0].five_5)
}else if( getRange(points[0].futurality)==6){
 setfuturality(data[0].futu[0].six_6)
}else if( getRange(points[0].futurality)==7){
 setfuturality(data[0].futu[0].seven_7)
}


else{
  console.log("not working")
}


}
 
useEffect(() => {
  // DownloadPDF()

  
}, [ ])
const [displaywheel, setDisplaywheel] = useState(false)

const [average, setAverage] = useState([])
const calculateMinMax = (nums) => {
  nums = nums.map(num => Number(num));

  const min = Math.min(...nums);
  const max = Math.max(...nums);
console.log(Number(min) >= 0.6 && Number(max) >= 0.6)
if (Number(min) >= 0.6 && Number(max) >= 0.6) {
  const minConverted = Math.floor(min * 100);
    const maxConverted = Math.floor(max * 100);

     let result = (minConverted / maxConverted) * 100;
    
    result = Number(result.toFixed(2)); // Convert to number and round to 2 decimals

    console.log("right", result);
    setDisplaywheel(true)
    setAverage(result)

    return { min: minConverted, max: maxConverted, result: result };
  } else {
    console.log("wrong");
    const minConverted = Math.floor(min * 100);
    const maxConverted = Math.floor(max * 100);
    setDisplaywheel(false)
     let result = (minConverted / maxConverted) * 100;
    
    result = Number(result.toFixed(2)); // Convert to number and round to 2 decimals
    console.log("wrong", result);
    setAverage(result)
     return { error: 'Wheel Alignment Score is not calculated for values below 0.6.' };
  }
};

const[rsdata,setrsdata]=useState([])
const[heData,setheData]=useState([])
const[weData,setweData]=useState([])
const[woeData,setwoeData]=useState([])
const[saData,setsaData]=useState([])  
const[userdata,setuserdata]=useState([])
console.log(rsdata)

const [dataneed,setDataNeed]=useState([])
const[carrier,setcarrier]=useState([])
useEffect(() => {
  axios.get(`${baseurlvmkp}/index/get/${id}` ).then((response) => {
    console.log(response.data)
    const x1=response.data[0].x_AXIS
    const y1=response.data[0].y_AXIS
      console.log(  getRange(response.data[0].applicability));
       setDataPoints([{x:x1,y:y1}]);
    setData([response.data[0].relationships,response.data[0].health,response.data[0].wealth,response.data[0].workEducation,response.data[0].selfAwareness])
const points=response.data

setData1([response.data[0].relationships,response.data[0].health,response.data[0].wealth,response.data[0].workEducation,response.data[0].selfAwareness,response.data[0].average])

 const relationshipData = rs.find(item => response.data[0].relationships >= item.min && response.data[0].relationships <= item.max);
console.log(relationshipData)
setcarrier(powerranger?.find(item => response.data[0].average >= item.min && response.data[0].average <= item.max))
const healthdata = he.find(item => response.data[0].health >= item.min && response.data[0].health <= item.max);
const wealth=we.find(item => response.data[0].wealth >= item.min && response.data[0].wealth <= item.max);
 const workEducationdata=woe.find(item => response.data[0].workEducation >= item.min && response.data[0].workEducation <= item.max);
const selfAwarenessdata=sa.find(item => response.data[0].selfAwareness >= item.min && response.data[0].selfAwareness <= item.max);

calculateMinMax([response.data[0].relationships,response.data[0].health,response.data[0].wealth,response.data[0].workEducation,response.data[0].selfAwareness])

 setheData(healthdata)
 setsaData(selfAwarenessdata)
 setwoeData(workEducationdata)
setrsdata(relationshipData)
setweData(wealth)
const blockName = findBlockName(x1, y1);
  
  const dataMap = {
    "Coding": Coding,
    "Data": Data,
    "Technology": Technology,
    "Business": Business,
    "Marketing": Marketing,
    "Finance": Finance,
    "Personal Growth": Personal_Growth,
    "Humanities": Humanities,
    "Design":Design,
    "Content":Content,
  };

  const dataToSet = dataMap[blockName] || [];
  
  setDataNeed(dataToSet);
  console.log(dataToSet)
setcategories([relationshipData.title,healthdata.title,wealth.title,workEducationdata.title,selfAwarenessdata.title])
    axios.get(`${baseurlvmkp}/vmkp/get/${id}`)
    .then(response => {
      console.log(response.data);
      setuserdata(response.data)
       if (response.data.age >= 41 && response.data.age <= 60) {
        return response.data.gender === 'Female' ?datachecker( female_41_60 ,points):datachecker( male_41_60,points );
      } else if (response.data.age >= 25 && response.data.age <= 40) {
        return response.data.gender === 'Female' ? datachecker(female_25_40,points ):datachecker(male_25_40 ,points);
      } else if (response.data.age >= 16 && response.data.age <= 24) {
        return response.data.gender === 'Female' ? datachecker(female_16_24,points) :datachecker(male_16_24,points);
      } else {
        return console.log('Age Grp Not found ');
      }



 

    })
    .catch(error => {
      console.error(error);
    });


 

}).catch((error)=>{
    console.log(error)
             }).finally(() => {
              //  setIsLoading(false);
              //  setTimeout(() => {
              //   window.print();


              //  }, 3000);
            
             })



  const updateRadius = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const newRadius = Math.min(width, height) * 0.4;  
    setRadius(newRadius);
  };

   updateRadius();
  window.addEventListener('resize', updateRadius);

  return () => {
    window.removeEventListener('resize', updateRadius);
  };
}, []);
console.log(applicability,workability,futurality,criticality)
const splitIntoTwoLines = (text) => {
  if (!text) return "";  

  const words = text.split(" ");
  const midIndex = Math.ceil(words.length / 2); 
  const firstLine = words.slice(0, midIndex).join(" ");  
  const secondLine = words.slice(midIndex).join(" "); 

  return `${firstLine}, ${secondLine}`; 
};

function getRange(number) {
   const ranges = [
    { min: 0, max: 0.3, range: 1 },
    { min: 0.31, max: 0.4, range: 2 },
    { min: 0.41, max: 0.5, range: 3 },
    { min: 0.51, max: 0.6, range: 4 },
    { min: 0.61, max: 0.7, range: 5 },
    { min: 0.71, max: 0.8, range: 6 },
    { min: 0.81, max: Infinity, range: 7 },
  ];

   for (const range of ranges) {
    if (number >= range.min && number <= range.max) {
      return range.range;
    }
  }

   return null;
}
 
function findBlockName(x, y) {
   const adjustedX = x >= 1.0 ? 0.81 : x;
  const adjustedY = y >= 1.0 ? 0.81 : y;

  const block = blocks.find(b => 
    adjustedX >= b.xRange[0] && adjustedX <= b.xRange[1] &&
    adjustedY >= b.yRange[0] && adjustedY <= b.yRange[1]
  );

  return block ? block.title : "No block contains the given point";
}
 

return (
  <>
  {/* glasses */}
  <div className=' ' id='santizer'>
   
    <div className="pdf-container">
      {/* These elements are for layout only; they won't be visible since we're downloading the PDF automatically */}
      <img src={img1} alt="Description of Image" className="full-image" />
      <div className="top-right" style={{textTransform:'capitalize'}}>
        {userdata.first_name} {userdata.last_name}
      </div>
      <div className="subtext" style={{textTransform:'capitalize'}}>
        {userdata.age} | {userdata.gender} | {userdata.occupation}
      </div>
    </div>
  
    <div className="pdf-container">
      {/* These elements are for layout only; they won't be visible since we're downloading the PDF automatically */}
      <img src={img2} alt="Description of Image" className="full-image" />
     
    </div>

    
    <div className="pdf-container">
      {/* These elements are for layout only; they won't be visible since we're downloading the PDF automatically */}
      <img src={img3} alt="Description of Image" className="full-image" />
     
    </div>


    <div className=" fgfg  " style={{alignItems:'normal',flexDirection:'column',    width: "103%",left:"-10px",
    position: "relative",pageBreakAfter:'always'}}>
      <img src={top} style={{zIndex:'0',width:"100%",height:'100%',zIndex:0}} alt="Description of Image" className="full-image"  />
<div style={{padding:'51px'}} >
<h1 style={{marginLeft:'20px',fontWeight:'400',color:'#414649',textTransform:'capitalize'}}>Dear {userdata.first_name},</h1>
{/* <br></br> */}
<div  style={{ marginTop:'20px',padding:'12px',color:'black',height:'750px' }}>
    <b style={{fontSize:'19px'}}>
{applicability[0]?.sec}
    </b>

    <p style={{textAlign:'justify'}}>
   {applicability[0]?.para}
    </p>
    {/* <br></br> */}
    <b style={{fontSize:'19px'}}>
{workability[0]?.sec}    </b>

    <p style={{textAlign:'justify'}}>
    {workability[0]?.para} 
    </p>
    <b style={{fontSize:'19px'}}>
  {criticality[0]?.sec}
    </b>

    <p  style={{textAlign:'justify'}}>
    {criticality[0]?.para}

    </p>
    <b style={{fontSize:'19px'}}>
   {futurality[0]?.sec}  
    </b>

    <p  style={{textAlign:'justify'}}>
   {futurality[0]?.para}
    </p>
</div>
</div>
  
 <footer style={{width:'100%',position:'relative',top:"20px",background:'black'}}>
    {/* <p>do,l</p> */}
    {/* uhjnnnnnnnnnnnnn */}
    <img src={pagenumber3} alt="Description of Image" style={{width:'100%'}} className="footerpro" />

 </footer>

 {/* pagenumber3 */}
{/* pagenumber3 */}
    </div>


    <div className=" fgfg" style={{alignItems:'normal',flexDirection:'column',    width: "103%",left:"-10px",
    position: "relative"}}>
      <img src={top} style={{zIndex:'0',width:"100%",height:'100%',zIndex:0}} alt="Description of Image" className="full-image"  />
<div style={{padding:'40px'}}>
 {/* <br></br> */}


<div  style={{marginTop:'-20px',padding:'12px',color:'black'}}>
<p style={{wordSpacing:'4px',textAlign:'justify'}}>
The scores determine how your wheel of life is at this moment. You can refer to the appendix to look at
where you could possibly reach and where you want to reach. However, for that growth and
development you will need to have a structure. You could use the structure at Human Design Education
for that growth or try your methods and test them for yourself time to time by taking PAT every 3 - 6
months.
    </p>
</div>
</div>
  {/* <div style={{height:'671px'}}> */}
  {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}> */}
<div style={{display:'flex',justifyContent:'center',marginTop:'-39px',alignItems:'center',flexDirection:'column'}}>
<div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
<RadialChart data={data} wrtieup={categories1} categories={categories} numCircles={10} radius={270} />
</div>



<p style={{color:"black",textAlign:'center',fontWeight:'500',fontSize:'17px'}}>Wheel of Life </p>
{displaywheel?<p style={{color:"black",textAlign:'center'}}>Wheel Alignment* = {average}%</p>
:""}<p style={{color:"black",textAlign:'center',padding:'11px'}}>*Applicable only if values are above 0.6. For scores below 0.6, Wheel Alignment Score is not calculated.</p>
</div>
    {/* </div> */}


   {/* </div> */}
<footer style={{width:'100%',position:'relative',top:"20px",background:'black'}}>
    {/* <p>do,l</p> */}
    {/* uhjnnnnnnnnnnnnn */}
    <img src={pagenumber4} alt="Description of Image" style={{width:'100%'}} className="footerpro" />

 </footer>
    </div>   
    
    {/* <div className="pdf-container">
      These elements are for layout only; they won't be visible since we're downloading the PDF automatically
      <img src={img5} alt="Description of Image" className="full-image" />
     
    </div> */}

    {/* <div className=" pdf-container fgfg" style={{alignItems:'normal',flexDirection:'column',    width: "103%",left:"-10px",
    position: "relative"}}>
      <img src={top} style={{zIndex:'0',width:"100%",height:'100%',zIndex:0}} alt="Description of Image" className="full-image"  />
 <h1 style={{textAlign:'center',color:'black'}}>Explanation of Wheel Of Life</h1>
<div className='row' style={{textAlign:'center',color:'black'}}>
  <div className='col-12'>
    <span>Balanced & Engaged</span>
  </div>
  <div className="col">
  You have a balance in being an extrovert or an introvert depending on how you perceive the group to be. However, in either way you are a good listener and can keep yourself and others involved in conversations. You stay in and maintain your relationships with genuine effort and authenticity. You would take people alongwith you and not leave them behind in the pursuit of your goals. You choose selflessness over selfishness in your growth.
  </div>
</div>
 

    </div>  */}

<div className=" pdf-container fgfg" style={{alignItems:'normal',flexDirection:'column',    width: "103%",left:"-10px",
    position: "relative"}}>
      <img src={top} style={{zIndex:'0',width:"100%",height:'100%',zIndex:0}} alt="Description of Image" className="full-image"  />
 <div className=''>
 <h1 style={{wordSpacing:'4px',textAlign:'center', color:'black',marginTop:'33px'   }}>
 Explanation of Wheel Of Life
     </h1>
  <div >
 
  <div  style={{marginTop:'-68px',padding:'62px',color:'white'}}>
 
<br></br>
<div className='row' style={{border:'2px solid black',borderBottom:'none',color:'#3E79D6',textAlign:'center'}}>
 <div className="col-12 wheeloflife" style={{background:'#C5D5F1',borderBottom:'2px solid black' }}>
 <span style={{fontWeight:'bold'}} >{rsdata.title}  </span>
 </div>
 <div className='prompt' style={{textAlign:'justify'}}>
 {rsdata.statement}
  </div>

</div>
<div className='row' style={{border:'2px solid black',borderBottom:'none',borderTop:'2px solid black',color:'#F7BD33',textAlign:'center'}}>
 <div className="col-12 wheeloflife" style={{background:'#FDF2D6',borderBottom:'2px solid black' }}>
 <span style={{fontWeight:'bold'}}>{heData.title}</span> 
 </div>
 <div  className='prompt' style={{textAlign:'justify'}}>
{heData.statement} </div>

</div>
<div className='row' style={{border:'2px solid black',color:'#5DB53C',borderBottom:'none',borderTop:'2px solid black',textAlign:'center'}}>
 <div className="col-12 wheeloflife" style={{background:'#CEE8C4',borderBottom:'2px solid black' }}>
 <span style={{fontWeight:'bold'}}>{weData.title}</span> 
 </div>
 <div  className='prompt' style={{textAlign:'justify'}}>
{weData.statement} </div>

</div>
<div className='row' style={{border:'2px solid black',borderBottom:'none',borderTop:'2px solid black',color:'#E22246',textAlign:'center'}}>
 <div className="col-12 wheeloflife" style={{background:'#F7C5C7',borderBottom:'2px solid black' }}>
 <span style={{fontWeight:'bold'}}>{woeData.title} </span>
 </div>
 <div  className='prompt' style={{textAlign:'justify'}}>
{woeData.statement} </div>

</div>
<div className='row' style={{border:'2px solid black',color:'#3D3D3C',textAlign:'center'}}>
 <div className="col-12 wheeloflife" style={{background:'#C4C4C4',borderBottom:'2px solid black' }}>
 <span style={{fontWeight:'bold'}}>{saData.title}</span> 
 </div>
 <div  className='prompt' style={{textAlign:'justify'}}>
{saData.statement} </div>

</div>
 
</div>

  </div>
 </div>

 

    </div> 


    {/* <div className=" pdf-container fgfg" style={{alignItems:'normal',flexDirection:'column',    width: "103%",left:"-10px",
    position: "relative"}}>
      <img src={top} style={{zIndex:'0',width:"100%",height:'100%',zIndex:0}} alt="Description of Image" className="full-image"  />
 <div className=''>
 <h1 style={{wordSpacing:'4px',textAlign:'center', color:'black'   }}>
Assessment Matrix
    </h1>
  <div className='black  ' style={{textAlign:'justify',padding:'40px',marginTop:'-36px',zIndex:'999999'}}>
  <jam>
  The result of the Assessment Matrix is best understood when you look at the report
which is drawn from this matrix to indicate the aptitude, behaviour, possible
professions and careers and the potential language of expertise that exist.

  </jam>
  </div>
 </div>

 { dataPoints[0]?.y>0.30?<ScatterPlot />:
      <Plot1/>}

    </div>  */}
 
 <div className=" pdf-container fgfg" style={{alignItems:'normal',flexDirection:'column',    width: "103%",left:"-10px",
    position: "relative"}}>
      <img src={top} style={{zIndex:'0',width:"100%",height:'100%',zIndex:0}} alt="Description of Image" className="full-image"  />
 <div className=''>
 <h1 style={{wordSpacing:'4px',textAlign:'center', color:'black',marginTop:"23px"   }}>
Assessment Matrix
    </h1>
  <div className='black  ' style={{textAlign:'justify',padding:'40px',marginTop:'-19px',zIndex:'999999',width:"721px",position:'relative',left:"49px"}}>
  <jam>
  The result of the Assessment Matrix is best understood when you look at the report
which is drawn from this matrix to indicate the aptitude, behaviour, possible
professions and careers and the potential language of expertise that exist.

  </jam>
  </div>
 </div>

 { dataPoints[0]?.y>0.30?<ScatterPlot />:
      <Plot1/>}

    </div> 
    <div className=" pdf-container fgfg" style={{alignItems:'normal',flexDirection:'column',    width: "103%",left:"-10px",
    position: "relative"}}>
      <img src={top} style={{zIndex:'0',width:"100%",height:'100%',zIndex:0}} alt="Description of Image" className="full-image"  />
 <div className=''>
 <h1 style={{wordSpacing:'4px',textAlign:'center', color:'black',position:'relative',top:'30px'   }}>
 Report
     </h1>
  <div style={{padding:'91px'}}>
 
  <div  style={{marginLeft:' -13px',marginTop:'-20px',color:'#3E73D2'}}>
<div className='row' style={{border:'3px solid 3E73D2',textAlign:'center'}}>
  <div className='col-3 colspacing bolding'  style={{border:'3px solid #3E73D2',background:'#C5D5F1',borderRight:'none'}}>
Name
  </div>
  <div className='col colspacing texttranscap' style={{border:'3px solid #3E73D2'}}>
{userdata.first_name}&nbsp;{userdata.last_name}
</div>
</div>
<br></br>
<div className='row' style={{border:'3px solid #E22246',color:'#E22246',textAlign:'center'}}>
 <div className="col-12 wheeloflife" style={{background:'#F7C5C7',borderBottom:'3px solid #E22246' }}>
 <span style={{fontWeight:'bold'}}>Wheel Of Life </span>(Refer to Appendix 1)
 </div>
<div className='col-12'>
<div className="row" style={{    lineHeight: "19px",borderBottom:'1px solid '}}>
<div className="col-2 bold">
 Relationships
 </div>
 <div className="col-2 bold">
 Health
 </div>
 <div className="col-2 bold">
 Wealth
 </div>
 <div className="col-2 bold">
 Work & Education
 </div>
 <div className="col-2 bold">
 Self Awareness
 </div>
 <div className="col-2 bold" style={{border:'none'}}>
 Average
 </div>
</div>
<div className="row" style={{    lineHeight: "19px",}}>
<div className="col-2 bold">
<span className="fontweightnormal">{data1[0]}</span>
</div>
 <div className="col-2 bold">
 <span className="fontweightnormal">{data1[1]}</span>

 </div>
 <div className="col-2 bold">
 <span className="fontweightnormal">{data1[2]}</span>
 </div>
 <div className="col-2 bold">
 <span className="fontweightnormal">{data1[3]}</span>
 </div>
 <div className="col-2 bold">
 <span className="fontweightnormal">{data1[4]}</span>
 </div>
 <div className="col-2 bold" style={{border:'none',background:"#F7C5C7"}}>
 <span className="" style={{fontWeight:'bold'}}>{data1[5]}</span>
 
 </div>
</div>
</div>


</div>


<br></br>
<div className='row' style={{border:'3px solid #FFBD16',color:'#FFBD16',textAlign:'center'}}>
 <div className="col-12 wheeloflife" style={{background:'#FDF2D6',borderBottom:'3px solid #FFBD16',textAlign:'center' }}>
 <span style={{fontWeight:'bold'}}>Assessment Matrix (Interpretation)  </span>(Refer to Appendix 2.1)
 </div>
 <div className="col-2 bold">
 Aptitude
 </div>
 <div className="col-2  bold">
 Behaviour
 </div>
 <div className="col bold">
 Possible Professions & Careers
 </div>
 <div className="col-3  bold"   style={{borderRight:'none'}}>
 Language of Being
  </div>


<div className="col-12" style={{borderTop:'1px solid '}}>
<div className="row">
<div className="col-2 boldheight">
<span className='fontweightnormal'>{findBlockName(dataPoints[0]?.x,dataPoints[0]?.y)}</span>
 </div>
 <div className="col-2 boldheight">
 <span className='fontweightnormal'>{dataneed[0]?.Behaviour}</span>
 </div>
 <div className="col  boldheight">
 <span className='fontweightnormal'>{dataneed[0]?.Careers}</span>
 </div>
 <div className="col-3 boldheight"  style={{borderRight:'none'}}>
 <span className='fontweightnormal' >{dataneed[0]?.Language_of_Being}</span>
 </div>

</div>
</div>
</div>
<br></br>
{/* <div className="container my-4"> */}

<div
  className="row"
  style={{
    border: "3px solid #45B43B",
    color: "#45B43B",
    textAlign: "center",
  }}
>
  {/* Header Section */}
  <div
    className="col-12"
    style={{
      background: "#CEE8C4",
      borderBottom: "3px solid #45B43B",
      fontWeight: "bold",
      padding: "10px",
    }}
  >
    <span>Pathway for Development</span> (Refer to Appendix 3)
  </div>

  {/* Subheader Section */}
  <div
    className="col-2"
    style={{
      fontWeight: "bold",
      borderRight: "2px solid #45B43B",
      borderBottom: "1px solid #45B43B",
      padding: "15px",
    }}
  >
    Average Score
  </div>
  <div
    className="col-3"
    style={{
      fontWeight: "bold",
      borderRight: "2px solid #45B43B",
      borderBottom: "1px solid #45B43B",
      // padding:'9px'
    }}
  >
<span  >
Pathway for Development in Human Design Education

</span>
  </div>
  <div
    className="col"
    style={{
      fontWeight: "bold",
      borderBottom: "1px solid #45B43B",
      padding: "10px",
    }}
  >
    <div className="row" style={{ height: "48px", padding: "2px" }}>
      <div
        className="col-12"
        style={{
          paddingBottom: "5px",
          borderBottom: "1px solid #45B43B",
          fontWeight: "bold",
          height: "43px",
        }}
      >
        Pathway for development other than Human Design Education
      </div>
      <div
        className="col"
        style={{
          fontWeight: "bold",
          borderRight: "2px solid #45B43B",
          padding: "5px",
        }}
      >
        Books
      </div>
      <div
        className="col"
        style={{
          fontWeight: "bold",
          borderRight: "2px solid #45B43B",
          padding: "5px",
        }}
      >
        Movies (English)
      </div>
      <div
        className="col"
        style={{
          padding: "5px",
        }}
      >
        Movies (Hindi)
      </div>
    </div>
  </div>

  {/* Data Section */}
  <div
    className="col-12"
    style={{
      borderTop: "1px solid #45B43B",
    }}
  >
    <div className="row" style={{ lineHeight: "14px", fontSize: "12px" }}>
      <div
        className="col-2"
        style={{
          borderRight: "2px solid #45B43B",
          borderBottom: "1px solid #45B43B",
          padding: "10px",
          display: "flex",
          color: "black",
          alignItems: "center",
          justifyContent: "center", 
          overflow: "hidden", // Ensures content stays inside
          textOverflow: "ellipsis", // Adds ellipsis if text overflows
          // whiteSpace: "nowrap", // Keeps text on one line
        }}
      >
        <span style={{ fontSize: "12px", fontWeight: "normal" }}>
          {data1[5]}
        </span>
      </div>
      <div
        className="col-3"
        style={{
          borderRight: "2px solid #45B43B",
          borderBottom: "1px solid #45B43B",
          padding: "10px",
          display: "flex",
          color: "black",
          alignItems: "center",
          overflow: "hidden",
          textOverflow: "ellipsis",
          // whiteSpace: "nowrap",
        }}
      >
        <span
          style={{
            fontSize: "12px",
            fontWeight: "normal",
            color: "black",
          }}
        >
          {carrier?.Pathway}
        </span>
      </div>
      <div
        className="col"
        style={{
          borderBottom: "1px solid #45B43B",
        }}
      >
        <div className="row">
          <div
            className="col"
            style={{
              borderRight: "2px solid #45B43B",
              padding: "10px",
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              // whiteSpace: "nowrap",
            }}
          >
            <span
              style={{
                fontSize: "12px",
                color: "black",
                fontWeight: "normal",
              }}
            >
              {carrier?.Books}
            </span>
          </div>
          <div
            className="col"
            style={{
              borderRight: "2px solid #45B43B",
              padding: "10px",
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              // whiteSpace: "nowrap",
            }}
          >
            <span
              style={{
                fontSize: "12px",
                color: "black",
                fontWeight: "normal",
              }}
            >
              {carrier?.MoviesE}
            </span>
          </div>
          {/* <div
            className="col"
            style={{
              padding: "10px",
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              // whiteSpace: "nowrap",
            }}
          >
            <span
              style={{
                fontSize: "12px",
                color: "black",
                fontWeight: "normal",
              }}
            >
              {carrier?.MoviesH}
            </span>
          </div> */}
        <div
  className="col"
  style={{
    padding: "10px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  }}
>
  <span
    style={{
      fontSize: "12px",
      color: "black",
      fontWeight: "normal",
      wordBreak: "break-word", // Ensures words break correctly
    }}
  >
    {splitIntoTwoLines(carrier?.MoviesH || "")}
  </span>
</div>
        </div>
      </div>
    </div>
  </div>
</div>

{/* 
      <div className="row border text-center">
        <div className="col-2 border fw-bold align-middle d-flex justify-content-center align-items-center">
          Average Score
        </div>
        <div className="col-10 border fw-bold align-middle">
          Pathway for Development (Refer to Appendix 3)
          <div className="row">
            <div className="col-4 border fw-bold">
              Pathway for Development in Human Design Education
            </div>
            <div className="col-8 border fw-bold">
              Pathway for Development Other than Human Design Education
              <div className="row">
                <div className="col-4 border fw-bold">Books</div>
                <div className="col-4 border fw-bold">Movies (English)</div>
                <div className="col-4 border fw-bold">Movies (Hindi)</div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* Data Row */}
      {/* <div className="row border text-center">
        <div className="col-2 border d-flex justify-content-center align-items-center">
          0.69
        </div>
        <div className="col-10 border">
          <div className="row">
            <div className="col-4 border d-flex justify-content-center align-items-center">
              Program for Life (Classroom)
            </div>
            <div className="col-8 border">
              <div className="row">
                <div className="col-4 border">
                  The Power of Now by Eckhart Tolle, The Power of Positive
                  Thinking by Norman Vincent Peale
                </div>
                <div className="col-4 border">
                  Invictus, Good Will Hunting, Bruce Almighty, The Shawshank
                  Redemption
                </div>
                <div className="col-4 border">
                  Rocket Singh, Barfi, Panga, Border
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    {/* </div> */}
</div>

  </div>
 </div>

 

    </div> 


    <div className="pdf-container">
      {/* These elements are for layout only; they won't be visible since we're downloading the PDF automatically */}
      <img src={img99} alt="Description of Image" className="full-image" />
     
    </div>
    <div className="pdf-container">
      {/* These elements are for layout only; they won't be visible since we're downloading the PDF automatically */}
      <img src={img999} alt="Description of Image" className="full-image" />
     
    </div>

    <div className="pdf-container">
      {/* These elements are for layout only; they won't be visible since we're downloading the PDF automatically */}
      <img src={img10} alt="Description of Image" className="full-image" />
     
    </div>

    <div className="pdf-container">
      {/* These elements are for layout only; they won't be visible since we're downloading the PDF automatically */}
      <img src={img11} alt="Description of Image" className="full-image" />
     
    </div>

    <div className="pdf-container">
      {/* These elements are for layout only; they won't be visible since we're downloading the PDF automatically */}
      <img src={img12} alt="Description of Image" className="full-image" />
     
    </div>

    <div className="pdf-container">
      {/* These elements are for layout only; they won't be visible since we're downloading the PDF automatically */}
      <img src={img13} alt="Description of Image" className="full-image" />
     
    </div>

    <div className="pdf-container">
      {/* These elements are for layout only; they won't be visible since we're downloading the PDF automatically */}
      <img src={img14} alt="Description of Image" className="full-image" />
     
    </div>
    <div className="pdf-container">
      {/* These elements are for layout only; they won't be visible since we're downloading the PDF automatically */}
      <img src={img15} alt="Description of Image" className="full-image" />
     
    </div>
    <div className="pdf-container">
      {/* These elements are for layout only; they won't be visible since we're downloading the PDF automatically */}
      <img src={img16} alt="Description of Image" className="full-image" />
     
    </div>
    
  </div>
  {isLoading && <div style={{background:"black"}}>Loading your document...</div>}

  </>
  );


}

export default Pdf;
