import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { baseurlvmkp } from './Baseurl';
import treemap from './../../Pagesmaker/treemap.png'
import transparent from './../../Pagesmaker/transparet.png'


const Plot1 = () => {
  const [dataPoints, setDataPoints] = useState([]);
  const canvasRef = useRef(null);
const [fakeData, setfakeData] = useState([])
let { id } = useParams();

  useEffect(() => {
    // Fake data with (x, y) values where x is for "why" and y is for "Overall"
    // const fakeData = [
    //   { x: 0.03, y:0.29/3 },
    // ];
setDataPoints(fakeData)
    axios.get(`${baseurlvmkp}/index/get/${id}` ).then((response) => {
        console.log(response.data[0].x_AXIS)
        const x1=response.data[0].x_AXIS = response.data[0].x_AXIS >= 1.0 ? 0.80 : response.data[0].x_AXIS;
        const y1=response.data[0].y_AXIS
        // setfakeData()
        setDataPoints([{x:x1,y:y1/3}]);

 

     }).catch((error)=>{
        console.log(error)
                 })
    // Set the fake data to state
  }, []);  

  // Function to draw the scatter plot on the canvas
  const drawScatterPlot = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;

    // Now, draw the scatter plot without the background image
    drawAxesAndPlot(ctx, width, height);
  };

//   const drawAxesAndPlot = (ctx, width, height) => {
//     // Set up axis ranges for the x-axis ("why") and y-axis ("Overall")
//     const xRanges = [
//       { label: '0 - 0.1', min: 0, max: 0.1 },
//       { label: '0.11 - 0.2', min: 0.11, max: 0.2 },
//       { label: '0.21 - 0.3', min: 0.21, max: 0.3 },
//       { label: '0.31 - 0.4', min: 0.31, max: 0.4 },
//       { label: '0.41 - 0.5', min: 0.41, max: 0.5 },
//       { label: '0.51 - 0.6', min: 0.51, max: 0.6 },
//       { label: '0.61 - 0.7', min: 0.61, max: 0.7 },
//       { label: '0.71 - 0.8', min: 0.71, max: 0.8 },
//       { label: '0.81 - 0.9', min: 0.81, max: 0.9 },
//      ];

//     const yRanges = [
//       { label: '0.0 - 0.3', min: 0, max: 0.1 },
//       { label: '0.31 - 0.4', min: 0.11, max: 0.2 },
     
      
//     ];

//     // Map the "why" values (x-axis) and "overall" values (y-axis) to canvas space
//     const scaleX = (x) => {
//       return Math.floor((x / 0.9) * (width - 100)) + 50; // Adding padding
//     };

//     const scaleY = (y) => {
//       return height - Math.floor((y / 0.9) * (height - 100)) - 50; // Adding padding
//     };

//     // Draw axes
//     ctx.beginPath();
//     ctx.moveTo(50, 20); // Y-axis start point
//     ctx.lineTo(50, height - 20); // Y-axis end point
//     ctx.lineTo(width - 20, height - 20); // X-axis end point
//     ctx.strokeStyle = 'black';
//     ctx.stroke();

//     // Draw axis labels
//     ctx.font = '14px Arial';
//     ctx.fillText('Why', width - 40, height - 10); // X-Axis label
//     ctx.save();
//     ctx.translate(20, height / 2); 
//     ctx.rotate(-Math.PI / 2);
//     ctx.fillText('Overall', 0, 0); // Y-Axis label
//     ctx.restore();

//     // Draw X-axis ticks and labels
//     xRanges.forEach((range, index) => {
//       const xPos = scaleX(range.min); // Get the X position of the tick
//       ctx.beginPath();
//       ctx.moveTo(xPos, height - 20); // X-axis tick line
//       ctx.lineTo(xPos, height - 30); // Tick length
//       ctx.stroke();

//       ctx.fillText(range.label, xPos - 15, height - 5); // Label for tick
//     });

//     // Draw Y-axis ticks and labels
//     yRanges.forEach((range, index) => {
//       const yPos = scaleY(range.min); // Get the Y position of the tick
//       ctx.beginPath();
//       ctx.moveTo(50, yPos); // Y-axis tick line
//       ctx.lineTo(40, yPos); // Tick length
//       ctx.stroke();

//       ctx.fillText(range.label, 15, yPos + 5); // Label for tick
//     });

//     // Plot the data points
//     const image = new Image();
//     image.src = 'https://i.ibb.co/p1r9TCz/removeback.png'; // Replace with your image source

//     image.onload = () => {
//       dataPoints.forEach(point => {
//         const x = scaleX(point.x);
//         const y = scaleY(point.y);

//         // Set the transparency (alpha value)
//         const transparency = 0.38; // Adjust the value between 0 (fully transparent) and 1 (fully opaque)
//         ctx.globalAlpha = transparency;

//         // Draw the image at the point (adjust size if needed)
//         const imageSize = 150; // You can change this to adjust the size of the image
//         ctx.drawImage(image, x - imageSize / 2, y - imageSize / 2, imageSize, imageSize);

//         // Reset the globalAlpha back to 1 (fully opaque) for other drawing operations
//         ctx.globalAlpha = 1;
//       });
//     };
//   };




const drawAxesAndPlot = (ctx, width, height) => {
    const xRanges = [
      { label: '0 - 0.1', min: 0, max: 0.1 },
      { label: '0.11 - 0.2', min: 0.11, max: 0.2 },
      { label: '0.21 - 0.3', min: 0.21, max: 0.3 },
      { label: '0.31 - 0.4', min: 0.31, max: 0.4 },
      { label: '0.41 - 0.5', min: 0.41, max: 0.5 },
      { label: '0.51 - 0.6', min: 0.51, max: 0.6 },
      { label: '0.61 - 0.7', min: 0.61, max: 0.7 },
      { label: '0.71 - 0.8', min: 0.71, max: 0.8 },
      { label: '0.81 - 0.9', min: 0.81, max: 0.9 },
    ];
  
    const yRanges = [
      { label: '0.0 - 0.3', min: 0, max: 0.1 },
      { label: '0.31 - 0.4', min: 0.11, max: 0.2 },
    ];
  
    // Map the "why" values (x-axis) and "overall" values (y-axis) to canvas space
    const scaleX = (x) => Math.floor((x / 0.9) * (width - 100)) + 50;
    const scaleY = (y) => height - Math.floor((y / 0.9) * (height - 100)) - 50;
  
    // Plot the data points
    const image = new Image();
    image.src =transparent; 
  
    image.onload = () => {
      dataPoints.forEach((point) => {
        const x = scaleX(point.x);
        const y = scaleY(point.y);
  
        // Set the transparency (alpha value)
        const transparency = 0.99;
        ctx.globalAlpha = transparency;
  
        // Draw the image at the point (adjust size if needed)
        const imageSize = 200;
        ctx.drawImage(image, x - imageSize / 2, y - imageSize / 2, imageSize, imageSize);
  
        // Reset the globalAlpha back to 1 for other drawing operations
        ctx.globalAlpha = 1;
      });
    };
  };
  
  useEffect(() => {
    if (dataPoints.length > 0) {
      drawScatterPlot();
    }
  }, [dataPoints]);

  return (
    <div className='makerclass' style={{top:'-53px!important',left:"-13px"}}>
         {/* <h1>plot</h1> */}
        <canvas
        ref={canvasRef}
        width={825}  
        height={712} 
        // className={dataPoints[0].y>0.30?'canvas4':'canvas3'}

        style={{ position: 'relative', zIndex: '999999' }}
      ></canvas>
     
     <img
        src={treemap}
        style={{ width: '666px', height: '559px', position:'relative',top:'-614px',left:'44px'   }}
        alt="Background Image"
      />
            {/* <h1>plot</h1> */}

    </div>
  );
};

export default Plot1;
