import React from "react";
import { Document } from "@react-pdf/renderer";
import PageOne from "./Pageone";
 

const PdfDocument = () => (
  <Document>
    <PageOne />
   </Document>
);

export default PdfDocument;
