import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { baseurlvmkp } from './Files/Subjects/Baseurl';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [passcode, setPasscode] = useState('');
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  // Handle the login logic
  const handleChangelogin = async () => {
    try {
      const response = await axios.post(`${baseurlvmkp}/vmkp/post/customer/signin`, {
        email,
        password: passcode
      });

      const { token, details } = response.data;

      localStorage.setItem('token', token);
      localStorage.setItem("user", JSON.stringify(response.data));
      axios.delete(`${baseurlvmkp}/exam/delete`, {
        headers: { Authorization: 'Bearer ' + token }
    }).then((deleteResponse) => {
         console.log('Exam deleted successfully', deleteResponse);

       
    }).catch((deleteError) => {
        console.error('Error deleting exam:', deleteError);
    });

    
      console.log('Login successful:', response.data);
      navigate('/payment'); // Redirect on successful login
    } catch (error) {
      console.error('There was an error logging in:', error);
      Swal.fire({
        title: 'Login Failed',
        text: 'Invalid email or password.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  // Close the login modal
  const handleClose = () => {
    setIsOpen(false);
    navigate('/');
  };

  // Handle pressing the Enter key to trigger login
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleChangelogin();
    }
  };

  if (!isOpen) return null;

  return (
    <Box
      display="flex"
      className="gtg"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor="#f7f9fc"
      padding={2}
    >
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginBottom: 4 }}>
        <img
          style={{ padding: '12px', height: '67px' }}
          src='https://static.wixstatic.com/media/5715af_a286bfb446374a96877a4193a1ef0933~mv2.png/v1/fill/w_114,h_60,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/PAT%20Logo.png'
          alt="PAT Logo"
        />
        <img
          style={{ padding: '12px', height: '52px' }}
          src='https://static.wixstatic.com/media/77a25b_ec2fa92086954799bf72699afde7a666~mv2.png/v1/fill/w_191,h_34,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/VMKGBLUE.png'
          alt="VMKGBLUE Logo"
        />
      </Box>

      <Paper elevation={3} sx={styles.card}>
        <Button onClick={handleClose} sx={styles.closeButton}>
          &times;
        </Button>
        <Typography variant="h4" component="h2" align="center" gutterBottom>
          Login
        </Typography>
        <Box component="form" noValidate autoComplete="off">
          <TextField
            variant="outlined"
            label="Email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyPress} // Handle Enter key press
            required
            fullWidth
            sx={styles.textField}
          />
          <TextField
            variant="outlined"
            label="Password"
            type={visible === false ? "password" : "text"}
            onChange={(e) => setPasscode(e.target.value)}
            onKeyDown={handleKeyPress} // Handle Enter key press
            required
            fullWidth
            sx={styles.textField}
          />
          <button
            id="eyebtn"
            onClick={(e) => {
              e.preventDefault();
              return setVisible(!visible);
            }}
          >
            {visible ? <FaEye /> : <FaEyeSlash />}
          </button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleChangelogin}
            fullWidth
            sx={styles.button}
          >
            Login
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

const styles = {
  card: {
    padding: '20px',
    borderRadius: '8px',
    width: '400px',
    position: 'relative',
  },
  textField: {
    marginBottom: '15px',
  },
  button: {
    marginTop: '20px',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'transparent',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
  },
};

export default LoginPage;
