import React, { useEffect } from 'react';
import html2pdf from 'html2pdf.js';
import img11 from './pag1leftside.png';
import img2 from './firstpagechanges2.png';

const NeoPrintLayout = () => {
  const handleDownloadPDF = () => {
    const element = document.getElementById('pdf-content');
    const options = {
      margin: 1,
      filename: 'neo-print-layout.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    html2pdf().set(options).from(element).save();
  };
useEffect(() => {
  handleDownloadPDF()
}, [ ])

  return (
    <div>
      {/* <button className='none' onClick={}>Download as PDF</button> */}
      <div className="neo-pdf-wrapper" id="pdf-content">
        <div className="inner-content">
          <div className="left-image">
            <img src={img11} style={{ width: '100%', height: '100%' }} alt="Left Image" />
          </div>
          <div className="left-side">
            <div className="firstpagechanges2">
              <div className="firtcontent">
                <h1 className="headingfont right">Bhavisha Sheth</h1>
                <div className="right fontname">26 | Female | Businessperson</div>
              </div>
              <div className="down">
                <img src={img2} style={{ width: '100%', height: '100%' }} alt="Bottom Image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NeoPrintLayout;
