 export const rs =[
    
    {min:0,max:0.40,title:'Introvert & Reticent',statement:"You prefer limited social interactions and would like to withdraw from conversations which have conflicts. You are a person of few words and think deeply. You have quite a bit of thoughts that you would like to share but experience a limitation in articulating your thoughts. You would experience freedom if you could practice articulating your thoughts in writing. Sharing your thoughts through writing may help build relationships."},
    {min:0.41,max:0.60,title:'Extrovert & Cautious',statement:"You prefer social interactions, conversing with people and creating a space to express yourself. However, you prefer to be careful in your conversations. You would like to be polite and careful rather than straight-forward and hurtful. You enjoy connecting and sharing with people as that is how you learn about people. You have the innate ability to lead people if you used caution to discover rather than suppress yourself."},
    {min:0.61,max:0.80,title:'Balanced & Engaged',statement:"You have a balance in being an extrovert or an introvert depending on how you perceive the group to be. However, in either way you are a good listener and can keep yourself and others involved in conversations. You stay in and maintain your relationships with genuine effort and authenticity. You would take people alongwith you and not leave them behind in the pursuit of your goals. You choose selflessness over selfishness in your growth."},
 {min:0.81,max:1,title:'Unrestrained & Reckless',statement:"You have a tendency to go over the top on the guise of being straight-forward and being authentic of what you feel. You will often come across as someone who doesn’t care for others and is self-centred while the opposite may be true about yourself. You will need to learn to articulate your thoughts with some restraint and you could be considered as intelligent and be treated with the respect you deserve."},
]


export const he =[
    
    {min:0,max:0.40,title:'Careful & Risk Averse',statement:"You consider a regular and simple way of life more fulfilling and useful rather than indulge or engage in adventure that may cause risk to your health. Having a routine and discipline is more important than being careless and risky. You prefer being called defensive over being called foolish."},
    {min:0.41,max:0.60,title:'Effective & Productive',statement:"You may not be a health freak, but have a genuine concern for your health. You prefer to be cautious and careful when it comes to health rather than sorry and regretful. You take your exercises and physical care with concern, but are not indulgent. You enjoy your discipline and routine, but would like to be adventurous at times if it is worth the effort."},
    {min:0.61,max:0.80,title:'Responsible & Fulfilled',statement:"Your approach to health is more like a leader who would be considered responsible. You are not sucker for routine or discipline, but at the same time not reckless. You maintain some routine but also enjoy adventure. You connect well with people and take care of others in a way that matters to you. Consider that for some reason, people feel safe about themselves around you."},
 {min:0.81,max:1,title:'Adventurous & Unorthodox',statement:"Left to yourself, you would prefer the wild rather than be domesticated by civilisation. You enjoy experimenting and exploration, it gives you a thrill. It is possible that you have a strong constitution and hence risk it often, as it doesn’t impact you like others. You enjoy sports and injuries are not a deterrent to you."},
]

export const we =[
    
    {min:0,max:0.40,title:'Safety & Secure',statement:"A bird in hand is worth two in a bush’ is probably your mantra to managing your money. You are cautious with your money and don’t like to spend unnecessarily. You prefer to think twice before spending rather than spend impulsively. You are someone who considers money in the hand more worthy than money in the mind. You prefer being considered sensible with money instead of being considered a spendthrift."},
    {min:0.41,max:0.60,title:'Industrious & Protective',statement:"Money is to generate wealth according to you. Savings and investments are primary and expenses are secondary. You prefer the long haul in making money rather than try to make money quickly. You choose not to spend money unless it is useful or productive. You prefer to maintain integrity with respect to both taking and giving money."},
    {min:0.61,max:0.80,title:'Generous & Wise',statement:"You have learnt over experience of self and others to be prudent with your money. Rarely do you struggle with situations with and for others and are not a miser. You invest well and are not reckless. You are committed to building wealth rather than being rich. You consider charity an investment and a part of building wealth and not an expense. You manage money well and do not allow it to spoil relationships."},
 {min:0.81,max:1,title:'Risky & Abundant',statement:"Money in the mind matters to you more than money in hand. You believe in the mantra ‘no risks, no gains’. You think and live like a rich man, irrespective of how much money you have. At times you may end up being delusional, if you are not a little cautious and don’t keep checking yourself. You need to be careful with your money and your assets."},
]

export const woe =[
    
    {min:0,max:0.40,title:'Hardworking & Perseverant',statement:"You may not consider yourself intelligent or innovative. However, just being hard-working and perseverant has given you access to reach your goals. You do not set many goals, but attempt to fulfil whichever goal you set. You are committed and determined in fulfilling your goals. You may consider yourself unlucky at times, but be rest assured your perseverance will serve you well."},
    {min:0.41,max:0.60,title:'Smart & Practical',statement:"You would prefer to be involved in more than one thing at a time. You are not confined to doing one thing. However, you take considerable effort to fulfil whatever you do rather than leave them incomplete. You take calculated risks with respect to your career and education however, you choose to be responsible for your actions which makes you a leader in your attitude and conduct."},
    {min:0.61,max:0.80,title:'Creative & Realistic',statement:"You enjoy yourself and learning from others though your means of doing may be different and unique as compared to most people. You prefer to make a regular advancement of knowledge with few but sure steps rather than educate and learn in a hurry in spurts and then have a void later. You enjoy working hard to create something rather than follow some routine that does not stimulate your brain. Bringing your thought to reality is like a work ethic for you."},
 {min:0.81,max:1,title:'Innovative & Idealistic',statement:"Doing something unique and different is what inspires you. You want to bring change and don’t mind taking up challenges. Success is not a difficult for you. It comes pretty easily to you, but change is more important to you. You will go to great lengths to educate yourself and choose careers that could bring change. You may often be considered idealistic and unreal. Between the possibility of a visionary and the gullibility of a fool, lies your journey. However, creating a vision and fulfilling it would make your life worth it."},
]

export const sa =[
    
    {min:0,max:0.40,title:'Cautious & Curious',statement:"One step at a time is a mantra for you. You prefer doing one thing at a time and prefer to know as much as required, even if it is about yourself. Being good is more important than being real. Being protective about yourself is a natural way of living for you. Family matters a lot and you are protective about them. Success doesn’t matter much to you, but you don’t like losing. You fear failure."},
    {min:0.41,max:0.60,title:'Interested & Exploring',statement:"Life is an interesting journey. You may be considered restless, but you are energetic and positive with life. It wouldn’t be wrong if the mantra ‘High on life’ is applied to you. To explore and experiment on anything, it needs to first interest you. Anything that doesn’t interest you, you will prefer to avoid it rather than do it for the sake of doing. Circumstances may impact you but can’t change you. You are someone who prefers to change circumstances."},
    {min:0.61,max:0.80,title:'Committed & Discovering',statement:"You have a deep yearning to know more about yourself. Your skill of listening and observation are your best tools in your development of yourself. You have this child like yearning to be a student of life itself. Your innocence and your yearning desire, together when coupled have the potential of you being blessed with wisdom."},
 {min:0.81,max:1,title:'Sharing & Experimenting',statement:"Your intelligence which is sound, often comes in the way of your awareness. Your way of life where exploring and discovering thrills you, may make you feel as if you are expanding your awareness and consciousness, but you also need to have a clear pathway into your future, or else, you may occur as delusional and irresponsible. If you could build a discipline to your experiment, you will be a change maker."},
]