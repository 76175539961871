import React, { useEffect, useState } from 'react';

export const RadialChart = ({ data, categories, wrtieup, numCircles = 6, radius }) => {
  const numCategories = categories.length;
  const angleStep = (2 * Math.PI) / numCategories;
  const offsetAngle = -Math.PI / 2;
  // #F0F0F0
  const categoryColors = ['#3E74D1', '#FFBD16', '#45B43B', '#E22146', '#3D3D3D'];
  const categoryColors0 = [
    'rgba(62, 116, 209, 0.5)', // #3E74D1 with 50% opacity
    'rgba(255, 189, 22, 0.5)', // #FFBD16 with 50% opacity
    'rgba(69, 180, 59, 0.5)',  // #45B43B with 50% opacity
    'rgba(226, 33, 70, 0.5)',  // #E22146 with 50% opacity
    'rgba(61, 61, 61, 0.5)'    // #3D3D3D with 50% opacity
  ];
  const circles = Array.from({ length: numCircles }).map((_, index) => {
    const isOuterCircle = index === numCircles - 1;
    const circleRadius = (radius / numCircles) * (index + 1);

    return (
      <circle
        key={index}
        cx={radius}
        cy={radius}
        r={circleRadius}
        stroke={isOuterCircle ? "#333333" : "#e0e0e0"}  // Darker color for the outer circle
        fill="none"
        strokeWidth={isOuterCircle ? "2" : "1.2"} // Slightly thicker stroke for the outer circle
      />
    );
  });

  const spokes = categories.map((category, index) => {
    const angle = offsetAngle + index * angleStep;
    const x1 = radius + Math.cos(angle) * radius;
    const y1 = radius + Math.sin(angle) * radius;
    return (
      <line
        key={category}
        x1={radius}
        y1={radius}
        x2={x1}
        y2={y1}
        stroke="#BACDEE"
        strokeWidth="1"
      />
    );
  });

  const points = data.map((value, index) => {
    const angle = offsetAngle + index * angleStep;
    const distance = value * radius;
    const x = radius + Math.cos(angle) * distance;
    const y = radius + Math.sin(angle) * distance;
    return { x, y };
  });

  const path = points
    .map((point, index) => `${index === 0 ? 'M' : 'L'} ${point.x} ${point.y}`)
    .join(' ') + ' Z';

  return (
    <svg width={radius * 2 + 70} height={radius * 2 + 70} viewBox={`-20 -20 ${radius * 2 + 70} ${radius * 2 + 50}`}>
      {circles}
      {spokes}
      <path d={path} fill="#ded9d952" stroke="#3a3b3a" strokeWidth="1" />
      {points.map((point, index) => (
        <circle
          key={index}
          cx={point.x}
          cy={point.y}
          r="1.8"
          fill={categoryColors[index % categoryColors.length]}
        />

        // <svg width={radius * 2 + 40} height={radius * 2 + 40} viewBox={`-20 -20 ${radius * 2 + 40} ${radius * 2 + 40}`}>

      ))}

{console.log(points)}
{categories.map((category, index) => {
    const angle = offsetAngle + index * angleStep;
    const labelRadius = radius * 0.3;

    let labelX = radius + Math.cos(angle) * labelRadius;
    let labelY = radius + Math.sin(angle) * labelRadius;
    let labelYAdjusted = labelY - 6;
    let rotation = (angle * 180) / Math.PI;

    if (category === "Relationships") {
      labelX -= 4;
    }
   
    if (category === "Self Awareness") {
      rotation += 180;
    }
    if (category === "Work and Education") {
      rotation += 180;
    }
    if (category === categories[3]) {
      rotation += 180;
      labelX -= 2;
    }
    if (category === categories[0]) {
      // rotation += 180;
      labelX -= 4;
    }
    if (category === categories[2]) {
      labelX += Math.cos(angle) * 10;
      labelYAdjusted += Math.sin(angle) * 10;
      labelX -= 1;
    }
    if (category === categories[4]) {
      rotation += 180;
      labelX -= 8;
    }

    return (
      <text
        key={category}
        x={labelX}
        y={labelYAdjusted}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize="13"
        fontWeight="400"
        fill={categoryColors[index % categoryColors.length]}
        transform={`rotate(${rotation}, ${labelX}, ${labelYAdjusted})`}
        style={{
          pointerEvents: 'none',
        }}
      >
        {category}
      </text>
    );
  })}

{wrtieup.map((category, index) => {
  const angle = offsetAngle + index * angleStep;

  // Adjust labelRadius for Health, Wealth, and Work and Education categories
  const labelRadius = 
  category === "Wealth"
    ? radius * 0.94
    : category === "Health"
    ? radius * 0.92
    : category === "Work and Education"
    ? radius * 0.81
    : radius * 0.83;


  let labelX = radius + Math.cos(angle) * labelRadius;
  let labelY = radius + Math.sin(angle) * labelRadius;

  if (category === "Relationships") {
    labelX -= 4;
  }

  const labelYAdjusted = labelY - 6;

  let rotation = (angle * 180) / Math.PI;

  if (category === "Self Awareness") {
    rotation += 180;
  }

  if (category === "Work and Education") {
    rotation += 180;
    labelX -= 0;
  }

  if (category === "Wealth") {
    // Adjusting "Wealth" label to move down a little
    labelY -= 55;
  }

  return (
    <text
      key={category}
      x={labelX}
      y={labelYAdjusted}
      textAnchor="middle"
      dominantBaseline="middle"
      fontSize="9"
      fontWeight="500"
      fill={categoryColors0[index % categoryColors.length]}
      transform={`rotate(${rotation}, ${labelX}, ${labelYAdjusted})`}
      style={{
        pointerEvents: 'none',
      }}
    >
      {category}
    </text>
  );
})}

{points.map((point, index) => {
  let labelX = point.x + 10; // Default offset for x position
  let labelY = point.y - 20; // Default offset for y position
  
  // Customize the position for each index
  if (index === 0) {
    // For the first point (index 0), move it slightly more to the right
    labelX = point.x + 12;
    labelY = point.y - 1;
  } else if (index === 1) {
    // For the second point (index 1), keep it closer to the point
    if(data[1]>0.9){
      labelX = point.x + -31;
      labelY = point.y - 29;
    }else{
      labelX = point.x + 1;
      labelY = point.y - 29;
    }
  } else if (index === 2) {
    // For the third point (index 2), move it to the left
    // labelX = point.x - -12;
    // labelY = point.y - -21;
    if(data[2]>0.9){

    }else{
     labelX = point.x - -28;
    labelY = point.y - -21;
    }

  } else if (index === 3) {
    // For the fourth point (index 3), move it more upwards
    labelX = point.x + 20;
    labelY = point.y - -22;
  } else if (index === 4) {
    // For the fifth point (index 4), move it more to the right
    
    console.log(data[4])
if(data[4]>0.94){

}else{
  labelX = point.x + -17;
    labelY = point.y - 25;
}

  }

  return (
    <text
      key={index}
      x={labelX}
      y={labelY}
      fontSize="12"
      fontWeight='500'
      fill={categoryColors[index % categoryColors.length]}

      style={{
        pointerEvents: 'none',
      }}
    >
      {data[index].toFixed(2)}
    </text>
  );
})}
    </svg>
  );
};