import React, { } from "react";
 import { IoEyeSharp } from "react-icons/io5";
import { StaffTableComponents } from "../../components/StaffTableComponents";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";


function Copuontable() {

  let header = [
    { label: "Id", field: "id" },
    { label: "Expiry Date", field: "expiry_date" },
    { label: "Coupon Status", field: "coupen_status" },
    { label: "Coupon Type", field: "coupentype" },

    { label: "Coupon Code", field: "coupen_code" },
    // { "": "Action", "field": "action","subaction1": <IoEyeSharp className="indexeye"/> }
  ];
  const sumofkeys = [{ first: "staffname" }];

  return (
   <>
    <Sidebar />
    <div className="containerNormal mainContainerlarge">
        <Navbar />
        <div>
    <StaffTableComponents
      head={header}
      title={"Coupon List"}
      axiostabledata={"coupen"}
    Add={"+ ADD"}
    Create={"/AddCoupon"}
    Edit={"/EditCoupon"}
    View={"/EditCoupon"}
      Sumofkeys={sumofkeys}
    />
    </div>  </div>
   
   </>
 
  );
}

export default Copuontable;
