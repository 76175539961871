import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import Pdftest1 from '../Pagesmaker/Pdftest1';


const Result = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Form submitted!');
    // Handle form submission logic here
  };
  const token =localStorage.getItem('user');
  console.log( JSON.parse(token).details.id)
 
  return (
    <div className="  fullwiped">
      <div className='home-container'>
      <section className="content" style={{background:"white"}}>
       
        <div className="right-side">
          <div style={{display:"flex",flexDirection:"column"}}>
          <div style={{ padding: "12px" }}>
            <img
              src="https://static.wixstatic.com/media/5715af_a286bfb446374a96877a4193a1ef0933~mv2.png/v1/fill/w_114,h_60,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/PAT%20Logo.png"
              alt="PAT Logo"
              className="banner"
            />
            <img
              src="https://static.wixstatic.com/media/77a25b_ec2fa92086954799bf72699afde7a666~mv2.png/v1/fill/w_191,h_34,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/VMKGBLUE.png"
              alt="VMK Blue Banner"
              className="banner"
            />
          </div>
          <h1 style={{ marginTop: '79px',color:"#2A5996",background:"white",textAlign:'center' }}>Congratulations!</h1>
          {/* <p style={{ fontSize: '13px',background:"white" ,color:"#2A5996" }}>
            You completed your test
          </p> */}
           <p style={{ fontSize: '14px',background:"white" ,color:"#2A5996",textAlign:'center',fontWeight:"bold"}}>
          You have completed the Personal Assessment Test. 
        
          </p>
          <br/>
          <p style={{ fontSize: '14px',background:"white" ,color:"#2A5996",textAlign:'center',fontWeight:"bold"}} >
          
          You will receive your comprehensive 17-page report on your email ID. You may also download it using the button below
          </p>
         <div className='downLoad mt-3'>
         {/* Result */}
         <button 
  className="download_btn" 
  style={{ padding: "12px" }} 
  onClick={() => window.open(`/Pdftest1/${JSON.parse(token).details.id}`)}
>
  Download Report
</button>

          {/* <button className='download_btn mr-2 '  style={{background:"#45B43B",padding:"12px"}}>Previous Activity</button> */}
         </div>
          </div>

         
        </div>
      </section>
    </div>
    </div>
  );
};

export default Result;
