export const BASE_URL = "http://13.201.40.47:8080/vmkp/api";

// export const baseurlvmkp='http://13.201.40.47:8080/vmkp/api'
export const Relationshpslabels =  [
    'mother', 'father', 'children', 'grandparents', 'partner', 'spouse',
    'siblings', 'girlfriends', 'boyfriends', 'friends', 'Friends With Benifits',
    'relatives', 'communities', 'boss', 'colleague', 'teacher', 'class mate',
    'conflicts', 'love', 'intimacy', 'sex', 'commitments', 'Gols In Your Relationshps'
];

export const healthlabels=[
   'cooking', 'diet', 'diseases', 'emotion', 'entertainment',
        'fitness', 'goalsInHealth', 'hygiene', 'lifestyleInHealth',
        'nutrition', 'personalCare', 'power', 'sports', 'stress',
        'traumas', 'vacation', 'vitality', 'weight'
  
]

export const  wealthlabel=[
 'assets', 'budgeting', 'expansion', 'goals In Wealth', 'growth', 'housing', 'income', 'inheritance', 'liabilities', 'lifestyle',
        'loans', 'luxury Comfort',  'personal Finance', 'personal Gadgets', 'planning', 'property', 'savings', 'subscription', 'taxes', 'technology'

]

export const workeducationlabel=[
    'education',
    'job',
    'buisness',
    'profession',
    'knowledge',
    'earnings',
    'competencies',
    'skills',
    'leadership',
    'teamSkills',
    'transport',
    'lifestyleWork',
    'goalsInWork'
]

export const  selfAwarenessTopics = [
    "prayers",
    "discipline",
    "meditation",
    "reading",
    "music",
    "fashion",
    "travel",
    "reflecting",
    "creation",
    "confidenece",
    "values",
    "learnings",
    "miracles",
    "spirituality",
    "devotion",
    "dreams",
    "future",
    "goalsInSelfAwareness"
];