import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../../components/APi';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';

function EditCoupon() {
  const [couponCode, setCouponCode] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [couponType, setCouponType] = useState('UNLIMITED');
  const [couponStatus, setCouponStatus] = useState('ACTIVE');
  const [usageLimit, setUsageLimit] = useState(0);
  const [percentage, setPercentage] = useState(50.0);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchCouponDetails = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/coupen/get/${id}`);
        if (response.status === 200) {
          const { coupenCode, expiry_date, coupentype, coupenStatus, usageLimit, percentage } =
            response.data;
          setCouponCode(coupenCode);
          setExpiryDate(expiry_date);
          setCouponType(coupentype);
          setCouponStatus(coupenStatus);
          setUsageLimit(usageLimit);
          setPercentage(percentage);
        } else {
          alert('Failed to fetch coupon details');
        }
      } catch (error) {
        console.error('Error fetching coupon details:', error);
        alert('An error occurred while fetching the coupon details');
      }
    };

    fetchCouponDetails();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const params = {
      coupenCode: couponCode,
      expiry_date: expiryDate,
      coupentype: couponType,
      coupenStatus: couponStatus,
      usageLimit: usageLimit,
      percentage: percentage,
    };

    try {
      const response = await axios.put(`${BASE_URL}/coupen/put/${id}`, params, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        alert('Coupon updated successfully!');
        navigate('/Copuontable/1');
      } else {
        alert('Failed to update coupon');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while updating the coupon');
    }
  };

  const handleCouponTypeChange = (e) => {
    const newCouponType = e.target.value;
    setCouponType(newCouponType);

    if (newCouponType === 'MULTIUSER') {
      setUsageLimit(0);
    }
  };

  return (
<>
<Sidebar />
 <div className="containerNormal mainContainerlarge">
     <Navbar />
     <Container className="mt-5">
      <h2 style={{color:'black'}}>Edit Coupon</h2>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="couponCode">
              <Form.Label>Coupon Code</Form.Label>
              <Form.Control
                type="text"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="expiryDate">
              <Form.Label>Expiry Date</Form.Label>
              <Form.Control
                type="datetime-local"
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="couponType">
              <Form.Label>Coupon Type</Form.Label>
              <Form.Select value={couponType} onChange={handleCouponTypeChange}>
                <option value="MULTIUSER">MULTIUSER</option>
                <option value="UNLIMITED">UNLIMITED</option>
                <option value="LIMITED">LIMITED</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="couponStatus">
              <Form.Label>Coupon Status</Form.Label>
              <Form.Select
                value={couponStatus}
                onChange={(e) => setCouponStatus(e.target.value)}
              >
                <option value="ACTIVE">ACTIVE</option>
                <option value="INACTIVE">INACTIVE</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="usageLimit">
              <Form.Label>Usage Limit</Form.Label>
              <Form.Control
                type="number"
                value={usageLimit}
                onChange={(e) => setUsageLimit(e.target.value)}
                disabled={couponType === 'MULTIUSER' || couponType === 'UNLIMITED'}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="percentage">
              <Form.Label>Discount Percentage</Form.Label>
              <Form.Control
                type="number"
                step="0.01"
                value={percentage}
                onChange={(e) => setPercentage(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Button variant="primary" type="submit">
          Update Coupon
        </Button>
      </Form>
    </Container>
</div>
</>



  );
}

export default EditCoupon;
