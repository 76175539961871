import React, { } from "react";
 import { IoEyeSharp } from "react-icons/io5";
import { StaffTableComponents } from "../../components/StaffTableComponents";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";


function Stafftable() {

  let header = [
    { label: "Id", field: "id" },
    { label: "Staff Name", field: "name" },
    {label:'Designation',field:"designation"},
    { label: "Email", field: "email" },

    { label: "Mobile No.", field: "phone_no" },
    { "label": "Action", "field": "action","subaction1": <IoEyeSharp className="indexeye"/> }
  ];
  const sumofkeys = [{ first: "staffname" }];

  return (
    <>
       <Sidebar />
    <div className="containerNormal mainContainerlarge">
        <Navbar />
    
        <div>
    <StaffTableComponents
      head={header}
      title={"STAFF LIST"}
      axiostabledata={"staff"}
    Add={"+ADD"}
    Create={"/stafftable"}
    Edit={"/Staffedit"}
    View={"/Staffedit"}
      Sumofkeys={sumofkeys}
    />
    </div>
    </div>
    </>

  );
}

export default Stafftable;
