import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseurlvmkp } from './Subjects/Baseurl';

const Redirect = () => {
  const navigate = useNavigate();


  const handleNav = () => {
    const mapping = [
      { label: "Relationship", path: "/Exam" },
      { label: "Health", path: "/health" },
      { label: "Work Education", path: "/we" },
      { label: "Wealth", path: "/wealth" },
      { label: "Self Awareness", path: "/selfAww" }
    ];

    // Find the first zeroKey and navigate to its path
    const firstZeroKey = zeroKeys[0];
    if (firstZeroKey) {
      const target = mapping.find((item) => item.label === firstZeroKey);
      if (target) {
        navigate(target.path); // Navigate to the first zeroKey's path
      }
    } else {
      navigate("/Totorialstartpage"); // Default navigation if no zero keys
    }
  };


  const [checkedKeys, setCheckedKeys] = useState([]);
    const checkboxes = document.querySelectorAll('.checkbox-wrapper-12 .cbx input[type="checkbox"]');

  // Function to check checkboxes one by one
  const [checkedStates, setCheckedStates] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [zeroKeys, setZeroKeys] = useState([]); // State to store key names with values equal to zero

  useEffect(() => {
    axios
      .get(`${baseurlvmkp}/exam/getexampending/${JSON.parse(localStorage.getItem('user')).details.id}`, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })
      .then((response) => {
        console.log(response.data);

        // Destructure the response data
        const { relationshipid, healthid, workeducationid, wealthid, selfawarenessid } = response.data;

        // Mapping of labels to their corresponding API keys and indices
        const mapping = [
          { label: "Relationship", id: relationshipid, index: 0 },
          { label: "Health", id: healthid, index: 1 },
          { label: "Work Education", id: workeducationid, index: 2 },
          { label: "Wealth", id: wealthid, index: 3 },
          { label: "Self Awareness", id: selfawarenessid, index: 4 }
        ];

        // Initialize states
        const updatedCheckedStates = [...checkedStates];
        const zeroKeyList = [];

        // Process each mapping item
        mapping.forEach(({ label, id, index }) => {
          if (id !== 0) {
            updatedCheckedStates[index] = true; // Check the checkbox for non-zero values
          } else {
            zeroKeyList.push(label); // Store the label for zero values
          }
        });

        // Update the states
        setCheckedStates(updatedCheckedStates);
        setZeroKeys(zeroKeyList); // Update the order for zero keys based on mapping
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  console.log(zeroKeys[0])
  const buttonLabel = zeroKeys.length > 0 ? zeroKeys[0] : "Task";

  
   return (
    <div className='fullwiped'>
      <div className="home-container"  >
        <div className="thankyou-box thankyou"   style={{  padding: '30px', borderRadius: '8px', boxShadow: '0 4px 20px rgba(0,0,0,0.1)', textAlign: 'center' , width: '360px' }}>
          <div className='imgarea'>

          <img 
          className='img1'
            src='https://static.wixstatic.com/media/5715af_a286bfb446374a96877a4193a1ef0933~mv2.png/v1/fill/w_114,h_60,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/PAT%20Logo.png' 
            alt="Logo" 
            style={{ width: '30%', marginBottom: '15px' }} 
          />
          <img 
            src='https://static.wixstatic.com/media/77a25b_ec2fa92086954799bf72699afde7a666~mv2.png/v1/fill/w_191,h_34,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/VMKGBLUE.png' 
            alt="Logo" 
            style={{ width: '60%', marginBottom: '15px' }} 
          />
          </div>

          <h2 style={{ color: '#396AA9', margin: '28px 0' }}>Resume Your Pat</h2>
          <ul className="ulfixer">
      {["Relationship", "Health", "Work Education", "Wealth", "Self Awareness"].map(
        (label, index) => (
          <li className="checkboxalignment" key={`checkbox-${index}`}>
            <div className="checkbox-wrapper-12">
              <div className="cbx">
                <input
                  id={`checkbox-${index}`}
                  type="checkbox"
                  checked={checkedStates[index]}
                  readOnly
                />
                <label htmlFor={`checkbox-${index}`}></label>
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
                  <path d="M2 8.36364L6.23077 12L13 2"></path>
                </svg>
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                  <filter id="goo-12">
                    <feGaussianBlur
                      in="SourceGraphic"
                      stdDeviation="4"
                      result="blur"
                    ></feGaussianBlur>
                    <feColorMatrix
                      in="blur"
                      mode="matrix"
                      values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                      result="goo-12"
                    ></feColorMatrix>
                    <feBlend in="SourceGraphic" in2="goo-12"></feBlend>
                  </filter>
                </defs>
              </svg>
            </div>
            <div className="black">{label}</div>
          </li>
        )
      )}
    </ul>

          <button 
            className=' ' 
            style={{ color: 'white', fontWeight: 500, background: '#396AA9', border: 'none', borderRadius: '4px', padding: '10px 20px', cursor: 'pointer', transition: 'background 0.3s' }} 
            onClick={()=>handleNav()}
            onMouseEnter={e => e.currentTarget.style.background = '#FEBD16'} // Change color on hover
            onMouseLeave={e => e.currentTarget.style.background = '#396AA9'} // Revert color
          >
Continue with {buttonLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Redirect;
