import React from "react";
import { Document, Page, Image, StyleSheet, PDFViewer } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    position: "relative",
  },
  fullPageImage: {
    width: "100%",
    height: "100%",
  },
});

// Create a PDF Document Component
const PdfDocument = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      {/* Full-page image */}
      <Image
        src="https://i.ibb.co/PQtMmKW/Bhavisha-Sheth-15-04-24-copy-images-0.jpg"
        style={styles.fullPageImage}
      />
    </Page>
  </Document>
);

const Pdftest = () => {
  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <PdfDocument />
    </PDFViewer>
  );
};

export default Pdftest;
