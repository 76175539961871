import React, { useEffect, useRef, useState } from 'react';
import html2pdf from "html2pdf.js"; // Import html2pdf.js
import img2 from './../Files/Subjects/page2.2.jpg';
import img8 from './../Files/Subjects/pagefull8.jpg';
import img9 from './../Files/Subjects/pagefull9.jpg';
import img10 from './../Files/Subjects/pagefull10.jpg';
import img11 from './../Files/Subjects/pagefull11.jpg';
import img13 from './../Files/Subjects/pagefull13.jpg';
import img14 from './../Files/Subjects/pagefull14.jpg';
import img15 from './../Files/Subjects/pagefull15.jpg';
import img16 from './../Files/Subjects/payfull16.jpg';
import img12 from './../Files/Subjects/payfull12.jpg';

import img3 from './../Files/Subjects/page3.png';
import top from './supertop2.png';
import footer from './footer3.png';
import stamp from './Creator.png';
import rertr from "./sidecorner.png";  
import Sidebarright from "./Sidefaci.png";  
import Sidefaci from './Sidefaci.png';
import Sidecreate from './sidecreator.png';
import Sideins from './Sideins.png';
import Sideexe from './sideExe.png'
import { useNavigate, useParams } from "react-router-dom";
// import { RadialChart } from './RadialChart';
import axios from 'axios';
// import ScatterPlot from './ScatterPlot';
// import Plot1 from './Plot1';
import { blocks, Business, Coding, Content, Data, Design, female_16_24, female_25_40, female_41_60, Finance, Humanities, male_16_24, male_25_40, male_41_60, Marketing, Personal_Growth, powerranger, stamptest, Technology } from '../Files/Para';
import { he, rs, sa, we, woe } from '../Files/Subjects/Circle';
import { baseurlvmkp } from '../Files/Subjects/Baseurl';
import { RadialChart } from '../Files/Subjects/RadialChart';
import ScatterPlot from '../Files/Subjects/ScatterPlot';
import Plot1 from '../Files/Subjects/Plot1';
import Result from '../Files/Result';
import Matrix, { axis } from '../Matrix';
// import { he } from '../Files/Subjects/Circle';
// import { blocks } from '../Files/Para';
const Pdftest1 = ({ name = "Bhavisha Sheth", age = 26, gender = "Female", occupation = "Businessperson" }) => {
    const navigate = useNavigate();  

    const imageMap = {
      Facilitator: Sidefaci,
      Creator: Sidecreate,
      Instructor: Sideins,
      Executor: Sideexe
  };


    const printButtonRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
       if (printButtonRef.current) {
        printButtonRef.current.click();
      }
    
       const handleAfterPrint = () => {
        if (printButtonRef.current) {
          printButtonRef.current.click();
        }
      };
    
      window.addEventListener("afterprint", handleAfterPrint);
    
       return () => {
        window.removeEventListener("afterprint", handleAfterPrint);
      };
    }, []);
    
    
    const handlePrint = () => {
      window.print();
    };
    console.log(stamptest)
    
    const [data, setData] = useState([0.67, 0.66, 0.77, 0.88, 0.68]);
    const [data1, setData1] = useState([0.67, 0.66, 0.77, 0.88, 0.68]);
    
    const[categories,setcategories]= useState([])
    // const data = [0.67, 0.66, 0.77, 0.88, 0.68]; // Values for each category (scaled from 0 to 1)
    // const categories = ['Balanced & Engaged', 'Health Relationships', 'Wealth Relationships', 'Generous & Wise', 'Committed & Discovering']; // Categories
    const categories1 = ['Relationships', 'Health', 'Wealth', 'Work and Education', 'Self Awareness']; // Categories
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const dynamicRadius = Math.min(screenWidth, screenHeight) / 2;
    // Calculate the radius based on screen size
    const [radius, setRadius] = useState(dynamicRadius);
    const [dataPoints, setDataPoints] = useState([]);
    const [paragenderage, setParagenderage] = useState([])
    const today = new Date();
const formattedDate = `${today.getDate().toString().padStart(2, '0')}.${(today.getMonth() + 1).toString().padStart(2, '0')}.${today.getFullYear().toString().slice(-2)}`;


    const [applicability,setapplicability]=useState([])
    const [workability,setworkability]=useState([])
    const [criticality,setcriticality]=useState([])
    const [futurality,setfuturality]=useState([])
    const[rsdata,setrsdata]=useState([])
    const[heData,setheData]=useState([])
    const[weData,setweData]=useState([])
    const[woeData,setwoeData]=useState([])
    const[saData,setsaData]=useState([])  
    const[userdata,setuserdata]=useState([])
    function datachecker(data,points) {
     console.log(data[0].app[0].one_1)
     console.log(points[0].applicability)
    // applicability
    if( getRange(points[0].applicability)==1){
      setapplicability(data[0].app[0].one_1)
    }
    else if( getRange(points[0].applicability)==2){
      setapplicability(data[0].app[0].two_2)
    }else if( getRange(points[0].applicability)==3){
      setapplicability(data[0].app[0].three_3)
    }else if( getRange(points[0].applicability)==4){
      setapplicability(data[0].app[0].four_4)
    }else if( getRange(points[0].applicability)==5){
      setapplicability(data[0].app[0].five_5)
    }else if( getRange(points[0].applicability)==6){
      setapplicability(data[0].app[0].six_6)
    }else if( getRange(points[0].applicability)==7){
      setapplicability(data[0].app[0].seven_7)}
    
    // workcability
    
    if( getRange(points[0].workability)==1){
      console.log(data[0].work[0].one_1)
      setworkability(data[0].work[0].one_1)

    }
    else if( getRange(points[0].workability)==2){
     setworkability(data[0].work[0].two_2)
    }else if( getRange(points[0].workability)==3){
     setworkability(data[0].work[0].three_3)
    }else if( getRange(points[0].workability)==4){
     setworkability(data[0].work[0].four_4)
    }else if( getRange(points[0].workability)==5){
     setworkability(data[0].work[0].five_5)
    }else if( getRange(points[0].workability)==6){
     setworkability(data[0].work[0].six_6)
    }else if( getRange(points[0].workability)==7){
     setworkability(data[0].work[0].seven_7)
    }
    // criticality
    if( getRange(points[0].criticality)==1){
      setcriticality(data[0].criti[0].one_1)
    }
    else if( getRange(points[0].criticality)==2){
     setcriticality(data[0].criti[0].two_2)
    }else if( getRange(points[0].criticality)==3){
     setcriticality(data[0].criti[0].three_3)
    }else if( getRange(points[0].criticality)==4){
     setcriticality(data[0].criti[0].four_4)
    }
    else if( getRange(points[0].criticality)==5){
     setcriticality(data[0].criti[0].five_5)
    }else if( getRange(points[0].criticality)==6){
     setcriticality(data[0].criti[0].six_6)
    }else if( getRange(points[0].criticality)==7){
     setcriticality(data[0].criti[0].seven_7)
    }
    
    
    if( getRange(points[0].futurality)==1){
     setfuturality(data[0].futu[0].one_1)
    }
    else if( getRange(points[0].futurality)==2){
     setfuturality(data[0].futu[0].two_2)
    }else if( getRange(points[0].futurality)==3){
     setfuturality(data[0].futu[0].three_3)
    }else if( getRange(points[0].futurality)==4){
     setfuturality(data[0].futu[0].four_4)
    }
    else if( getRange(points[0].futurality)==5){
     setfuturality(data[0].futu[0].five_5)
    }else if( getRange(points[0].futurality)==6){
     setfuturality(data[0].futu[0].six_6)
    }else if( getRange(points[0].futurality)==7){
     setfuturality(data[0].futu[0].seven_7)
    }
    
    
    else{
      console.log("not working")
    }
    
    
    }
    const token =localStorage.getItem('user');
console.log( JSON.parse(token).details.id)

  
    const [displaywheel, setDisplaywheel] = useState(false)
    const [loadingdata, setLoadingdata] = useState(false)
    const [average, setAverage] = useState([])
    const calculateMinMax = (nums) => {
      nums = nums.map(num => Number(num));
    
      const min = Math.min(...nums);
      const max = Math.max(...nums);
    console.log(Number(min) >= 0.6 && Number(max) >= 0.6)
    if (Number(min) >= 0.6 && Number(max) >= 0.6) {
      const minConverted = Math.floor(min * 100);
        const maxConverted = Math.floor(max * 100);
    
         let result = (minConverted / maxConverted) * 100;
        
        result = Number(result.toFixed(2)); // Convert to number and round to 2 decimals
    
        console.log("right", result);
        setDisplaywheel(true)
        setAverage(result)
    
        return { min: minConverted, max: maxConverted, result: result };
      } else {
        console.log("wrong");
        const minConverted = Math.floor(min * 100);
        const maxConverted = Math.floor(max * 100);
        setDisplaywheel(false)
         let result = (minConverted / maxConverted) * 100;
        
        result = Number(result.toFixed(2)); // Convert to number and round to 2 decimals
        console.log("wrong", result);
        setAverage(result)
         return { error: 'Wheel Alignment Score is not calculated for values below 0.6.' };
      }
    };
    

    console.log(rsdata)
    
    const [dataneed,setDataNeed]=useState([])
    const[carrier,setcarrier]=useState([])
    useEffect(() => {
      setLoadingdata(true)
      let id  =JSON.parse(token).details.id;

      axios.get(`${baseurlvmkp}/index/get/${id}` ).then((response) => {
        console.log(axis.x)
        // { x: 0.51, y: 0.90 },
        // x: 0.52, y: 0.88 
        
        const x1=response.data[0].x_AXIS
        const y1=response.data[0].y_AXIS
        // const x1= axis.x
        // const y1=axis.y
          console.log(  getRange(response.data[0].applicability));
           setDataPoints([{x:x1,y:y1}]);
        setData([response.data[0].relationships,response.data[0].health,response.data[0].wealth,response.data[0].workEducation,response.data[0].selfAwareness])
    const points=response.data
    
    setData1([response.data[0].relationships,response.data[0].health,response.data[0].wealth,response.data[0].workEducation,response.data[0].selfAwareness,response.data[0].average])
    
     const relationshipData = rs.find(item => response.data[0].relationships >= item.min && response.data[0].relationships <= item.max);
    setcarrier(powerranger?.find(item => response.data[0].average >= item.min && response.data[0].average <= item.max))
    const healthdata = he.find(item => response.data[0].health >= item.min && response.data[0].health <= item.max);
    const wealth=we.find(item => response.data[0].wealth >= item.min && response.data[0].wealth <= item.max);
     const workEducationdata=woe.find(item => response.data[0].workEducation >= item.min && response.data[0].workEducation <= item.max);
    const selfAwarenessdata=sa.find(item => response.data[0].selfAwareness >= item.min && response.data[0].selfAwareness <= item.max);
    
    calculateMinMax([response.data[0].relationships,response.data[0].health,response.data[0].wealth,response.data[0].workEducation,response.data[0].selfAwareness])
    
     setheData(healthdata)
     setsaData(selfAwarenessdata)
     setwoeData(workEducationdata)
    setrsdata(relationshipData)
    setweData(wealth)
    const blockName = findBlockName(x1, y1);
      
      const dataMap = {
        "Coding": Coding,
        "Data": Data,
        "Technology": Technology,
        "Business": Business,
        "Marketing": Marketing,
        "Finance": Finance,
        "Personal Growth": Personal_Growth,
        "Humanities": Humanities,
        "Design":Design,
        "Content":Content,
      };
    
      const dataToSet = dataMap[blockName] || [];
      
      setDataNeed(dataToSet);
      console.log(dataToSet)
    setcategories([relationshipData.title,healthdata.title,wealth.title,workEducationdata.title,selfAwarenessdata.title])
        axios.get(`${baseurlvmkp}/vmkp/get/${id}`)
        .then(response => {
          console.log(response.data);
          setuserdata(response.data)
           if (response.data.age >= 41 && response.data.age <= 60) {
            return response.data.gender === 'Female' ?datachecker( female_41_60 ,points):datachecker( male_41_60,points );
          } else if (response.data.age >= 25 && response.data.age <= 40) {
            return response.data.gender === 'Female' ? datachecker(female_25_40,points ):datachecker(male_25_40 ,points);
          } else if (response.data.age >= 16 && response.data.age <= 24) {
            return response.data.gender === 'Female' ? datachecker(female_16_24,points) :datachecker(male_16_24,points);
          } else {
            return console.log('Age Grp Not found ');
          }
    
    
    
     
    
        })
        .catch(error => {
          console.error(error);
        });
    
    
     
    
    }).catch((error)=>{
        console.log(error)
                 }).finally(() => {



                
                //    setIsLoading(false);
                   setTimeout(() => {
                    setLoadingdata(false);
    
    
                   }, 10000);
                
                 })
    
    
    
      const updateRadius = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        const newRadius = Math.min(width, height) * 0.4;  
        setRadius(newRadius);
      };
    
       updateRadius();
      window.addEventListener('resize', updateRadius);
    
      return () => {
        window.removeEventListener('resize', updateRadius);
      };
    }, []);
    console.log(applicability,workability,futurality,criticality)
    const splitIntoTwoLines = (text) => {
      if (!text) return "";  
    
      const words = text.split(" ");
      const midIndex = Math.ceil(words.length / 2); 
      const firstLine = words.slice(0, midIndex).join(" ");  
      const secondLine = words.slice(midIndex).join(" "); 
    
      return `${firstLine}, ${secondLine}`; 
    };
    
    function getRange(number) {
       const ranges = [
        { min: 0, max: 0.3, range: 1 },
        { min: 0.31, max: 0.4, range: 2 },
        { min: 0.41, max: 0.5, range: 3 },
        { min: 0.51, max: 0.6, range: 4 },
        { min: 0.61, max: 0.7, range: 5 },
        { min: 0.71, max: 0.8, range: 6 },
        { min: 0.81, max: Infinity, range: 7 },
      ];
    
       for (const range of ranges) {
        if (number >= range.min && number <= range.max) {
          return range.range;
        }
      }
    
       return null;
    }

    function findBlockName(x, y) {
      const adjustedX = x >= 1.0 ? 0.81 : x;
      const adjustedY = y >= 1.0 ? 0.81 : y;
    
      // First, try to find a match with any block
      let block = blocks.find(b =>
        adjustedX >= b.xRange[0] && adjustedX <= b.xRange[1] &&
        adjustedY >= b.yRange[0] && adjustedY <= b.yRange[1]
      );
    
      // If no block is found, check the "Design" blocks
      if (!block) {
        block = blocks.find(b =>
          b.title === "Design" &&
          adjustedX >= b.xRange[0] && adjustedX <= b.xRange[1] &&
          adjustedY === b.yRange[0] // Design blocks have only one y-value
        );
      }
    
      // If still no block is found, check the "Content" blocks
      if (!block) {
        block = blocks.find(b =>
          b.title === "Content" &&
          adjustedX >= b.xRange[0] && adjustedX <= b.xRange[1] &&
          adjustedY === b.yRange[0]  
        );
      }
    
      // Return the block's title if found, otherwise "No block contains the given point"
      return block ? block.title : "No block contains the given point";
    }
    
    // function findBlockName(x, y) {
    //   const adjustedX = x >= 1.0 ? 0.81 : x;
    //   const adjustedY = y >= 1.0 ? 0.81 : y;
    
    //   // First, try to find a match with any block
    //   let block = blocks.find(b =>
    //     adjustedX >= b.xRange[0] && adjustedX <= b.xRange[1] &&
    //     adjustedY >= b.yRange[0] && adjustedY <= b.yRange[1]
    //   );
    
    //   // If no block is found, check the "Design" blocks
    //   if (!block) {
    //     block = blocks.find(b =>
    //       b.title === "Design" &&
    //       adjustedX >= b.xRange[0] && adjustedX <= b.xRange[1] &&
    //       adjustedY === b.yRange[0] // Design blocks have only one y-value
    //     );
    //   }
    
    //   // Return the block's title if found, otherwise "No block contains the given point"
    //   return block ? block.title : "No block contains the given point";
    // }


    // function findBlockName(x, y) {
    //    const adjustedX = x >= 1.0 ? 0.81 : x;
    //   const adjustedY = y >= 1.0 ? 0.81 : y;
    
    //   const block = blocks.find(b => 
    //     adjustedX >= b.xRange[0] && adjustedX <= b.xRange[1] &&
    //     adjustedY >= b.yRange[0] && adjustedY <= b.yRange[1]
    //   );
    
    //   return block ? block.title : "No block contains the given point";
    // }
     

//     const handleDownloadPDF = () => {
//         const element = document.getElementById("pdfContent");
    
//        var options = {
//     margin:       0,
//     filename:     userdata.first_name,
//     image:        { type: 'jpeg', quality: 0.98 },
//     html2canvas:  { scale: 3 }, // Increase scale for higher resolution
//     jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait' }
// };
    
//         html2pdf().from(element).set(options).save();


        
//     };
const [isLoading1, setIsLoading1] = useState(false);
const [progress, setProgress] = useState(0);

const handleDownloadPDF = () => {
  setIsLoading1(true); // Start loading

  const element = document.getElementById("pdfContent");

  const options = {
    margin: 0,
    filename: userdata.first_name,
    image: { type: "jpeg", quality: 1 },
    html2canvas: { scale: 3 }, // Higher resolution
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  };

  html2pdf()
    .from(element)
    .set(options)
    .save()
    .then(() => {
      setIsLoading1(false); // Stop loading after saving
    })
    .catch((error) => {
      console.error("Error generating PDF:", error);
      setIsLoading1(false); // Stop loading if there's an error
    });
};
 console.log(findBlockName(dataPoints[0]?.x,dataPoints[0]?.y))
    return (
<>
{/* <button onClick={handleDownloadPDF} style={{ padding: "10px 20px", marginTop: "20px", cursor: "pointer" }}>
Download PDF
</button> */}
{/* <Result    /> */}

<div className="  fullwiped"  >
      <div className='home-container'>
      <section className="content" style={{background:"white"}}>
       
        <div className="right-side">
          <div style={{display:"flex",flexDirection:"column"}}>
          <div style={{ padding: "12px" }}>
            <img
              src="https://static.wixstatic.com/media/5715af_a286bfb446374a96877a4193a1ef0933~mv2.png/v1/fill/w_114,h_60,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/PAT%20Logo.png"
              alt="PAT Logo"
              className="banner"
            />
            <img
              src="https://static.wixstatic.com/media/77a25b_ec2fa92086954799bf72699afde7a666~mv2.png/v1/fill/w_191,h_34,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/VMKGBLUE.png"
              alt="VMK Blue Banner"
              className="banner"
            />
          </div>
          <h1 style={{ marginTop: '79px',color:"#2A5996",background:"white",textAlign:'center' ,textTransform:'capitalize'}}>Congratulations {userdata.first_name} {userdata.last_name} !</h1>
          {/* <p style={{ fontSize: '13px',background:"white" ,color:"#2A5996" }}>
            You completed your test
          </p> */}
           <p style={{ fontSize: '14px',background:"white" ,color:"#2A5996",textAlign:'center',fontWeight:"bold"}}>
          You have completed the Personal Assessment Test. 
        
          </p>
          <br/>
          <p style={{ fontSize: '14px',background:"white" ,color:"#2A5996",textAlign:'center',fontWeight:"bold"}} >
          
          {/* You will receive your comprehensive 17-page report on your email ID. You may also download it using the button below */}
          </p>
         <div className='downLoad mt-3'>
         {/* Result */}
         
         {/* <button 
  className="download_btn" 
  style={{ padding: "12px" }} 
  onClick={() => {
    if (!loadingdata && !isLoading1) {
      handleDownloadPDF();
    }
  }}
  disabled={loadingdata || isLoading1} // Disable button while fetching or loading
>
  {loadingdata 
    ? "Fetching Report Details" 
    : isLoading1 
      ? "Loading..." 
      : "Download Report"}
</button> */}
{loadingdata?
<span style={{color:'#000000c4',fontWeight:'500'}}>Fetching Report Details.....
</span>
:
<>
{isLoading1?<div className='vmkgmagic'> <div class="loader12"></div><div className='themaker' style={{color:'black',fontWeight:'500'}}>Loading...</div></div>:<button   className="download_btn"  style={{padding:"10px",background:'#224D7E'}}
 onClick={()=>{ handleDownloadPDF()}}>Download Report</button>}
</>
}
          {/* <button
          isLoading1?<div className='vmkgmagic'> <div class="loader12"></div></div>:
          
          
          className='download_btn mr-2 '  style={{background:"#45B43B",padding:"12px"}}>Previous Activity</button> */}
         </div>
          </div>

         
        </div>
      </section>
    </div>
    </div>
    {/* glasses */}
       <div className='glasses'>
           
            
            <div id="pdfContent" className='ibmsansflex' style={{ position: "relative", width: "100%", height: "100%" }}>
                
                {/* Page 1 content */}
                <div style={{   position: "relative", height: "100%",  marginTop:'6px'}}>
                    <img src={rertr} alt="Side corner" style={{
                        // width: "50%", 
                        // height: "100%", 
                        objectFit: "cover", 
                        position: "relative", 
                        left: "15px", 
                        top: "-10px"
                    }} />
                    
                    <div style={{
                        position: "absolute", 
                        top: "5.43%", 
                        right: "3%", 
                        textAlign: "right"
                    }}>
                        <div style={{ fontSize: "23px", color: 'black', fontWeight: "400", marginBottom: "5px",textTransform:'capitalize' }}>
{userdata.first_name} {userdata.last_name}
                        </div>
                        <div style={{ fontSize: "21px", color: "#525659", marginBottom: "10px", fontWeight: "300" ,textTransform:'capitalize'}}>
                            {userdata.age} | {userdata.gender} | {userdata.occupation}
                        </div>
{/* 
                        <img src={stamptest.filter((item)=>item.coreAptitude==findBlockName(dataPoints[0]?.x,dataPoints[0]?.y)) } alt="Sidebar" style={{
                            width: "100%", 
                            height: "70%", 
                            marginTop: "10px", 
                            alignSelf: "flex-end", 
                            marginBottom: "30px"
                        }} /> */}
<img
    src={
        imageMap[
            stamptest.find(
                (item) =>
                    item.coreAptitude === findBlockName(dataPoints[0]?.x, dataPoints[0]?.y)
            )?.stampAtFront
        ]
    }
    alt="Sidebar"
    style={{
        width: "100%",
        height: "70%",
        marginTop: "10px",
        alignSelf: "flex-end",
        marginBottom: "30px"
    }}
/>;
<div style={{ fontSize: "13px", color: "#525659" }}>Date: {formattedDate}</div>
                    </div>
                </div>
 
                <div style={{ position: "relative", height: "100%" }}>
    {/* First Image Container */}
    <div style={{ position: "relative", width: "100%", height: "auto" }}>
        <img
            src={img2}
            alt="Page 2 image"
            style={{
                width: "100%",
                height: "auto",
                position: "relative",
                left: "0.49px",
                top: "0px",
                // marginTop: "1px",
            }}
        />
    </div>

     <div style={{ position: "relative", width: "100%", textAlign: "center", fontSize: "18px", color: "#333" }}>
        <img
            src={img3}
            alt="Page 2 image"
            style={{
                width: "100%",
                height: "auto",
                position: "relative",
                left: "0.5px",
                // top: "1px",
            }}
        />
    </div>


    <div style={{  position: "relative", height: "100%",   }}>
            <div style={{   position: "relative", height: "100%"  }}>
            <img src={top} style={{zIndex:'0',width:"100%",height:'100%',zIndex:0, position: "relative", left: "-12px", top: "1px"}} alt="Description of Image" className="full-image"  />


                 
<div  style={{marginTop:"-112px",padding:'29px',height:'820px',background:'white'}} >
<h1 style={{ fontWeight:'400',padding:'12px',color:'#414649',textTransform:'capitalize'}}>Dear {userdata.first_name} ,</h1>
{/* <br></br> */}
<div  style={{ padding:'12px',color:'black',zIndex:'9999',position:'relative' ,lineHeight:'22px' }}>
<b style={{fontSize:'17px'}}>
{applicability[0]?.sec}
</b>

<p style={{textAlign:'justify',fontSize:'15px',wordSpacing:'8px'}}>
{applicability[0]?.para}
</p>
{/* <br></br> */}
<b style={{fontSize:'17px'}}>
{workability[0]?.sec}    </b>

<p style={{textAlign:'justify',fontSize:'15px',wordSpacing:'8px'}}>
{workability[0]?.para} 
</p>
<b style={{fontSize:'17px'}}>
{criticality[0]?.sec}
</b>

<p style={{textAlign:'justify',fontSize:'15px',wordSpacing:'8px'}}>
{criticality[0]?.para}

</p>
<b style={{fontSize:'17px'}}>
{futurality[0]?.sec}  
</b>

<p style={{textAlign:'justify',fontSize:'15px',wordSpacing:'8px'}}>
{futurality[0]?.para}
</p>
</div>

{/* add footer here */}

</div>
<div className='footerspeical fleering'>
<div className='' style={{color:'white'}}>
HUMAN DESIGN EDUCATION
</div>
<div className='' style={{color:'white'}}>
PAGE 03</div>
</div>

                {/* </div> */}
                 
            </div>
                 {/* Add any content for the second page below */}
                <div style={{  textAlign: "center", fontSize: "18px", color: "#333" }}>
                {/* <h1>pageee</h1> */}

                </div>
                 
            </div>

            <div style={{  position: "relative", height: "100%" }}>
            <div style={{   position: "relative", height: "100%" }}>
            <img src={top} style={{zIndex:'0',width:"100%",height:'100%',zIndex:0, position: "relative", left: "-12px", top: "-6px"}} alt="Description of Image" className="full-image"  />


                 {/* <div style={{  textAlign: "center", fontSize: "18px", color: "#333" }}> */}
                
<div  style={{marginTop:"-112px",padding:'29px',height:'820px',background:'white'}} >
<div style={{padding:'10px'}}>
 {/* <br></br> */}


<div  style={{marginTop:'-20px' ,color:'black'}}>
<p style={{wordSpacing:'4px',textAlign:'justify',zIndex:'99999',position:'relative'}}>
The scores determine how your wheel of life is at this moment. You can refer to the appendix to look at
where you could possibly reach and where you want to reach. However, for that growth and
development you will need to have a structure. You could use the structure at Human Design Education
for that growth or try your methods and test them for yourself time to time by taking PAT every 3 - 6
months.
    </p>
</div>
</div>
<div style={{display:'flex',justifyContent:'center',marginTop:'-39px',alignItems:'center',flexDirection:'column'}}>
<div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',position:'relative',left:'8px' }}>
<RadialChart data={data} wrtieup={categories1} categories={categories} numCircles={10} radius={270} />
</div>



<p style={{color:"black",textAlign:'center',fontWeight:'500',fontSize:'17px'}}>Wheel of Life </p>
{displaywheel?<p style={{color:"black",textAlign:'center'}}>Wheel Alignment* = {average}%</p>
:""}<p style={{color:"black",textAlign:'center',padding:'11px',wordSpacing:'4px'}}>*Applicable only if values are above 0.6. For scores below 0.6, Wheel Alignment Score is not calculated.</p>
</div>

</div>
<div className='footerspeical fleering'>
<div className='' style={{color:'white'}}>
HUMAN DESIGN EDUCATION
</div>
<div className='' style={{color:'white'}}>
PAGE 04</div>
</div>
                {/* </div> */}
                 
            </div>
                 {/* Add any content for the second page below */}
                <div style={{  textAlign: "center", fontSize: "18px", color: "#333" }}>
                {/* <h1>pageee</h1> */}

                </div>
                 
            </div>

            <div style={{  position: "relative", height: "100%" }}>
            <div style={{   position: "relative", height: "100%" }}>
            <img src={top} style={{zIndex:'0',width:"100%",height:'100%',zIndex:0, position: "relative", left: "-12px", top: "-6px"}} alt="Description of Image" className="full-image"  />


                 {/* <div style={{  textAlign: "center", fontSize: "18px", color: "#333" }}> */}
                
<div  style={{marginTop:"-112px",padding:'29px',height:'820px',background:'white'}} >
<div style={{zIndex:'9999',position:'relative'}}>
 {/* <br></br> */}
 <div className=''>
 <h1 style={{wordSpacing:'4px',textAlign:'center', color:'black',position:'relative',top:'7px'   }}>
 Explanation of Wheel of Life
     </h1>
  <div >
 
  <div  style={{ color:'white',padding:'20px'}}>
 
<br></br>
<div className='row' style={{border:'2px solid black',borderBottom:'none',color:'#3E79D6',textAlign:'center'}}>
 <div className="col-12 wheeloflife" style={{background:'#C5D5F1',borderBottom:'2px solid black' }}>
 <span style={{fontWeight:'bold'}} >{rsdata.title}  </span>
 </div>
 <div className='prompt' style={{textAlign:'justify'}}>
 {rsdata.statement}
  </div>

</div>
<div className='row' style={{border:'2px solid black',borderBottom:'none',borderTop:'2px solid black',color:'#F7BD33',textAlign:'center'}}>
 <div className="col-12 wheeloflife" style={{background:'#FDF2D6',borderBottom:'2px solid black' }}>
 <span style={{fontWeight:'bold'}}>{heData.title}</span> 
 </div>
 <div  className='prompt' style={{textAlign:'justify'}}>
{heData.statement} </div>

</div>
<div className='row' style={{border:'2px solid black',color:'#5DB53C',borderBottom:'none',borderTop:'2px solid black',textAlign:'center'}}>
 <div className="col-12 wheeloflife" style={{background:'#CEE8C4',borderBottom:'2px solid black' }}>
 <span style={{fontWeight:'bold'}}>{weData.title}</span> 
 </div>
 <div  className='prompt' style={{textAlign:'justify'}}>
{weData.statement} </div>

</div>
<div className='row' style={{border:'2px solid black',borderBottom:'none',borderTop:'2px solid black',color:'#E22246',textAlign:'center'}}>
 <div className="col-12 wheeloflife" style={{background:'#F7C5C7',borderBottom:'2px solid black' }}>
 <span style={{fontWeight:'bold'}}>{woeData.title} </span>
 </div>
 <div  className='prompt' style={{textAlign:'justify'}}>
{woeData.statement} </div>

</div>
<div className='row' style={{border:'2px solid black',color:'#3D3D3C',textAlign:'center'}}>
 <div className="col-12 wheeloflife" style={{background:'#C4C4C4',borderBottom:'2px solid black' }}>
 <span style={{fontWeight:'bold'}}>{saData.title}</span> 
 </div>
 <div  className='prompt' style={{textAlign:'justify'}}>
{saData.statement} </div>

</div>
 
</div>

  </div>
 </div>


 
</div>
 

</div>
<div className='footerspeical fleering'>
<div className='' style={{color:'white'}}>
HUMAN DESIGN EDUCATION
</div>
<div className='' style={{color:'white'}}>
PAGE 05</div>
</div>
                {/* </div> */}
                 
            </div>
                 {/* Add any content for the second page below */}
                <div style={{  textAlign: "center", fontSize: "18px", color: "#333" }}>
                {/* <h1>pageee</h1> */}

                </div>
                 
            </div>
            
            <div style={{  position: "relative", height: "100%" }}>
            <div style={{   position: "relative", height: "100%" }}>
            <img src={top} style={{zIndex:'0',width:"100%",height:'100%',zIndex:0, position: "relative", left: "-12px", top: "-6px"}} alt="Description of Image" className="full-image"  />


                 {/* <div style={{  textAlign: "center", fontSize: "18px", color: "#333" }}> */}
                
<div  style={{marginTop:"-112px",padding:'29px',height:'820px',background:'white',overflow:'hidden'}} >
<div style={{zIndex:'9999',position:'relative'}}>
 {/* <br></br> */}
 <div className=''>
 <h1 style={{wordSpacing:'4px',textAlign:'center', color:'black'  }}>
Assessment Matrix
    </h1>
  <div className='black  ' style={{ textAlign: "justify",
  marginTop: "-19px",
  zIndex: 999999,
  width: "649px",
  position: "relative",
  top: "26px",
  left: "39px",
  wordSpacing: "6px",}}>
  <jam>
  The result of the Assessment Matrix is best understood when you look at the report
which is drawn from this matrix to indicate the aptitude, behaviour, possible
professions and careers and the potential language of expertise that exist.

  </jam>
  </div>
 </div>
 
 {/* { dataPoints[0]?.y>0.30?
 <ScatterPlot />
 :
      <Plot1/>}   */}

<Matrix/>
 {/* <Plot1/> */}

 
</div>
 

</div>
<div className='footerspeical fleering'>
<div className='' style={{color:'white'}}>
HUMAN DESIGN EDUCATION
</div>
<div className='' style={{color:'white'}}>
PAGE 06</div>
</div>
                {/* </div> */}
                 
            </div>


                 {/* Add any content for the second page below */}
                <div style={{  textAlign: "center", fontSize: "18px", color: "#333" }}>
                {/* <h1>pageee</h1> */}

                </div>
                 
            </div>

            <div style={{  position: "relative", height: "100%" }}>
            <div style={{   position: "relative", height: "100%" }}>
            <img src={top} style={{zIndex:'0',width:"100%",height:'100%',zIndex:0, position: "relative", left: "-12px", top: "-6px"}} alt="Description of Image" className="full-image"  />


                 {/* <div style={{  textAlign: "center", fontSize: "18px", color: "#333" }}> */}
                
<div  style={{marginTop:"-112px",padding:'29px',height:'820px',background:'white'}} >
<div className=''>
 <h1 style={{wordSpacing:'4px',textAlign:'center', color:'black',position:'relative',top:'-10px'   }}>
 Report
     </h1>
  <div style={{padding:'30px',zIndex:'99999',position:"relative"}}>
 
  <div  style={{marginLeft:' -13px',marginTop:'-20px',color:'#3E73D2'}}>
<div className='row' style={{border:'3px solid 3E73D2',textAlign:'center'}}>
  <div className='col-3 colspacing bolding'  style={{border:'3px solid #3E73D2',background:'#C5D5F1',borderRight:'none'}}>
Name
  </div>
  <div className='col colspacing texttranscap' style={{border:'3px solid #3E73D2',textAlign:'left',textTransform:'capitalize',fontWeight:'600'}}>
{userdata.first_name}&nbsp;{userdata.last_name}
</div>
</div>
<br></br>
<div className='row' style={{border:'3px solid #E22246',color:'#E22246',textAlign:'center'}}>
 <div className="col-12 wheeloflife" style={{background:'#F7C5C7',borderBottom:'3px solid #E22246' }}>
 <span style={{fontWeight:'bold'}}>Wheel Of Life </span>(Refer to Appendix 1)
 </div>
<div className='col-12'>
<div className="row" style={{    lineHeight: "19px",borderBottom:'1px solid '}}>
<div className="col-2 bold">
 Relationships
 </div>
 <div className="col-2 bold">
 Health
 </div>
 <div className="col-2 bold">
 Wealth
 </div>
 <div className="col-2 bold">
 Work & Education
 </div>
 <div className="col-2 bold">
 Self Awareness
 </div>
 <div className="col-2 bold" style={{border:'none'}}>
 Average
 </div>
</div>
<div className="row" style={{    lineHeight: "19px",}}>
<div className="col-2 bold">
<span className="fontweightnormal">{data1[0]}</span>
</div>
 <div className="col-2 bold">
 <span className="fontweightnormal">{data1[1]}</span>

 </div>
 <div className="col-2 bold">
 <span className="fontweightnormal">{data1[2]}</span>
 </div>
 <div className="col-2 bold">
 <span className="fontweightnormal">{data1[3]}</span>
 </div>
 <div className="col-2 bold">
 <span className="fontweightnormal">{data1[4]}</span>
 </div>
 <div className="col-2 bold" style={{border:'none',background:"#F7C5C7"}}>
 <span className="" style={{fontWeight:'bold'}}>{data1[5]}</span>
 
 </div>
</div>
</div>


</div>


<br></br>
<div className='row' style={{border:'3px solid #FFBD16',color:'#FFBD16',textAlign:'center'}}>
 <div className="col-12 wheeloflife" style={{background:'#FDF2D6',borderBottom:'3px solid #FFBD16',textAlign:'center' }}>
 <span style={{fontWeight:'bold'}}>Assessment Matrix (Interpretation)  </span>(Refer to Appendix 2.1)
 </div>
 <div className="col-2 bold">
 Aptitude
 </div>
 <div className="col-2  bold">
 Behaviour
 </div>
 <div className="col bold">
 Possible Professions & Careers
 </div>
 <div className="col-3  bold"   style={{borderRight:'none'}}>
 Language of Being
  </div>


<div className="col-12" style={{borderTop:'1px solid '}}>
<div className="row">
<div className="col-2 boldheight">
<span className='fontweightnormal'>{findBlockName(dataPoints[0]?.x,dataPoints[0]?.y)}</span>
 </div>
 <div className="col-2 boldheight">
 <span className='fontweightnormal'>{dataneed[0]?.Behaviour}</span>
 </div>
 <div className="col  boldheight">
 <span className='fontweightnormal'>{dataneed[0]?.Careers}</span>
 </div>
 <div className="col-3 boldheight"  style={{borderRight:'none'}}>
 <span className='fontweightnormal' >{dataneed[0]?.Language_of_Being}</span>
 </div>

</div>
</div>
</div>
<br></br>
{/* <div className="container my-4"> */}

<div
  className="row"
  style={{
    border: "3px solid #45B43B",
    color: "#45B43B",
    textAlign: "center",
  }}
>
  {/* Header Section */}
  <div
    className="col-12"
    style={{
      background: "#CEE8C4",
      borderBottom: "3px solid #45B43B",
      fontWeight: "bold",
      padding: "10px",
    }}
  >
    <span>Pathway for Development</span> (Refer to Appendix 3)
  </div>

  {/* Subheader Section */}
  <div
    className="col-2"
    style={{
      fontWeight: "bold",
      borderRight: "2px solid #45B43B",
      borderBottom: "1px solid #45B43B",
      padding: "15px",
    }}
  >
    Average Score
  </div>
  <div
    className="col-3"
    style={{
      fontWeight: "bold",
      borderRight: "2px solid #45B43B",
      borderBottom: "1px solid #45B43B",
      // padding:'9px'
    }}
  >
<span  >
Pathway for Development in Human Design Education

</span>
  </div>
  <div
    className="col"
    style={{
      fontWeight: "bold",
      borderBottom: "1px solid #45B43B",
      padding: "10px",
    }}
  >
    <div className="row" style={{ height: "48px", padding: "2px" }}>
      <div
        className="col-12"
        style={{
          paddingBottom: "5px",
          borderBottom: "1px solid #45B43B",
          fontWeight: "bold",
          height: "43px",
        }}
      >
        Pathway for development other than Human Design Education
      </div>
      <div
        className="col"
        style={{
          fontWeight: "bold",
          borderRight: "2px solid #45B43B",
          padding: "5px",
        }}
      >
        Books
      </div>
      <div
        className="col"
        style={{
          fontWeight: "bold",
          borderRight: "2px solid #45B43B",
          padding: "5px",
        }}
      >
        Movies (English)
      </div>
      <div
        className="col"
        style={{
          padding: "5px",
        }}
      >
        Movies (Hindi)
      </div>
    </div>
  </div>

  {/* Data Section */}
  <div
    className="col-12"
    style={{
      borderTop: "1px solid #45B43B",
    }}
  >
    <div className="row" style={{ lineHeight: "14px", fontSize: "12px" }}>
      <div
        className="col-2"
        style={{
          borderRight: "2px solid #45B43B",
          borderBottom: "1px solid #45B43B",
          padding: "10px",
          display: "flex",
          color: "black",
          alignItems: "center",
          justifyContent: "center", 
          overflow: "hidden", // Ensures content stays inside
          textOverflow: "ellipsis", // Adds ellipsis if text overflows
          // whiteSpace: "nowrap", // Keeps text on one line
        }}
      >
        <span style={{ fontSize: "12px", fontWeight: "normal" }}>
          {data1[5]}
        </span>
      </div>
      <div
        className="col-3"
        style={{
          borderRight: "2px solid #45B43B",
          borderBottom: "1px solid #45B43B",
          padding: "10px",
          display: "flex",
          color: "black",
          alignItems: "center",
          overflow: "hidden",
          textOverflow: "ellipsis",
          // whiteSpace: "nowrap",
        }}
      >
        <span
          style={{
            fontSize: "12px",
            fontWeight: "normal",
            color: "black",
          }}
        >
          {carrier?.Pathway}
        </span>
      </div>
      <div
        className="col"
        style={{
          borderBottom: "1px solid #45B43B",
        }}
      >
        <div className="row">
          <div
            className="col"
            style={{
              borderRight: "2px solid #45B43B",
              padding: "10px",
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              // whiteSpace: "nowrap",
            }}
          >
            <span
              style={{
                fontSize: "12px",
                color: "black",
                fontWeight: "normal",
              }}
            >
              {carrier?.Books}
            </span>
          </div>
          <div
            className="col"
            style={{
              borderRight: "2px solid #45B43B",
              padding: "10px",
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              // whiteSpace: "nowrap",
            }}
          >
            <span
              style={{
                fontSize: "12px",
                color: "black",
                fontWeight: "normal",
              }}
            >
              {carrier?.MoviesE}
            </span>
          </div>
          {/* <div
            className="col"
            style={{
              padding: "10px",
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              // whiteSpace: "nowrap",
            }}
          >
            <span
              style={{
                fontSize: "12px",
                color: "black",
                fontWeight: "normal",
              }}
            >
              {carrier?.MoviesH}
            </span>
          </div> */}
        <div
  className="col"
  style={{
    padding: "10px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  }}
>
  <span
    style={{
      fontSize: "12px",
      color: "black",
      fontWeight: "normal",
      wordBreak: "break-word", // Ensures words break correctly
    }}
  >
    {splitIntoTwoLines(carrier?.MoviesH || "")}
  </span>
</div>
        </div>
      </div>
    </div>
  </div>
</div>

{/* 
      <div className="row border text-center">
        <div className="col-2 border fw-bold align-middle d-flex justify-content-center align-items-center">
          Average Score
        </div>
        <div className="col-10 border fw-bold align-middle">
          Pathway for Development (Refer to Appendix 3)
          <div className="row">
            <div className="col-4 border fw-bold">
              Pathway for Development in Human Design Education
            </div>
            <div className="col-8 border fw-bold">
              Pathway for Development Other than Human Design Education
              <div className="row">
                <div className="col-4 border fw-bold">Books</div>
                <div className="col-4 border fw-bold">Movies (English)</div>
                <div className="col-4 border fw-bold">Movies (Hindi)</div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* Data Row */}
      {/* <div className="row border text-center">
        <div className="col-2 border d-flex justify-content-center align-items-center">
          0.69
        </div>
        <div className="col-10 border">
          <div className="row">
            <div className="col-4 border d-flex justify-content-center align-items-center">
              Program for Life (Classroom)
            </div>
            <div className="col-8 border">
              <div className="row">
                <div className="col-4 border">
                  The Power of Now by Eckhart Tolle, The Power of Positive
                  Thinking by Norman Vincent Peale
                </div>
                <div className="col-4 border">
                  Invictus, Good Will Hunting, Bruce Almighty, The Shawshank
                  Redemption
                </div>
                <div className="col-4 border">
                  Rocket Singh, Barfi, Panga, Border
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    {/* </div> */}
</div>

  </div>
 </div>

</div>
{/* <img
        src={footer}
        alt="Footer"
          className='footerspeical'
      
    /> */}
<div className='footerspeical fleering'>
<div className='' style={{color:'white'}}>
HUMAN DESIGN EDUCATION
</div>
<div className='' style={{color:'white'}}>
PAGE 07</div>
</div>



                {/* </div> */}
                 
            </div>
                 {/* Add any content for the second page below */}
                <div style={{  textAlign: "center", fontSize: "18px", color: "#333" }}>
                {/* <h1>pageee</h1> */}

                </div>
                 
            </div>

            <div style={{ position: "relative", width: "100%", height: "auto" }}>
        <img
            src={img8}
            alt="Page 2 image"
            style={{
                width: "100%",
                height: "auto",
                position: "relative",
                left: "0.49px",
                top: "0px",
                // marginTop: "1px",
            }}
        />
    </div>

    <div style={{ position: "relative", width: "100%", height: "auto" }}>
        <img
            src={img9}
            alt="Page 2 image"
            style={{
                width: "100%",
                height: "auto",
                position: "relative",
                left: "0.49px",
                top: "0px",
                // marginTop: "1px",
            }}
        />
    </div>

   

    <div style={{ position: "relative", width: "100%", height: "auto" }}>
        <img
            src={img10}
            alt="Page 2 image"
            style={{
                width: "100%",
                height: "auto",
                position: "relative",
                left: "0.49px",
                top: "0px",
                // marginTop: "1px",
            }}
        />
    </div>

    <div style={{ position: "relative", width: "100%", height: "auto" }}>
        <img
            src={img11}
            alt="Page 2 image"
            style={{
                width: "100%",
                height: "auto",
                position: "relative",
                left: "0.49px",
                top: "-1px",
                // marginTop: "1px",
            }}
        />
    </div>


    <div style={{ position: "relative", width: "100%", height: "auto" }}>
        <img
            src={img12}
            alt="Page 2 image"
            style={{
                width: "100%",
                height: "auto",
                position: "relative",
                left: "0.49px",
                top: "0px",
                // marginTop: "1px",
            }}
        />
    </div>

    <div style={{ position: "relative", width: "100%", height: "auto" }}>
        <img
            src={img13}
            alt="Page 2 image"
            style={{
                width: "100%",
                height: "auto",
                position: "relative",
                left: "0.49px",
                top: "-2.5px",
                // marginTop: "1px",
            }}
        />
    </div>

    <div style={{ position: "relative", width: "100%", height: "auto" }}>
        <img
            src={img14}
            alt="Page 2 image"
            style={{
                width: "100%",
                height: "auto",
                position: "relative",
                left: "0.49px",
                top: "0px",
                // marginTop: "1px",
            }}
        />
    </div>

    <div style={{ position: "relative", width: "100%", height: "auto" }}>
        <img
            src={img15}
            alt="Page 2 image"
            style={{
                width: "100%",
                height: "auto",
                position: "relative",
                left: "0.49px",
                top: "-2.5px",
                // marginTop: "1px",
            }}
        />
    </div>

    <div style={{ position: "relative", width: "100%", height: "auto",overflow:'hidden' }}>
        <img
            src={img16}
            alt="Page 2 image"
            style={{
                width: "100%",
                height: "auto",
                position: "relative",
                left: "0.49px",
                top: "0px",
                marginTop: '-8px',
            }}
        />
    </div>
</div>

          
            </div>
        
      
        </div>
 

</>

    );
};

export default Pdftest1;
