import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Don't forget to import axios if you're using it
import img1 from './5.png'
import Swal from 'sweetalert2';
import { jwtDecode } from "jwt-decode";
import { baseurlvmkp } from './Baseurl';

const SelfAww = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const selfAwarenessTopics = [
        { label: 'Prayers', field: 'prayers' },
        { label: 'Discipline', field: 'discipline' },
        { label: 'Meditation', field: 'meditation' },
        { label: 'Reading', field: 'reading' },
        { label: 'Music', field: 'music' },
        { label: 'Fashion', field: 'fashion' },
        { label: 'Travel', field: 'travel' },
        { label: 'Reflecting', field: 'reflecting' },
        { label: 'Creation', field: 'creation' },
        { label: 'Confidence', field: 'confidenece' }, // Fixed typo here
        { label: 'Values', field: 'values' },
        { label: 'Learnings', field: 'learnings' },
        { label: 'Miracles', field: 'miracles' },
        { label: 'Spirituality', field: 'spirituality' },
        { label: 'Devotion', field: 'devotion' },
        { label: 'Dreams', field: 'dreams' },
        { label: 'Future', field: 'future' },
        { label: 'Goals in Self Awareness', field: 'goalsInSelfAwareness' },
    ];
    
    // const selfAwarenessTopics = [
    //     "prayers",
    //     "discipline",
    //     "meditation",
    //     "reading",
    //     "music",
    //     "fashion",
    //     "travel",
    //     "reflecting",
    //     "creation",
    //     "confidenece",
    //     "values",
    //     "learnings",
    //     "miracles",
    //     "spirituality",
    //     "devotion",
    //     "dreams",
    //     "future",
    //     "goalsInSelfAwareness"
    // ];

    const initializeFields = () => {
     
     
         
        
 
 
        const initialFields = Array(18).fill().map((_, index) => {
            const config =  { selected: Array(4).fill(""), current: 0 };
            return { current: config.current, selected: config.selected };
        });
    
        return initialFields;
    };


    const [fieldsVisibility, setFieldsVisibility] = useState('');
    const [id,setId]=useState("")


    useEffect(() => {
        axios.get(`${baseurlvmkp}/exam/getexampending/${JSON.parse(localStorage.getItem('user')).details.id}` , {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        }).then((response) => {
            setId(response.data.id)

            axios.get(`${baseurlvmkp}/selfawareness/get/${(response.data.selfawarenessid)}` , {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            }).then((response) => {
                // console.log(response.data.map((item)=>item.questions));
               
                  
                 const cleanedData = Object.fromEntries(
                    Object.entries(response.data).map(([key, { selfAwarenessQuestions, name }]) => [key, { selfAwarenessQuestions, name }])
                );
                console.log(cleanedData)
                
                const cleanedData1 = Object.fromEntries(
                    Object.entries(cleanedData).map(([key, { selfAwarenessQuestions  }]) => [key, { selfAwarenessQuestions  }])
                );
               console.log(cleanedData1)
                  
               const allQuestionsWithKeys = Object.entries(cleanedData1).flatMap(([key, value]) => 
                Array.isArray(value.selfAwarenessQuestions) ? 
                value.selfAwarenessQuestions.map(question => ({ area: key, ...question })) : []
              );
              console.log(allQuestionsWithKeys)
    
              const mappedResults = allQuestionsWithKeys.map(question => {
                const { area, selfAwarenessAnswer } = question;
                const answerText = selfAwarenessAnswer.text;
            
                return {
                    area: area,
                    Applicable: answerText === 'Not Applicable' || answerText === 'Applicable' ? answerText : "",
                    Working: answerText === 'Not Working' || answerText === 'Working' ? answerText : "",
                    Critical: ['Not Critical', 'Somewhat Critical', 'Critical', 'Very Critical', 'Most Critical'].includes(answerText) ? answerText : "",
                    Future: answerText === 'Necessity' || answerText === 'Wish/Want/Desire' || answerText === 'Dream' || 
                            answerText === 'Hope' || answerText === 'Miracle' ? answerText : ""
                };
            });
            
            const uniqueData = mappedResults.reduce((acc, item) => {
                const existing = acc.find(entry => entry.area === item.area);
                
                if (existing) {
                  existing.Applicable = existing.Applicable || item.Applicable || '';
                  existing.Working = existing.Working || item.Working || '';
                  existing.Critical = existing.Critical || item.Critical || '';
                  existing.Future = existing.Future || item.Future || '';
                } else {
                  acc.push({
                    area: item.area,
                    Applicable: item.Applicable || '',
                    Working: item.Working || '',
                    Critical: item.Critical || '',
                    Future: item.Future || '',
                  });
                }
                
                return acc;
              }, []);
              
              // Step 2: Map to the desired structure
              const data = uniqueData.map(question => {
                const applicable = question.Applicable || '';
                const working = question.Working || '';
                const critical = question.Critical || '';
                const future = question.Future || '';
              
                // Create an array for selected values based on conditions
                const selectedArray = [
                  applicable  ,
                  working ,
                  critical  ,
                  future 
                ];
              
                // Count how many selected values are not default
                const currentCount = selectedArray.filter(value => value.length !==0 &&  value.length !==0 &&  value.length !==0 &&  value.length !==0).length-1;
              
                return {
                  selected: selectedArray,
                  current: currentCount  
                };
              });
          
              setFieldsVisibility(data.length === 0 ? initializeFields() : data);
    console.log(data)
            }).catch((error)=>{
    console.log(error)
    setFieldsVisibility(initializeFields())
            })

        }).catch((error)=>{
            console.log(error)
            setFieldsVisibility(initializeFields())
                    })

        // axios.get(`${baseurlvmkp}/exam/get` , {
        //     headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        // }).then((response) => {
        //     console.log(response.data)
        //     const userid=JSON.parse(localStorage.getItem('user')).details.id
        //     console.log(response.data.filter((item)=>item.userId==Number(userid)).map((item)=>item.id)[0])
        //     setId(response.data.filter((item)=>item.userId==Number(userid)).map((item)=>item.id)[0])

        // }).catch((error)=>{
        //     console.log(error)
        //              })




       
    }, [ ])
    // (Array(selfAwarenessTopics.length).fill({ current: 0, selected: Array(4).fill("") }))
     const [showErrors, setShowErrors] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const handleChange = (rowIndex, selectIndex, value) => {
        setFieldsVisibility(prev => {
            const updatedVisibility = prev.map((row, index) => {
                if (index === rowIndex) {
                    const newSelected = [...row.selected];
                    newSelected[selectIndex] = value;

                    const nextField = (value !== "Not Applicable" && value !== 'Select' && value !== 'Working') 
                        ? Math.min(row.current + 1, 3) 
                        : selectIndex;

                    return { current: nextField, selected: newSelected };  
                }
                return row;  
            });

            return updatedVisibility;  
        });
    };
    const token =localStorage.getItem('user');
    console.log( JSON.parse(token).details.id)
   
console.log(fieldsVisibility)
const handleSubmit = () => {
    const allRowsFilled = fieldsVisibility.map((row, index1) => 

        fieldsVisibility.filter((item,index) => index == index1).map((item)=>item.selected)[0].filter((item,index3)=>index3==row.current).every((item)=>item.length!==0)


        );
        console.log(allRowsFilled.filter((item)=>item==false).length)
       
    console.log(fieldsVisibility)
         setShowErrors(!(allRowsFilled.filter((item)=>item==false).length==0?true:false));   
    
        if (allRowsFilled.filter((item)=>item==false).length==0) {
        const result = {
            name: "Self Awareness",
            exam:[{
                "id":Number(id)
                }],
            ...Object.fromEntries(
                selfAwarenessTopics.map((label, index) => {
                    const selectedValues = fieldsVisibility[index].selected || [];
                    const notApplicableIndex = selectedValues.indexOf('Not Applicable');
                    const workingIndex = selectedValues.indexOf('Working');

                    const updatedAnswers = selectedValues.map((val, i) => {
                        if ((i > notApplicableIndex && notApplicableIndex !== -1) || (i > workingIndex && workingIndex !== -1)) {
                            return "";
                        }
                        return val;
                    });

                    return [
                        label.field,
                        {
                            name: label.field,
                            selfAwarenessQuestions: [
                                { text: "Is this applicable?", selfAwarenessQuestionOptions: [{ text: "Applicable" }, { text: "Not Applicable" }], selfAwarenessAnswer: { text: updatedAnswers[0] } ,   area: label.label},
                                { text: "Is this working?", selfAwarenessQuestionOptions: [{ text: "Working" }, { text: "Not Working" }], selfAwarenessAnswer: { text: updatedAnswers[1] },   area: label.label },
                                { text: "How Critical Is This?", selfAwarenessQuestionOptions: [{ text: "Not Critical" }, { text: "Somewhat Critical" }, { text: "Critical" }, { text: "Very Critical" }, { text: "Most Critical" }], selfAwarenessAnswer: { text: updatedAnswers[2] },   area: label.label },
                                { text: "How do you see this in the future?", selfAwarenessQuestionOptions: [{ text: "Necessity" }, { text: "Wish/Want/Desire" }, { text: "Dream" }, { text: "Hope" }, { text: "Miracle" }], selfAwarenessAnswer: { text: updatedAnswers[3] },   area: label.label }
                            ]
                        }
                    ];
                })
            )
        };

        console.log(result);
        Swal.fire({
            title: "Are You Sure You Want To Submit?",
            text: "This Will Generate Report",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Submit It!"
          }).then((resultConfirm) => {
            if (resultConfirm.isConfirmed) {
                setIsLoading(true);
                axios.post(`${baseurlvmkp}/selfawareness/post`, result, {
                    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                }) .then(response => {
                    console.log(response.status, response.data.token);
                    // Uncomment for navigation after successful submission
                    Swal.fire({
                        title: "Submitted!",
                        text: "PAT Submitted Successfully.",
                        icon: "success"
                      });
                     
                                   navigate(`/Pdftest1/${JSON.parse(token).details.id}`);
                
                })
                .catch(error => {
                    console.error("Error submitting form:", error);
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Failed to submit the form. Please try again.",
                        footer: '<a href="#">Why do I have this issue?</a>'
                      });
                    //alert("Failed to submit the form. Please try again.");
                }).finally(() => {
                    setIsLoading(false); 
                  });
            
            }
          })
        // axios.post("http://13.201.40.47:8080/vmkp/api/selfawareness/post", result, {
        //     headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        // })
        // .then(response => {
        //     console.log(response.status, response.data.token);
        //     // Uncomment for navigation after successful submission
        //     navigate('/result');
        // })
        // .catch(error => {
        //     console.error("Error submitting form:", error);
        //     alert("Failed to submit the form. Please try again.");
        // });
    } else {
        alert("Please fill out all fields for each row.");
    }
};



    // const handleSubmit = () => {
    //     const allRowsFilled = fieldsVisibility.map((row, index1) => 
 
    //         fieldsVisibility.filter((item,index) => index == index1).map((item)=>item.selected)[0].filter((item,index3)=>index3==row.current).every((item)=>item.length!==0)
    
    
    //         );
    //         console.log(allRowsFilled.filter((item)=>item==false).length)
           
    //     console.log(fieldsVisibility)
    //          setShowErrors(!(allRowsFilled.filter((item)=>item==false).length==0?true:false));   
        
    //         if (allRowsFilled.filter((item)=>item==false).length==0) {
    //         const result = {
    //             name: "Self Awareness",
    //             exam:[{
    //                 "id":Number(id)
    //                 }],
    //             ...Object.fromEntries(
    //                 selfAwarenessTopics.map((label, index) => {
    //                     const selectedValues = fieldsVisibility[index].selected || [];
    //                     const notApplicableIndex = selectedValues.indexOf('Not Applicable');
    //                     const workingIndex = selectedValues.indexOf('Working');

    //                     const updatedAnswers = selectedValues.map((val, i) => {
    //                         if ((i > notApplicableIndex && notApplicableIndex !== -1) || (i > workingIndex && workingIndex !== -1)) {
    //                             return "";
    //                         }
    //                         return val;
    //                     });

    //                     return [
    //                         label.field,
    //                         {
    //                             name: label.field,
    //                             selfAwarenessQuestions: [
    //                                 { text: "Is this applicable?", selfAwarenessQuestionOptions: [{ text: "Applicable" }, { text: "Not Applicable" }], selfAwarenessAnswer: { text: updatedAnswers[0] } },
    //                                 { text: "Is this working?", selfAwarenessQuestionOptions: [{ text: "Working" }, { text: "Not Working" }], selfAwarenessAnswer: { text: updatedAnswers[1] } },
    //                                 { text: "How Critical Is This?", selfAwarenessQuestionOptions: [{ text: "Not Critical" }, { text: "Somewhat Critical" }, { text: "Critical" }, { text: "Very Critical" }, { text: "Most Critical" }], selfAwarenessAnswer: { text: updatedAnswers[2] } },
    //                                 { text: "How do you see this in the future?", selfAwarenessQuestionOptions: [{ text: "Necessity" }, { text: "Wish/Want/Desire" }, { text: "Dream" }, { text: "Hope" }, { text: "Miracle" }], selfAwarenessAnswer: { text: updatedAnswers[3] } }
    //                             ]
    //                         }
    //                     ];
    //                 })
    //             )
    //         };

    //         console.log(result,'resultresultresultresultresult');
    //         axios.post("http://13.201.40.47:8080/vmkp/api/selfawareness/post", result, {
    //             headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    //         })
    //         .then(response => {
    //             console.log(response.status, response.data.token);
    //             // Uncomment for navigation after successful submission
    //             navigate('/result');
    //         })
    //         .catch(error => {
    //             console.error("Error submitting form:", error);
    //             alert("Failed to submit the form. Please try again.");
    //         });
    //     } else {
    //         alert("Please fill out all fields for each row.");
    //     }
    // };
    const anchors = document.querySelectorAll('select');

    anchors.forEach((element) => {
      console.log(element.length);
   });
    useEffect(() => {

   


        const handleScroll = () => {
            setIsScrolled(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);
        window.scrollTo(0, 0); // Scroll to top when component mounts

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='selftest'>  <br></br>
        <div className='maxcenter '  >
        <div className='rs11' style={{display:'flex',background:'white',justifyContent:'center',flexDirection:'row',gap:50}}>
        <h1 className='black fontsize'>Relationships</h1>
        <hr/>
        <h1 className='black fontsize'>Health</h1>
        <hr/>
        <h1 className='black fontsize'>Work and Education</h1>
        
        <hr/>
        <h1 className='black fontsize'>Wealth</h1>
        <hr/>
        <h1 className='black fontsize '  style={{color:'#3D3D3D'}}>Self Awareness</h1>

        </div>
         </div>
         <br></br>
            <div className={`display ${isScrolled ? 'blockdisplay' : 'displaynone'}`}>
                <p className='black jhk'>
                    <div className='middle'>
                        <div className='cont'>
                            <div>
                                <div className='row tesfier'>
                                    <div className='col black'></div>
                                    <div className='col black question'>Is this area applicable for you?</div>
                                    <div className='col black question'>Is this area working for you?</div>
                                    <div className='col black question'>How critical is this area for you?</div>
                                    <div className='col black question'>How do you see your near future in this area?</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </p>
            </div>
            <div className='middle'>
                <div className='cont'>
                <img src={img1} className='imglogo' style={{width:'50px'}} alt="Description of the image" />

                    <h1 className='black   tyttyt' style={{ color: '#3D3D3D' }}>Self Awareness</h1>
                    <p className='black   Personal'>
                        This part of the Personal Assessment Test is created for you to reflect on your Self Awareness.
                    </p>

                    <br />
                    <div className={`display ${isScrolled ? 'displaynone' : 'blockdisplay'}`}>
                        <div className='row'>
                            <div className='col black'></div>
                            <div className='col black question'>Is this area applicable for you?</div>
                            <div className='col black question'>Is this area working for you?</div>
                            <div className='col black question'>How critical is this area for you?</div>
                            <div className='col black question'>How do you see your near future in this area?</div>
                        </div>
                    </div>

                    {selfAwarenessTopics.map((label, index) => (
                        <div className='row pading12' key={index}>
                            <div className='col black fontweight500 d-flex justify-content-center align-items-center'>{label.label}</div>
                            <div className='col black'>
                                {(fieldsVisibility[index]?.current >= 0) && (
                                    <select
                                        className='form-select sw'
                                        onChange={(e) => handleChange(index, 0, e.target.value)}
                                        value={fieldsVisibility[index].selected[0] || ""}

                                        defaultValue=""
                                    >
                                        <option disabled value="">--Select--</option>
                                        <option>Not Applicable</option>
                                        <option>Applicable</option>
                                    </select>
                                )}
                            </div>
                            <div className='col black'>
                                {(fieldsVisibility[index]?.current >= 1) && (
                                    <select
                                        className='form-select sw'
                                        value={fieldsVisibility[index].selected[1] || ""}

                                        onChange={(e) => handleChange(index, 1, e.target.value)}
                                        defaultValue=""
                                    >
                                        <option disabled value="">--Select--</option>
                                        <option>Not Working</option>
                                        <option value="Working">Working</option>
                                    </select>
                                )}
                            </div>
                            <div className='col black'>
                                {(fieldsVisibility[index]?.current >= 2) && (
                                    <select
                                        className='form-select sw'
                                        value={fieldsVisibility[index].selected[2] || ""}

                                        onChange={(e) => handleChange(index, 2, e.target.value)}
                                        defaultValue=""
                                    >
                                        <option disabled value="">--Select--</option>
                                        <option>Not Critical</option>
                                        <option>Somewhat Critical</option>
                                        <option>Critical</option>
                                        <option>Very Critical</option>
                                        <option>Most Critical</option>
                                    </select>
                                )}
                            </div>
                            <div className='col black'>
                                {(fieldsVisibility[index]?.current >= 3) && (
                                    <select
                                        className='form-select sw'
                                        onChange={(e) => handleChange(index, 3, e.target.value)}
                                        value={fieldsVisibility[index].selected[3] || ""}

                                        defaultValue=""
                                    >
                                        <option disabled value="">--Select--</option>
                                        <option>Necessity</option>
                                        <option>Wish/Want/Desire</option>
                                        <option>Dream</option>
                                        <option>Hope</option>
                                        <option>Miracle</option>
                                    </select>
                                )}
                            </div>
                        </div>
                    ))}
                    <br />
                    <div className='row fotbuttons'>
                       
                      
                            <button className='btn btn-primary bigger' onClick={()=>{navigate(-1)}} >Back</button>
                      
                            {isLoading?   <button className='btn btn-primary bigger' style={{background:'white'}}  > 
                            <svg viewBox="25 25 50 50" class="spinner-container" style={{width:'21px'}}>
  <circle cx="50" cy="50" r="20" class="spinner-loader"></circle>
</svg>

                            </button>:<button className='btn btn-primary bigger'  onClick={handleSubmit}> 
                      Next

                            </button>}
                            {/* <button className='btn btn-primary bigger' style={{backgroundColor:'#3D3D3D'}} onClick={handleSubmit}>Submit</button> */}
                       
                    </div>
                    <br></br>
                    <div className='row'>
                       
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SelfAww;
