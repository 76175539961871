import React from 'react';

const Grid = () => {
  const rows = [
    { label: '0.81-0.9', values: ['0-0.1', '0.11-0.2', '0.21-0.3', '0.31-0.4', '0.41-0.5', '0.51-0.6', '0.61-0.7', '0.71-0.8', '0.71-0.81'] },
    // ... other rows
  ];

  return (
    <div className="grid-container">
      <div className="row header">
        <div className="cell">OVERALL</div>
        <div className="cell">RANGES</div>
        {rows[0].values.map((value, index) => (
          <div key={index} className="cell">{value}</div>
        ))}
      </div>
      {rows.map((row, index) => (
        <div key={index} className="row">
          <div className="cell">{row.label}</div>
          <div className="cell"></div>
          {row.values.map((value, index) => (
            <div key={index} className="cell"></div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Grid;