import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { RxCross2 } from "react-icons/rx";
import axios from 'axios';
import { FaBuilding, FaHandshake, FaQuoteLeft } from "react-icons/fa";
import { FaQuoteRight } from "react-icons/fa";
import { Icon } from 'react-icons'


import { FaHandsHelping } from "react-icons/fa";
import { IoIosHappy } from "react-icons/io";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import left from './Blue Waves Surfing Club Logo.png';
import img1 from "../Files/Subjects/1.png";
import img2 from "../Files/Subjects/2.png";
import img3 from "../Files/Subjects/3.png";
import img4 from "../Files/Subjects/4.png";
import img5 from "../Files/Subjects/5.png";
import img6 from "../Files/Subjects/fifty20.PNG";
import img7 from "../Files/Subjects/patelreview.PNG";
import Select from 'react-select';
import bg from './img1.jpg'

// patelreview
import { Card, Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import { type } from '@testing-library/user-event/dist/type';
import zIndex from '@mui/material/styles/zIndex';
import AnimatedCounter from '../AnimatedCounter';
import { FormControlLabel } from '@mui/material';
import { ClassNames } from '@emotion/react';
import { baseurlvmkp } from './Subjects/Baseurl';

const Home = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  const [visible, setvisible] = useState(false);

  const [date, setDate] = useState(new Date());
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    birth_date: '',
    gender: '',
    address: '',
    marital_status: '',
    occupation: '',
    email: '',
    password: '',
    phone_no: '',
    country: "",
    states: "",
    city: ""
  });
  const areas = [
    { title: 'Relationships', icon: img1, description: ' ' },
    { title: 'Health', icon: img3, description: ' ' },
    { title: 'Wealth', icon: img4, description: ' ' },
    { title: 'Work/Education', icon: img2, description: ' ' },
    { title: 'Self-Awareness', icon: img5, description: '' },
  ];
  const genderOptions = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
   ];

  const maritalStatusOptions = [
    { label: "Single", value: "Single" },
    { label: "Married", value: "Married" },
    { label: "Divorced", value: "Divorced" },
    { label: "Widowed", value: "Widowed" },

  ]
  const [occupation, setOccupation] = useState("");

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [passcode, setpasscode] = useState("");
  const [email, setemail] = useState("");
  const [countryValue, setCountryValue] = useState();
  const [stateValue, setStateValue] = useState();
  const [cityValue, setCityValue] = useState();
  const [error, setError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [genderValue, setGenderValue] = useState();  
  const [errorGender, setErrorGender] = useState(false); 
const [errorCountry, setErrorCountry] = useState(false);
const [errorState, setErrorState] = useState(false);
const [errorCity, setErrorCity] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    fetch(`${baseurlvmkp}/country/get`)
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.map((item) => ({ label: item.name, value: item.id })))
      })
      .catch((error) => console.error('Error fetching countries:', error));
  }, []);

  const fetchStates = (countryId) => {
    fetch(`${baseurlvmkp}/state/get/${countryId}`)
      .then((response) => response.json())
      .then((data) => setStates(data.map((item) => ({ label: item.name, value: item.id }))))
      .catch((error) => console.error('Error fetching states:', error));
  };

  const fetchCities = (stateId) => {
    fetch(`${baseurlvmkp}/city/get/${stateId}`)
      .then((response) => response.json())
      .then((data) => setCities(data.map((item) => ({ label: item.name, value: item.id }))))
      .catch((error) => console.error('Error fetching cities:', error));
  };

  const handlelogin = () => {
    console.log("first")
    navigate('/LOgionnew')
  }

  const handleChange = (e, name) => {



    if (name === 'country') {
      const selectedCountry = countries.find(country => country.id === e.value);
      console.log(e.value);
      setErrorCountry(false);  

      console.log(selectedCountry);

      setFormData((prevData) => ({
        ...prevData,
        country: { id: e.value },
        states: { id: '' },
        city: { id: '', name: '' },
      }));
      fetchStates(e.value);
      setStates([]);
      setCities([]);
      setCountryValue(e)
      setStateValue("");
      setCityValue("");
    } else if (name === 'states') {
      setErrorState(false);
      const selectedState = states.find(state => state.id === e.value);
      setFormData((prevData) => ({
        ...prevData,
        states: { id: e.value },
        city: { id: '', name: '' },
      }));
      setStateValue(e)
      setCities([]);
      fetchCities(e.value);
      setCityValue("")
    } else if (name === 'city') {
      const selectedCity = cities.find(city => city.name === e.value);
      setErrorCity(false);
      setFormData((prevData) => ({
        ...prevData,
        city: { id: e.value, name: e.label },
      }));
      setCityValue(e)
    } else if (name === 'gender') {
      const selectedCity = cities.find(city => city.name === e.value);
      setErrorGender(false);
      setFormData((prevData) => ({
        ...prevData,
        gender: e.value
      }));
    }else if (name === 'occupation'){
      // setErrorOccupation(false);
      setFormData((prevData) => ({
        ...prevData,
        occupation: e.value
      }));
    }
    
    else if (name === 'marital_status') {
      setErrorMaritalStatus(false); 
      setFormData((prevData) => ({
        ...prevData,
        marital_status: e.value
      }));
    } else {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handleChangeDOB = (e, name) => {

    if (name === 'year') {

      setSelectedYear(e);
      setSelectedDate('');
      setSelectedMonth('');
      const bday_in_milliseconds = selectedYear;
    
      
      // let now = new Date().getTime(); //current time in milliseconds
      // if (now - bday_in_milliseconds > 567648000000) { //567648000000 is 18 years in milliseconds
      //  alert('Age 18 years');
      // }
      // else {
      //   alert('Age less than 18 years');
      //   // under 18
      // }

    }
    else if (name === 'month') {

      setSelectedMonth(e);
      setSelectedDate('');

    }
    else if (name === 'date') {

      setSelectedDate(e);

    }

    setDobError(false);  

  }
  const [maritalStatusValue, setMaritalStatusValue] = useState(null);  
  const [errorMaritalStatus, setErrorMaritalStatus] = useState(false); 

  const handlegenChange = (event, fieldName) => {
    // Update the value of the field based on the selected option
    const value = event.target.value;
  
    // Update the state for the field (e.g., marital status, gender, etc.)
    if (fieldName === 'marital_status') {
      setMaritalStatusValue(value);
    } else if (fieldName === 'gender') {
      setGenderValue(value);
    } else if (fieldName === 'country') {
      setCountryValue(value);
    } else if (fieldName === 'states') {
      setStateValue(value);
    } else if (fieldName === 'city') {
      setCityValue(value);
    }
    
    // Reset the error state for the field (i.e., make the error disappear when the user selects a value)
    if (fieldName === 'marital_status') {
      setErrorMaritalStatus(false);
    } else if (fieldName === 'gender') {
      setErrorGender(false);
    } else if (fieldName === 'country') {
      setErrorCountry(false);
    } else if (fieldName === 'states') {
      setErrorState(false);
    } else if (fieldName === 'city') {
      setErrorCity(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorCountry(false);
    setErrorState(false);
    setErrorCity(false);
    setErrorGender(false);
    setErrorMaritalStatus(false);

   if (!genderValue) {
    setErrorGender(true);  
  }
     if (!countryValue) {
      setErrorCountry(true);  
    }
    if (!stateValue) {
      setErrorState(true); 
    }
    if (!cityValue) {
      setErrorCity(true); 
    }
     if (!occupation) {
      setError(true);
    }
      if (!selectedYear || !selectedMonth || !selectedDate) {
      setDobError(true);
    }

     if (!maritalStatusValue) {
      setErrorMaritalStatus(true);  
    }
console.log(maritalStatusValue,genderValue)
if(occupation && selectedYear && selectedMonth&& maritalStatusValue && selectedDate&&countryValue && stateValue && cityValue&&genderValue){
  setError(false);
  setDobError(false);
    formData.birth_date = selectedYear.value + '-' + 
    String(selectedMonth.value).padStart(2, '0') + '-' + 
    String(selectedDate.value).padStart(2, '0');
console.log(formData);
const birthDate = new Date(formData.birth_date);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

     if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
    }

     formData.age = age;

    console.log(formData);

    axios.post(`${baseurlvmkp}/vmkp/post/customer`, formData)
      .then((response) => {
        closeModal();
        console.log('Success:', response.data);
        Swal.fire({
          title: 'Success!',
          text: 'Form submitted successfully!',
          icon: 'success',
          confirmButtonText: 'OK',
        });
        navigate('/LOgionnew')
      })

      .catch((error) => {
        console.error('Error:', error);
        Swal.fire({
          title: 'Error!',
          text: 'There was an error submitting the form.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      });
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleChangelogin = async () => {
    try {
      const response = await axios.post(`${baseurlvmkp}/post/customer/signin`, {
        email,
        password: passcode
      });

      const { token, details } = response.data;

      localStorage.setItem('token', token);
      // localStorage.setItem('details', JSON.parse(response.data)); 
      localStorage.setItem("user", JSON.stringify(response.data));


      console.log('Login successful:', response.data);

      navigate('/payment');
      console.log(response.data)
    } catch (error) {
      console.error('There was an error logging in:', error);
      Swal.fire({
        title: 'Login Failed',
        text: 'Invalid email or password.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };
  const [isSecondModalOpen, setSecondModalOpen] = useState(false);

  const openSecondModal = () => setSecondModalOpen(true);
  const closeSecondModal = () => setSecondModalOpen(false);
  const [isVisible, setIsVisible] = useState(true); // State to manage visibility

  const toggleVisibility = () => {
    setIsVisible(prevState => !prevState); // Toggle the state
  };

  console.log(formData);
  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  };
  const yr = date.getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => yr - i).map((year) => ({ value: year, label: year }));
  
  const months = [
    { value: 1, label: 'Jan' },
    { value: 2, label: 'Feb' },
    { value: 3, label: 'Mar' },
    { value: 4, label: 'Apr' },
    { value: 5, label: 'May' },
    { value: 6, label: 'Jun' },
    { value: 7, label: 'Jul' },
    { value: 8, label: 'Aug' },
    { value: 9, label: 'Sep' },
    { value: 10, label: 'Oct' },
    { value: 11, label: 'Nov' },
    { value: 12, label: 'Dec' }
  ]
  console.log(months, 'months');

  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [fullDate, setFullDate] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableDays, setAvailableDays] = useState([]);
  const getDaysInMonth = (year, month) => {
    if (month === null || year === null) return [];

    const daysInMonth = {
      1: 31, // January
      2: isLeapYear(year) ? 29 : 28, // February
      3: 31, // March
      4: 30, // April
      5: 31, // May
      6: 30, // June
      7: 31, // July
      8: 31, // August
      9: 30, // September
      10: 31, // October
      11: 30, // November
      12: 31 // December
    };

    return Array.from({ length: daysInMonth[month] }, (_, i) => ({
      value: i + 1,
      label: i + 1
    }));
  };
  useEffect(() => {
    const days = getDaysInMonth(selectedYear?.value, selectedMonth?.value);
    setAvailableDays(days);
    setSelectedDate(null); // Reset the selected date when month/year changes
  }, [selectedYear, selectedMonth]);
  const validateDate = (year, month, date) => {
    if (!year || !month || !date) {
      return 'Year, month, and date must be selected.';
    }
  }
  const scrollToDOBSection = () => {
    const modalContainer = document.querySelector('.container.gyty');
    if (modalContainer) {
      modalContainer.scrollTo({
        top: modalContainer.scrollHeight,
        behavior: 'smooth',
      });
    }
  };
  
  console.log(selectedYear, selectedMonth, selectedDate);
  const handleChangeOccupation = (e) => {
    setOccupation(e.value);
    setError(false); 
  };
  // const navigate = useNavigate();

   
  return (
    <><div className='flexer  nono' style={{ background: 'white', height: '56px' }}>
      {/* <h1>wds</h1>  */}
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className=''
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
       
        <div className='container   gyty' style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px' }}>
  
       <Button variant="outlined" color="secondary" onClick={closeModal} style={{ flex: '1' } } id='coloseBtn'><RxCross2 /></Button>

     
          <Typography id="modal-title" className='register' variant="h4" component="h2" style={{ color: '#07356D', textAlign: 'center' }}>
            Register
          </Typography>
        
          <br />
          <div className='card '>
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '15px', padding: '20px', width: '100%', maxWidth: '800px', margin: '0 auto' }}>
              {/* <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>User Information</h2> */}

              <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="col-md-6" style={{ flex: '1', marginRight: '10px' }}>
                  <TextField variant="outlined" label="First Name" name="first_name" onChange={handleChange} required fullWidth />
                </div>
                <div className="col-md-6" style={{ flex: '1' }}>
                  <TextField variant="outlined" label="Last Name" name="last_name" onChange={handleChange} required fullWidth />
                </div>
              </div>

              <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="col-md-6" style={{ flex: '1', marginRight: '10px' }}>
                  <TextField variant="outlined" label="Phone Number" name="phone_no" onChange={handleChange}  required fullWidth type="number"  />
                </div>
                <div className="col-md-6" style={{ zIndex: 40, flex: '1' }}>
                  <FormControl variant="outlined" required fullWidth>
                    <Select
                      style={{ padding: "8px 0px" }}
                      options={genderOptions}
                      name="gender"
                      onChange={(e) =>{ handleChange(e, "gender");setGenderValue(e,'gender'); setErrorGender(false);}}
                      placeholder="Select Gender"
                    />
    {errorGender && <p style={{ color: 'red' }}>Gender is required!</p>} {/* Error message if gender is not selected */}


                  </FormControl>
                </div>


              </div>

              <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>

                <div className="col-md-6" style={{ flex: '1', marginRight: '10px' }}>
                  <TextField variant="outlined" label="Email Id" name="email" type="email" onChange={handleChange} required fullWidth />
                </div>


                <div className="col-md-6" style={{ flex: '1', position: 'relative' }} >
                  <TextField variant="outlined" label="Set Password" name="password" type={visible === false ? "password" : "text"} onChange={handleChange} required fullWidth />
                  <button
                    id="eyebtnRegister"
                    onClick={(e) => {
                      e.preventDefault();
                      return setvisible(!visible);
                    }}
                  >
                    {visible ? <FaEye /> : <FaEyeSlash />}
                  </button>
                </div>


              </div>

              <div className="row"  onFocus={scrollToDOBSection}>


                <div className="col-md-6" style={{ display: 'flex',marginRight: '18px', justifyContent: 'space-around', alignItems: 'center', flex: '1', borderRadius: '3px', border: '1px solid #8080805c', marginLeft: '10px', maxWidth: '47%', zIndex: '9999999' }} id='dob'>

                  <div className='d-flex justify-content-center align-items-center'>
                    <label style={{ marginRight: '2px', color: 'grey' }}>DOB :</label>
                  </div>

                  <Select
                    options={years}
                    value={selectedYear}
                    onChange={(e) => handleChangeDOB(e, 'year')}
                    placeholder=" Year"
                    styles={{ marginRight: '7px' }}
                  />

                  <Select
                    options={months}
                    onChange={(e) => handleChangeDOB(e, 'month')}
                    value={selectedMonth}
                    placeholder=" Month"
                    styles={{ marginRight: '7px' }}
                  />
                  <Select
                    options={availableDays}
                    onChange={(e) => handleChangeDOB(e, 'date')}
                    value={selectedDate}
                    id='date'
                    placeholder="Date"
                    isDisabled={!availableDays.length} // Disable until month/year are selected
                  />




                </div>

                <div className="col-md-6 retert" style={{ zIndex:"999999", flex: '1' }}>

                  <FormControl variant="outlined" required fullWidth>
                    <Select options={maritalStatusOptions} onChange={(e) => {handleChange(e, "marital_status"); setMaritalStatusValue(e.value); setErrorMaritalStatus(false);}}
                      placeholder="Select Marital Status"
                      styles={{
                        borderColor: errorMaritalStatus ? 'red' : '#ccc', // Show red border if there's an error
                      }}
                    />
  {errorMaritalStatus && <p style={{ color: 'red' }}>Marital Status is required!</p>} {/* Error message if marital status is not selected */}


                  </FormControl>
                </div>
                {dobError && <p style={{ color: "red" }}>Date of Birth is required!</p>}

              </div>
              <div className="row" style={{ display: 'flex', justifyContent: 'space-between',zIndex:'9999' }} onFocus={scrollToDOBSection}>

                <div className="col" style={{ zIndex: 10 }}>
                  <FormControl variant="outlined" required style={{ width: '100%' }}>

                    <Select
                      name="country"
                      onChange={(e) => handleChange(e, "country")}
                      options={countries}
                      placeholder="Select Country"
                      value={countryValue}
                    />
                                {errorCountry && <p style={{ color: 'red' }}>Country is required!</p>} {/* Error message if country is not selected */}
      
                  </FormControl>

                </div>


                <div className="col" style={{ zIndex: 12 }}>
                  <FormControl variant="outlined" required style={{ width: '100%' }}>

                    <Select
                      name="states"
                      onChange={(e) => handleChange(e, "states")}
                      options={states}
                      placeholder="Select State"
                      value={stateValue}
                    />
                          {errorState && <p style={{ color: 'red' }}>State is required!</p>} {/* Error message if state is not selected */}

                  </FormControl>
                </div>
                <div className="col" style={{ zIndex: 12 }}>
                  <FormControl variant="outlined" required style={{ width: '100%' }}>

                    <Select
                      name="city"
                      onChange={(e) => handleChange(e, "city")}
                      options={cities}
                      placeholder="Select City"
                      value={cityValue}
                    />
                    {/* {cities.map((city) => (
                <MenuItem key={city.id} value={city.name}>
                  {city.name}
                </MenuItem>
              ))}
            </Select> */}
                  {errorCity && <p style={{ color: 'red' }}>City is required!</p>} {/* Error message if city is not selected */}

                  </FormControl>
                </div>





              </div>

            

              <div className="row" style={{ display: 'flex', justifyContent: 'space-between',zIndex:'99' }} >
                <div className="col-md-6" style={{ flex: '1' }}>
                
                <Select 
                onFocus={scrollToDOBSection}
                options={[
                  { value: "student", label: "Student" },
  { value: "Employed", label: "Employed" },
  { value: "Unemployed", label: "Unemployed" },

  { value: "Self-employed", label: "Self-employed" },
  { value: "Business-Person", label: "Business Person" },
  
                ]}
                onChange={(e) => {handleChange(e, "occupation");handleChangeOccupation(e)}}
                placeholder="Select Occupation"
              />
                      {error && <p style={{ color: "red" }}>Occupation is required!</p>}

                  {/* <TextField variant="outlined" label="Occupation" name="occupation" onChange={handleChange} required fullWidth /> */}
                </div>

              </div>

              <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }} onFocus={scrollToDOBSection}>
                <div className="col-md-6" style={{ flex: '1' }}>
                  <TextField variant="outlined" label="Address" name="address" onChange={handleChange}   fullWidth />
                </div>
              </div>


              
              <div style={{ display: 'flex', justifyContent: 'center',alignItems:'center', marginTop: '10px' }}>
                <Button variant="contained" color="primary" type="submit" style={{padding:'12px 76px',fontSize:'1.1rem'}} >Submit</Button>
              
              </div>
            </form>



          </div>
        </div>

      </Modal>
      <div className='flexer'>
        <img style={{ padding: '12px', height: '67px' }} src='https://static.wixstatic.com/media/5715af_a286bfb446374a96877a4193a1ef0933~mv2.png/v1/fill/w_114,h_60,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/PAT%20Logo.png' alt="Description of the image" />
        <img
      style={{ padding: '12px', height: '52px', cursor: 'pointer' }}
      src="https://static.wixstatic.com/media/77a25b_ec2fa92086954799bf72699afde7a666~mv2.png/v1/fill/w_191,h_34,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/VMKGBLUE.png"
      alt="Description of the image"
      onClick={() => navigate('/Login')}
    />


        {/* https://static.wixstatic.com/media/77a25b_ec2fa92086954799bf72699afde7a666~mv2.png/v1/fill/w_191,h_34,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/VMKGBLUE.png */}
      </div>
      <ul className='flexyer'>
        {/* <li className='wbvv'>Test </li> */}
        <li className='wbvv' onClick={openModal}>Register</li>
        <li className='wbvv' onClick={() => handlelogin()}>Login</li>
      </ul>
    </div>

      <div className='fullwiped'>
        <br></br>
        <div className="home-container" style={{ height: '507px' }}>
          <section className="content" style={{ display: 'flex' }}>

            {/* <div className=" left-side hls" style={{ flex: 1, transition: 'flex 0.5s' }} data-aos="fade-left"> */}

            {/* <h1 className='black'>Wdsx</h1> */}
            {/* <img src={left} alt="Description of the image" /> */}

            {/* left */}
            {/* </div> */}
            <div className="right-side hrs" style={{ flex: 1, textAlign: "center", marginTop: '36px' }}  >
              <br></br>
              <br></br>
              <br></br>
              <br></br>

              <h1 className='white fadeing' style={{ marginBottom: '21px' }}>“Assessment that awakens you to the knowledge and possibility of yourself”</h1>
              {/* <p style={{paddingBlock:'5px'}} className='fadeout'>The Personal Assessment Test is a comprehensive test that measures a human being's critical areas of life, and provides a comprehensive, actionable report that can be used to improve, or simply reflect on those areas of life.</p> */}
              <button style={{ color: 'white', background: '#ff5722', fontWeight: 500, padding: '8px 11px', width: '200px', borderRadius: '12px' }} onClick={() => handlelogin()}>Begin Test</button> &nbsp; &nbsp;
              {/* <button style={{ background: "white", borderRadius: '12px',fontWeight:500, color: 'blue', padding: '12px' }}  >Begin Test</button> */}
            </div>
          </section>
        </div>




      </div>


      <div className=' whiteback' >
        <div className=' ' >
          <br></br>
          <br></br>

          <div className="middle middles container">
            <b className='black headcontent' >What is the Personal Assessment Test? </b>
            <br></br>
            <b className='black para'  >This unique test combines both qualitative and quantitative measures to provide a comprehensive view of where you stand in life and the possibilities ahead. It is a 25-minute self-assessment that evaluates your life across five essential areas </b>

          </div>



          <br></br>
          <div className='fonter'>
            {/* <div className='conteting black'>
             dc
            </div>
            <div className='conteting black'>
              dc
            </div>
            <div className='conteting black'>
              dc
            </div>
            <div className='conteting black'>
              dc
            </div> */}

            <div style={{ display: 'flex', gap: '50px' }}>
              {areas.map((area, index) => (
                <div key={index} className="">
                  <Card className="text-center">
                    <Card.Body>
                      <Card.Title className="icon "><img src={area.icon} className='card-img-top' /></Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">{area.title}</Card.Subtitle>
                      <Card.Text>{area.description}</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </div>
          </div>
          <br></br>
          <br></br>
          {/* <div className="middle middles">
            <b className='black headcontent' >Take the First Step Towards Self-Awareness
            </b>
            <br></br>
            <b className='black para'  >Your journey of self-discovery begins here! This is your opportunity to reflect, assess, and explore new possibilities in your life.
            </b>

          </div> */}
          <div class="gradient-overlay-half-dark-v4 bg-img-hero text-left " >
            <div class="container text-center text-lg-left space-2">
              <div class="row align-items-lg-center">
                <div class="col-lg-9 mb-5 mb-lg-0 text-left  cta">
                  <h2 class="h2 text-primary font-weight-light mb-1 text-left " >Take the First Step Towards <span class="font-weight-medium">Self-Awareness</span></h2>
                  <p class="lead  mb-0 text-left " >Your journey of self-discovery begins here! This is your opportunity to reflect, assess, and explore new possibilities in your life.</p>
                </div>

                <div class="col-lg-3 text-lg-right">
                  <Link class="btn btn-primary btn-wide transition-3d-hover ctabtn"onClick={() => navigate('/Login')}>Begin Test</Link>

                </div>
              </div>
            </div>
          </div>

          <br></br>
          <div className="middle middles">
            {/* <b className='black headcontent' >Button to begin the assessment */}

            {/* </b> */}
            <br></br>
            {/* <b className='black para' style={{fontStyle:'italic'}}  >(Add a panel here at the bottom showcasing the following stats & quotes) */}

            {/* </b> */}

            {/* <div className='charger'>

              <div className='font1'>
                <div className='  yelloweover'>Over</div>
                <div className='  numberblue'>
                  <CountUp start={0} end={50} duration={2.75} delay={2}>

                  </CountUp>
                </div>
                <div className='yelloweover'>Organizations</div>
              </div>

              <div className='font1'>
                <div className='  yelloweover'>More than</div>
                <div className='numberblue'>
                  <CountUp start={0} end={1200} duration={2.75} delay={2} >

                  </CountUp>

                </div>
                <div className='yelloweover'>People</div>
              </div>
            </div> */}
            <div className="container   w-10  progressNumber">
              <h1 class="h1 black headcontent text-center font-weight-light mb-1 text-center " style={{ paddingBottom: "30px" }}>Trusted by</h1>

              <div className="d-flex justify-content-center align-items-center " style={{ gap: '50px' }}>
                <div className="">
                  <AnimatedCounter endValue={50} duration={3} cardText={'Organization'} icon={<FaHandsHelping style={{ fontSize: '69px' }} />} />
                </div>
                <div className="">
                  <AnimatedCounter endValue={1400} duration={3} cardText={'People'} icon={<IoIosHappy style={{ fontSize: '69px' }} />} />
                </div>

              </div>
            </div>

            <br></br><br></br><br></br>
            {/* <b className='black headcontent'><u>Testimonials</u></b>
            <br></br> */}
            {/* <div className='center'>
       <div className="testimonials">
      <div className="testimonial">
        <p>"Personal Assessment Test has helped us create a clear growth pathway for all the members of our organisation. It has been a great tool to enable our vision of 10x growth for our members."</p>
        <p>- <span style={{fontWeight:'700'}}>Mukesh Dedhia,</span> Mentor at Kutch Corporate Forum, Director at Ghalla Bhansali Stock Brokers Pvt. Ltd.</p>
      </div>
      <div className="testimonial">
        <p>"The PAT test is seemingly a simple scoring when you take the test but the results are surprisingly in-depth and intuitive. It captures not only your strengths and weaknesses but your current state of mind in a succinct manner, presenting you with an objective report of yourself that compels you to think about what you are going to do now that you have this information."</p>
        <p>- <span style={{fontWeight:'700'}}>Dhun Patel,</span> Director at Therefore Design Pvt. Ltd.</p>
      </div>
    </div>
       </div> */}
            {/* <img style={{ padding: '12px', height: '211px' }} src={img6}alt="Description of the image" />
            <img style={{ padding: '12px', height: '300px' }} src={img7}alt="Description of the image" /> */}

            {/* <button className='btn btn-primary bttn' onClick={openModal} style={{ color: 'white', padding: '12px', borderRadius: '12px' }}>Begin Test</button> &nbsp; &nbsp;  */}



            <section className='testimonials fullwiped' >
              <div className='container'>
                <div>
                  <div className='row align-items-center text-center'>
                    <div className='col-lg-12 info'>
                      <h3>
                        Read What Our Customers Love About Us
                      </h3>
                      {/* <p>Personal Assessment Test has helped us create a clear growth pathway for all the members of our organisation. It has been a great tool to enable our vision of 10x growth for our members.</p> */}
                    </div>
                    <div className='col-lg-12'>

                      <Swiper
                        modules={[Pagination, Autoplay]}
                        loop={true}
                        speed={2000}
                        slidesPerView='auto'
                        pagination={{ el: '.swiper-pagination', type: 'bullets', clickable: true }}
                        autoplay={{ delay: 2500, disableOnInteraction: false }}
                        className="mySwiper"
                      >
                        <SwiperSlide key={1} className='swiper-slide'>
                          <div className='testimonial-item'>
                            <div className='d-flex flex-direction-column' style={{ flexDirection: 'column' }}>
                              <h3>Mukesh Dedhia</h3>
                              <h4>Mentor at Kutch Corporate Forum, Director at Ghalla Bhansali Stock Brokers Pvt. Ltd.</h4>

                            </div>
                            <p>


                              <span>"Personal Assessment Test has helped us create a clear growth pathway for all the members of our organisation. It has been a great tool to enable our vision of 10x growth for our members."</span>

                            </p>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide key={2} className='swiper-slide'>
                          <div className='testimonial-item'>
                            <div className='d-flex flex-direction-column' style={{ flexDirection: 'column' }}>
                              <h3>Dhun Patel</h3>
                              <h4>Director at Therefore Design Pvt. Ltd.</h4>

                            </div>
                            <p>

                              <span> "The PAT test is seemingly a simple scoring when you take the test but the results are surprisingly in-depth and intuitive. It captures not only your strengths and weaknesses but your current state of mind in a succinct manner, presenting you with an objective report of yourself that compels you to think about what you are going to do now that you have this information."</span>

                            </p>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide key={3} className='swiper-slide'>
                          <div className='testimonial-item'>
                            <div className='d-flex flex-direction-column' style={{ flexDirection: 'column' }}>
                              <h3>Dr. Kiran Khairnar</h3>
                              <h4>Associate Professor, Training and Placement Officer at DY Patil University School of Management.</h4>

                            </div>
                            <p>

                              <span> “Personal Assessment Test has helped our students in understanding themselves better and guided us in understanding how to help our students in carving a better future for them.”</span>

                            </p>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide key={4} className='swiper-slide'>
                          <div className='testimonial-item'>
                            <div className='d-flex flex-direction-column' style={{ flexDirection: 'column' }}>
                              <h3>Shambhavi Mate</h3>
                              <h4>Digital Marketing Specialist</h4>

                            </div>
                            <p>

                              <span> “Unlike other tests, PAT was a very personalised one, it spoke specifically about me. It didn't give me my positives or negatives, it showed me a mirror to my secret ingredients. What lies within me and what more I can explore in this world. PAT created a lot of possibilities for me, and at my choice!”</span>

                            </p>
                          </div>
                        </SwiperSlide>
                        <br />
                        <div className='swiper-pagination'>
                          <span className='swiper-pagination-bullet'></span>
                        </div>
                      </Swiper>
                    </div>
                  </div>
                </div>
              </div>
            </section>


          </div>
        </div>
        <br></br>

        <br></br>
        <br></br>
      </div>

    </>
  );
};

export default Home;
  