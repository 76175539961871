import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import bgImgae from "../images.png";
import axios from 'axios';
import { baseurlvmkp } from './Subjects/Baseurl';

const Totorialstartpage = () => {
  const navigate = useNavigate();
const [orderId, setOrderId] = useState([])
const[loading,setloading]=useState(false)
  const handlenav = () => {
    const now = new Date();
    const formattedDate = `${(now.getMonth() + 1).toString().padStart(2, '0')}/${now.getDate().toString().padStart(2, '0')}/${now.getFullYear()}`;

   
// if(checker==0){
//   // else{

//     navigate('/exam');  

  
// }
  if(checker==0) { 
    setloading(true)
    axios.delete(`${baseurlvmkp}/exam/delete`, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }).then((deleteResponse) => {
       console.log('Exam deleted successfully', deleteResponse);

     
  }).catch((deleteError) => {
      console.error('Error deleting exam:', deleteError);
  });
    axios.post(`${baseurlvmkp}/exam/post`, {
      date: formattedDate, 
      myOrders:[{id:orderId}]
    }, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    })
    .then((response) => {
      console.log(response.data);  
      navigate('/exam');  
    })
    .catch((error) => {
      console.error('There was an error fetching exam data:', error);
    })
    .finally(() => {
      setloading(false)
    });
    // console.log("navig");
  }else{
    navigate('/exam');  

  }

  };
const [checker, setChecker] = useState([])
  // /api/exam/post
useEffect(() => {


  axios
  .get(`${baseurlvmkp}/myorder/getstatus/${JSON.parse(localStorage.getItem('user')).details.id}`) 
  .then((response) => {
    setOrderId(response.data.id);
    console.log(response.data.id);  
  })
  .catch((err) => {
    console.log(err.message); 
  })
  .finally(() => {
    console.log('finally');
  });


//   axios.get(`${baseurlvmkp}/exam/get` , {
//     headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
// }).then((response) => {
//     console.log(response.data)

//     const userid=JSON.parse(localStorage.getItem('user')).details.id
//     console.log(response.data.filter((item)=>item.userId==Number(userid)).map((item)=>item.id)[0])
//     console.log(response.data.filter((item)=>item.userId==Number(userid)).map((item)=>item.status))
//     const pendingIds = response.data
//     .filter((item) => item.userId == Number(userid))  // Filter by userId
//     .filter((item) => item.status === 'pending')     // Filter items where status is 'pending'
//     .map((item) => item.id);   

//     const sortedPendingIds = pendingIds.sort((a, b) => b - a);
//     // const sortedPendingIds = pendingIds.sort((a, b) => b - a);

//     // Get the latest (most recent) pending id and remove it from the list
//     const latestPendingId = sortedPendingIds[0]; // The latest (most recent) id
//     const remainingPendingIds = sortedPendingIds.slice(1); // Remove the latest one
//     console.log(latestPendingId)
//     // setChecker(latestPendingId)
// // console.log(first)
//     if (latestPendingId) {
//       setChecker(latestPendingId);   
//     }else{
//       setChecker(0)
//     }


// }).catch((error)=>{
//     console.log(error)
//              })
}, [ ])





  return (
    <> 
      <div className='fullwiped'>
        <div className="home-container">
          <section className="content ttt  " style={{background:'white',width:'124%',height:'99%'}}>
            <div className="left-side ">
              <div className='imgback' style={{ padding: '12px', zIndex: '9999', position: 'relative' }} >
                <img
                  src="https://static.wixstatic.com/media/5715af_a286bfb446374a96877a4193a1ef0933~mv2.png/v1/fill/w_114,h_60,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/PAT%20Logo.png"
                  alt="PAT Logo"
                  className="banner"
                />
                <img
                  src="https://static.wixstatic.com/media/77a25b_ec2fa92086954799bf72699afde7a666~mv2.png/v1/fill/w_191,h_34,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/VMKGBLUE.png"
                  alt="VMK Blue Banner"
                  className="banner"
                />
              </div>
              {/* Updated video iframe */}
              <div className="video-container">
                <div>
                <iframe
                  src="https://drive.google.com/file/d/1LsxdDD8HGGPNAEMDUVN2EYg35vZ8nfiU/preview"
                  title="Tutorial Video"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  style={{ width: '100%', height: '100%' }}
                ></iframe>

                
                </div>
              </div>
              <p style={{color:"black"}}>Click above to view tutorial</p>
            </div>
            <div className="right-side nonerighttop nonelefttop scrollhidden" id="rightangle" style={{overflow:'scroll'}}>
          

<div className="faq-section">
      <h2 className="faq-title" style={{fontSize:'18px'}}>
        Guidelines for the Personal Assessment Test
      </h2>

      <div className="faq-item">
      <h3 className="faq-question">
          <span className="highlight">1.  Be authentic and fill it without any distractions. Think about only that one area of life while you are answering questions about it. Completing this test may take you 15 to 20 minutes.</span>
          <br />
          </h3>
       
      </div>

      <div className="faq-item">
        <h3 className="faq-question">
          <span className="highlight">2. Make sure you answer for all areas of life and DO NOT miss any!</span>
          <br />
          <span className="sub-text">
          
          <div style={{display:"flex",alignItems:"center"}}>
          <span style={{fontSize:"15px",marginRight:"5px",marginTop:"-35px",}}>•</span>There are 92 areas of life mentioned. Not all areas exist in your life. Even if they do exist for you, make sure you mark them 'Not Applicable' before moving on to the next area of life.
            </div>
            </span>
        </h3>
      </div>

      <div className="faq-item">
        <h3 className="faq-question">
          <span className="highlight" >3. If an area of life does not exist for you, you DO NOT have to answer other questions about that area.</span>
        <br/>
          <span className="example">
            Example: 
            <span className="sub-text">The area 'children' does not exist in my life. I will mark it <b>"Not Applicable"</b> and move on. Only if it exists in my life, I need to fill the next questions for it.</span>
          </span>
        </h3>
      </div>

      <div className="faq-item">
        <h3 className="faq-question">
          <span className="highlight">4. How to answer the questions</span>
          <br />
          
          
          
            <span className="sub-text" >
            <div style={{display:"flex",alignItems:"center"}}>
            <span style={{fontSize:"15px",marginRight:"5px"}}>•</span> If an area of life is working as per your expectations, mark it <b style={{marginLeft:"5px"}}>"Working"</b>.
            </div>
            </span>
          
          <br />
          
          <span className="sub-text">
          <div style={{display:"flex",alignItems:"start"}}>
          <span style={{fontSize:"15px",marginRight:"5px",marginTop:"-2px"}}>•</span>If it is Working in your life, you do not need to answer the next 2 questions about it. Simply, move on to the next area of life.
          </div>
            
            </span>
          <br />
          
          <span className="sub-text">

          <div>
          <span style={{fontSize:"15px",marginRight:"5px",marginTop:"-2px"}}>•</span>For an area of life Not Working, answer how critical it is for you based on what importance it holds in your life (The <span style={{color:"#4378BE"}}>Criticality</span>).
           </div>
            </span>
          
          <br />
          <span className="sub-text">
          <span style={{fontSize:"15px",marginRight:"5px",marginTop:"-2px"}}>•</span> In the next 2 years, what would it mean for you to make this area of life completely working as per your expectations? Choose from the options provided to you. 
            Example: The area of life Travel is <span className="highlight">Not Working</span> for me. But if it becomes perfectly working in the next 2 years, it would be a <span className="highlight">Dream</span> come true for me! For someone else, it could be a <span className="highlight">Necessity</span> to have 'traveling' a perfectly working area of life. Choose according to what you feel in the moment.
          </span>
        </h3>
      </div>
    </div>

</div>

          { loading==false? <button className='buttonback' style={{ color: '#FEBD16', fontWeight: 500, background: 'rgb(188 186 183)' }} onClick={handlenav}>
              <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#E22246" className="animated-icon">
                <path d="m182-181 219-299-219-299h129.67l219 299-219 299H182Zm295 0 219-299-219-299h128.67l220 299-220 299H477Z" />
              </svg>
            </button>:
           <button className='buttonback' style={{ color: '#FEBD16', fontWeight: 500, background: 'rgb(188 186 183)' }} >
           <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#E22246" className="animated-icon">
             <path d="m182-181 219-299-219-299h129.67l219 299-219 299H182Zm295 0 219-299-219-299h128.67l220 299-220 299H477Z" />
           </svg>
         </button> 
            
            }
          </section>
        </div>
      </div>
    </>
  );
};

export default Totorialstartpage;
