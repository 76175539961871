import React from 'react';
import { Card, Container, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import ExcelJS from 'exceljs';
import { BASE_URL } from '../../components/APi';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';

const Sortdateresult = () => {
  const Relationshpslabels =  [
    'Mother', 'Father', 'Children', 'Grandparents', 'Partner', 'Spouse',
    'Siblings', 'Girlfriends', 'Boyfriends', 'Friends', 'friendswithbenefits',
    'Relatives', 'Communities', 'Boss', 'Colleague', 'Teacher', 'Classmate',
    'Conflicts', 'Love', 'Intimacy', 'Sex', 'Commitments', 'goalsinrelationship'
  ];
  const additionalMessage = "For each area of your life with respect to Physical Health & Mental Health, answer the questions necessary out of the four questions below.";
const education="For each area of your life with respect to Work & Education, answer the questions necessary out of the four questions below.";
const wealth="For each area of your life with respect to Wealth, answer the questions necessary out of the four questions below.";
const self="For each area of your life with respect to Self Awareness, answer the questions necessary out of the four questions below.";
  const healthlabels = [
     'Weight','Fitness','Vitality', 'Power','Diseases', 'Nutrition', 'Diet', 'Cooking', 'Hygiene',  'personalcare',    'Sports',  'Entertainment','Emotion',
      'Traumas','Stress',  'Vacation',  'lifestyleinhealth','goalsinhealth',,

  
  ];

  // const handleDownload = async (startDate, endDate) => {
  //   const url = `http://13.201.40.47:8080/vmkp/excel/get/${startDate}/${endDate}`;

  //   try {
  //     const response = await axios.get(url, {
  //       headers: { Authorization: 'Bearer ' + localStorage.getItem('staffToken') },
  //     });

  //     const extractedData = response.data.map(item => {
  //       const row = {
  //         FullName: item.fullname,
  //         ContactNumber: item.contactnumber,
  //         EmailId: item.emailid,
  //         Gender: item.gender
  //       };

  //       // Dynamically add questions for each relationship type
  //       Relationshpslabels.forEach((label) => {
  //         for (let i = 1; i <= 4; i++) {
  //           row[`${label} - Question ${i}`] = item[`${label.toLowerCase()}${i}`]; // API format
  //         }
  //       });

  //       // Dynamically add questions for each health type
  //       healthlabels.forEach((label) => {
  //         for (let i = 1; i <= 4; i++) {
  //           row[`${label} - Question ${i}`] = item[`${label.toLowerCase()}${i}`]; // API format
  //         }
  //       });

  //       return row;
  //     });

  //     // Create a new workbook and a new worksheet
  //     const workbook = new ExcelJS.Workbook();
  //     const worksheet = workbook.addWorksheet("User Data");

  //     // Define the data for the sheet
  //     const data = [
  //       ["", "", "", "", "For each area of your life with respect to Relationships and Health, answer the questions necessary out of the four questions below.", ...Array(91).fill(""), additionalMessage],
  //       ["Full Name", "Contact Number", "Email ID", "Gender", 
  //         ...Relationshpslabels.flatMap(label => 
  //           [`${label} - Question 1`, `${label} - Question 2`, `${label} - Question 3`, `${label} - Question 4`]
  //         ),
  //         ...healthlabels.flatMap(label => 
  //           [`${label} - Question 1`, `${label} - Question 2`, `${label} - Question 3`, `${label} - Question 4`]
  //         )
  //       ], // Dynamic headers for all relationships and health
  //       ...extractedData.map(row => [
  //         row.FullName, 
  //         row.ContactNumber, 
  //         row.EmailId, 
  //         row.Gender,
  //         ...Relationshpslabels.flatMap(label => [
  //           row[`${label} - Question 1`],
  //           row[`${label} - Question 2`],
  //           row[`${label} - Question 3`],
  //           row[`${label} - Question 4`]
  //         ]),
  //         ...healthlabels.flatMap(label => [
  //           row[`${label} - Question 1`],
  //           row[`${label} - Question 2`],
  //           row[`${label} - Question 3`],
  //           row[`${label} - Question 4`]
  //         ])
  //       ])
  //     ];

  //     // Add data to the worksheet
  //     data.forEach((row) => {
  //       worksheet.addRow(row);
  //     });

  //     const headerCells = worksheet.getRow(1);
  //     headerCells.eachCell(cell => {
  //       // Check if the cell matches specific header values
  //       if (cell.value === "For each area of your life with respect to Relationships and Health, answer the questions necessary out of the four questions below.") {
  //         cell.fill = {
  //           type: 'pattern',
  //           pattern: 'solid',
  //           fgColor: { argb: '3E74D1' } // Background color for this header
  //         };
  //         cell.font = {
  //           color: { argb: 'FFFFFFFF' }, // White font color
  //           bold: true,
  //           size: 12
  //         };
  //       } else if (cell.value === additionalMessage) {
  //         cell.fill = {
  //           type: 'pattern',
  //           pattern: 'solid',
  //           fgColor: { argb: 'FFBD16' } // Yellow background color for additionalMessage
  //         };
  //         cell.font = {
  //           color: { argb: 'FFFFFFFF' },  // Black font color for readability
  //           bold: true,
  //           size: 12
  //         };
  //       }
  //     });
  //     // Generate the Excel file and initiate download
  //     const buffer = await workbook.xlsx.writeBuffer();
  //     const blob = new Blob([buffer], { type: "application/octet-stream" });
  //     const link = document.createElement("a");
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = `data_${startDate}.xlsx`;
  //     link.click();
  //   } catch (error) {
  //     console.error('Download failed:', error);
  //   }
  // };
    const workeducationlabel = [
    'education', 'job', 'buisness', 'profession', 'knowledge', 'earnings', 
    'competencies'
 , 'skills', 'leadership', 'teamSkills', 'transport', 
    'lifestyleWork', 'goalsInWork'
  ];
  const wealthlabel = [
   'income', 'savings', 'property', 'inheritance','assets',  'growth', 'expansion', 'personalgadgets', 'personalfinance','budgeting', 'planning','lifestyle','subscription', 'taxes', 'housing',  'liabilities', 'loans',   'luxuryandcomfort', 'technology', 'goals In Wealth',   
  ];

    const selfAwarenessTopics = [
    "prayers", "discipline", "meditation", "reading", "music", "fashion", 
    "travel", "reflecting", "creation", "confidence", "values", 
    "learnings", "miracles", "spirituality", "devotion", "dreams", 
    "future", "goalsInSelfAwareness"
  ];
  
  const handleDownload = async (startDate, endDate) => {
    const url = `http://13.201.40.47:8080/vmkp/excel/get/${startDate}/${endDate}`;
  
    try {
      const response = await axios.get(url, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('staffToken') },
      });
  
      const extractedData = response.data.map(item => {
        const row = {
          StartDate:item.startdate,
          EndDate:item.enddate,
          FullName: item.fullname,
          ContactNumber: item.contactnumber,
          EmailId: item.emailid,
          Gender: item.gender,
          Age: item.age,
          occupation: item.occupation,
          maritalstatus: item.maritalstatus,
          // country: item.country,
          state: item.state,
          city: item.city,
          address: item.address,
        };
  
        // Dynamically add questions for each relationship type
        Relationshpslabels.forEach((label) => {
          for (let i = 1; i <= 4; i++) {
            row[`${label} - Question ${i}`] = item[`${label.toLowerCase()}${i}`]; // API format
          }
        });
  
        // Dynamically add questions for each health type
        healthlabels.forEach((label) => {
          for (let i = 1; i <= 4; i++) {
            row[`${label} - Question ${i}`] = item[`${label.toLowerCase()}${i}`]; // API format
          }
        });
  
        // Dynamically add questions for each wealth type
      
  
        // Dynamically add questions for each work education type
        workeducationlabel.forEach((label) => {
          for (let i = 1; i <= 4; i++) {
            row[`${label} - Question ${i}`] = item[`${label.toLowerCase()}${i}`]; // API format
          }
        });
        wealthlabel.forEach((label) => {
          for (let i = 1; i <= 4; i++) {
            row[`${label} - Question ${i}`] = item[`${label.toLowerCase().replace(/ /g, '')}${i}`]; // Clean key for API format
          }
        });
        // Dynamically add questions for each self-awareness topic
        selfAwarenessTopics.forEach((label) => {
          for (let i = 1; i <= 4; i++) {
            row[`${label} - Question ${i}`] = item[`${label.toLowerCase()}${i}`]; // API format
          }
        });
  
        return row;
      });
  
      // Create a new workbook and a new worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("User Data");
  
      // Define the data for the sheet
      const data = [
        ["","","", "", "", "","","","","","","", "For each area of your life with respect to Relationships, Health, Wealth, Work Education, and Self Awareness, answer the questions necessary out of the four questions below.", ...Array(91).fill(""), additionalMessage,...Array(71).fill(""),education,...Array(51).fill(''),wealth,...Array(79).fill(" "),self],
        ["StartDate","EndDate","Full Name", "Contact Number", "Email ID", "Gender","Age","occupation","maritalstatus","state","city","address",
          ...Relationshpslabels.flatMap(label => 
            [`${label} - Is this area applicable for you?`, `${label} - Is this area working for you?`, `${label} - How critical is this area for you?`, `${label} - How do you see your future in this area?`]
          ),
          ...healthlabels.flatMap(label => 
            [`${label} - Is this area applicable for you?`, `${label} - Is this area working for you?`, `${label} -How critical is this area for you?`, `${label} - How do you see your future in this area?`]
          ),
         
          ...workeducationlabel.flatMap(label => 
            [`${label}  - Is this area applicable for you?`, `${label} - Is this area working for you?`, `${label} - How critical is this area for you?`, `${label}- How do you see your future in this area?`]
          ), ...wealthlabel.flatMap(label => 
            [`${label}  - Is this area applicable for you?`, `${label} -Is this area working for you?`, `${label} - How critical is this area for you?`, `${label} - How do you see your future in this area?`]
          ),
          ...selfAwarenessTopics.flatMap(label => 
            [`${label}  - Is this area applicable for you?`, `${label} -Is this area working for you?`, `${label} - How critical is this area for you?`, `${label} - How do you see your future in this area?`]
          )
        ], // Dynamic headers for all categories
        ...extractedData.map(row => [
row.StartDate, 
row.EndDate,
          row.FullName, 
          row.ContactNumber, 
          row.EmailId, 
          row.Gender,row.Age,row.occupation,row.maritalstatus,row.state,row.city,row.address,
          ...Relationshpslabels.flatMap(label => [
            row[`${label} - Question 1`],
            row[`${label} - Question 2`],
            row[`${label} - Question 3`],
            row[`${label} - Question 4`]
          ]),
          ...healthlabels.flatMap(label => [
            row[`${label} - Question 1`],
            row[`${label} - Question 2`],
            row[`${label} - Question 3`],
            row[`${label} - Question 4`]
          ]),  ...workeducationlabel.flatMap(label => [
            row[`${label} - Question 1`],
            row[`${label} - Question 2`],
            row[`${label} - Question 3`],
            row[`${label} - Question 4`]
          ]),
          ...wealthlabel.flatMap(label => [
            row[`${label} - Question 1`],
            row[`${label} - Question 2`],
            row[`${label} - Question 3`],
            row[`${label} - Question 4`]
          ]),
        
          ...selfAwarenessTopics.flatMap(label => [
            row[`${label} - Question 1`],
            row[`${label} - Question 2`],
            row[`${label} - Question 3`],
            row[`${label} - Question 4`]
          ])
        ])
      ];
  
      // Add data to the worksheet
      data.forEach((row) => {
        worksheet.addRow(row);
      });
  
      const headerCells = worksheet.getRow(1);
      headerCells.eachCell(cell => {
        // Check if the cell matches specific header values
        if (cell.value === "For each area of your life with respect to Relationships, Health, Wealth, Work Education, and Self Awareness, answer the questions necessary out of the four questions below.") {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '3E74D1' } // Background color for this header
          };
          cell.font = {
            color: { argb: 'FFFFFFFF' }, // White font color
            bold: true,
            size: 12
          };
        } else if (cell.value === additionalMessage) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFBD16' }  
          };
          cell.font = {
            color: { argb: 'FFFFFFFF' }, 
            bold: true,
            size: 12
          };
        }else if (cell.value === education) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'E22146' }  
          };
          cell.font = {
            color: { argb: 'FFFFFFFF' },   
            bold: true,
            size: 12
          };
        }else if (cell.value === wealth) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '45B43B' }  
          };
          cell.font = {
            color: { argb: 'FFFFFFFF' },   
            bold: true,
            size: 12
          };
        }else if (cell.value === self) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '3D3D3D' }  
          };
          cell.font = {
            color: { argb: 'FFFFFFFF' },  
            bold: true,
            size: 12
          };
        }
      });
  
      const secondRowCells = worksheet.getRow(2);   
      secondRowCells.eachCell(cell => {
         if (typeof cell.value === 'string') {
          cell.value = cell.value.toUpperCase();  
        }
        cell.font = {
          bold: true,   
          size: 12      
        };
      });

      // Generate the Excel file and initiate download
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `data_${startDate}.xlsx`;
      link.click();
    } catch (error) {
      console.error('Download failed:', error);
    }
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const startDate = e.target.startDate.value;
    const endDate = e.target.endDate.value;

    handleDownload(startDate, endDate);
  };

  return (
    <>
    <Sidebar />
    <div className="containerNormal mainContainerlarge">
        <Navbar />
      <Container className="mt-5">
        <Card>
          <Card.Header as="h5">User Result Overview</Card.Header>
          <Card.Body>
            <Form onSubmit={handleSubmit}>
             <div className="row">
            <div className='col'>
            <Form.Group controlId="formStartDate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control type="date" name="startDate" required />
              </Form.Group>

            
            </div>
            <div className="col">
            <Form.Group controlId="formEndDate">
                <Form.Label>End Date</Form.Label>
                <Form.Control type="date" name="endDate" required />
              </Form.Group>
            </div>
             </div>
<br></br>
              <Button variant="primary" type="submit">
                Download Excel
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
      </div>
    </>
  );
};

export default Sortdateresult;
