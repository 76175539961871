import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Thankyou = () => {
  const navigate = useNavigate();

  const handleNav = () => {
    const now = new Date();
    const formattedDate = `${(now.getMonth() + 1).toString().padStart(2, '0')}/${now.getDate().toString().padStart(2, '0')}/${now.getFullYear()}`;

      
      navigate('/Totorialstartpage');  
    
  };

  return (
    <div className='fullwiped'>
      <div className="home-container"  >
        <div className="thankyou-box thankyou"   data-aos="fade-up" style={{  padding: '30px', borderRadius: '8px', boxShadow: '0 4px 20px rgba(0,0,0,0.1)', textAlign: 'center' , width: '360px' }}>
          <div className='imgarea'>

          <img 
          className='img1'
            src='https://static.wixstatic.com/media/5715af_a286bfb446374a96877a4193a1ef0933~mv2.png/v1/fill/w_114,h_60,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/PAT%20Logo.png' 
            alt="Logo" 
            style={{ width: '30%', marginBottom: '15px' }} 
          />
          <img 
            src='https://static.wixstatic.com/media/77a25b_ec2fa92086954799bf72699afde7a666~mv2.png/v1/fill/w_191,h_34,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/VMKGBLUE.png' 
            alt="Logo" 
            style={{ width: '60%', marginBottom: '15px' }} 
          />
          </div>

          <h2 style={{ color: '#396AA9', margin: '28px 0' }}>Payment Successful!</h2>
          <p style={{ color: '#333', margin: '5px 0' }}>Thank you for your payment. Your transaction has been completed.</p>
          <p style={{ color: '#333', margin: '5px 0' }}>You can now proceed to the next step.</p>
          <img 
            src='https://static.wixstatic.com/media/77a25b_1d08d845574b4541a951c8f311e1ade6~mv2.png/v1/fill/w_291,h_34,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/payment_long.png' 
            alt="Payment Success" 
            style={{ margin: '15px 0', width: '80%' }} 
          />

          <button 
            className=' ' 
            style={{ color: 'white', fontWeight: 500, background: '#396AA9', border: 'none', borderRadius: '4px', padding: '10px 20px', cursor: 'pointer', transition: 'background 0.3s' }} 
            onClick={handleNav}
            onMouseEnter={e => e.currentTarget.style.background = '#FEBD16'} // Change color on hover
            onMouseLeave={e => e.currentTarget.style.background = '#396AA9'} // Revert color
          >
          Proceed to take PAT
          </button>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
